import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FaTachometerAlt,
  FaUsers,
  FaChartBar,
  FaTools,
  FaBoxes,
  FaTruck,
  FaFileInvoiceDollar,
  FaCogs,
  FaTasks,
  FaClipboardList,
  FaBell,
  FaUserFriends,
  FaBriefcase,
  FaLock,
  FaMoneyBillWave,
  FaMapMarkerAlt
} from "react-icons/fa";
import { MdSettings } from "react-icons/md";

const sidebarLinks = [
  {
    category: "Dashboard",
    links: [{ path: "/admin/dashboard", label: "Dashboard", icon: <FaTachometerAlt /> }],
  },
  {
    category: "Management",
    links: [
      { path: "/admin/enquiries", label: "Enquiries", icon: <FaUsers /> },
      { path: "/admin/quotations", label: "Quotations", icon: <FaChartBar /> },
      { path: "/admin/projects", label: "Projects", icon: <FaTools /> },
      { path: "/admin/subcontract-works", label: "Subcontract Works", icon: <FaTools /> },
    ],
  },
  {
    category: "Assets & Purchases",
    links: [
      { path: "/admin/assets", label: "Assets", icon: <FaBoxes /> },
      { path: "/admin/security-deposits", label: "Security Deposits", icon: <FaLock /> },
      { path: "/admin/EMD", label: "EMD", icon: <FaMoneyBillWave /> },
      { path: "/admin/purchases", label: "Purchases", icon: <FaTruck /> },
      { path: "/admin/stock-management", label: "Stock Management", icon: <FaBoxes /> },
    ],
  },
  {
    category: "Finance",
    links: [
      { path: "/admin/accounts", label: "Accounts", icon: <FaFileInvoiceDollar /> },
      { path: "/admin/expenses", label: "Expenses", icon: <FaFileInvoiceDollar /> },
    ],
  },
  {
    category: "Reports & Attendance",
    links: [
      { path: "/admin/reports", label: "Reports", icon: <FaChartBar /> },
      { path: "/admin/attendance-report", label: "Attendance Report", icon: <FaClipboardList /> },
    ],
  },
  {
    category: "Operations",
    links: [
      { path: "/admin/labour-management", label: "Labour Management", icon: <FaUsers /> },
      { path: "/admin/vehicle-management", label: "Vehicle Management", icon: <FaTruck /> },
      { path: "/admin/hr", label: "HR", icon: <FaUserFriends /> },
      { path: "/admin/employees", label: "Employees", icon: <FaUserFriends /> },
      { path: "/admin/users", label: "Users", icon: <FaUserFriends /> },
      { path: "/admin/tools-management", label: "Tools Management", icon: <FaBriefcase /> },
      { path: "/admin/travel-allowance", label: "Travel Allowance", icon: <FaCogs /> },
    ],
  },
  {
    category: "Tasks & Notifications",
    links: [
      { path: "/admin/reminders", label: "Reminders", icon: <FaBell /> },
      { path: "/admin/daily-tasks", label: "Daily Tasks", icon: <FaTasks /> },
      { path: "/admin/daily-notes", label: "Daily Notes", icon: <FaTasks /> },
    ],
  },
  {
    category: "Data Management",
    links: [
      { path: "/admin/clients", label: "Clients", icon: <FaUsers /> },
      { path: "/admin/sales-customers", label: "Sales Customers", icon: <FaUsers /> },
      { path: "/admin/services", label: "Services", icon: <FaCogs /> },
      { path: "/admin/suppliers", label: "Suppliers", icon: <FaTruck /> },
      { path: "/admin/materials", label: "Materials", icon: <FaBoxes /> },
      { path: "/admin/bank-details", label: "Bank A/C Details", icon: <FaMoneyBillWave /> },
      { path: "/admin/work-types", label: "Work Types", icon: <FaTools /> },
      { path: "/admin/work-categories", label: "Work Categories", icon: <FaTools /> },
      { path: "/admin/project-sections", label: "Project Sections", icon: <FaClipboardList /> },
      { path: "/admin/holidays", label: "Holidays", icon: <FaBell /> },
      { path: "/admin/work-locations", label: "Work Location Area", icon: <FaMapMarkerAlt /> },
      { path: "/admin/tax-types", label: "Tax Types", icon: <FaFileInvoiceDollar /> },
    ],
  },
  {
    category: "Settings",
    links: [{ path: "/admin/settings", label: "Settings", icon: <MdSettings /> }],
  },
];

const Sidebar = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (category) => {
    setOpenSections((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className="w-64 bg-white shadow-lg flex flex-col min-h-screen">
      {/* Header */}
      <div className="p-4 text-lg font-bold border-b border-gray-300">
        Admin Dashboard
      </div>

      {/* Navigation */}
      <nav className="flex-grow overflow-y-auto p-4">
        {sidebarLinks.map((section) => (
          <div key={section.category} className="mb-4">
            {/* Category Header */}
            <div
              onClick={() => toggleSection(section.category)}
              className="cursor-pointer flex justify-between items-center font-semibold text-black hover:text-black"
            >
              <span>{section.category}</span>
              <span className="text-sm">
                {openSections[section.category] ? "−" : "+"}
              </span>
            </div>

            {/* Links (Collapsible) */}
            <ul
              className={`mt-2 space-y-2 overflow-hidden transition-all duration-300 ${
                openSections[section.category] ? "max-h-screen" : "max-h-0"
              }`}
            >
              {section.links.map((link) => (
                <li key={link.path}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      `flex items-center space-x-2 p-2 rounded ${
                        isActive ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-100"
                      }`
                    }
                  >
                    <span>{link.icon}</span>
                    <span>{link.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;