import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Import reusable table
import Modal from "../../components/common/Modal"; // Import reusable modal
import TravelAllowanceForm from "../../components/forms/TravelAllowanceForm"; // Import form component

const TravelAllowance = () => {
  const [travelData, setTravelData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Date", accessor: "date" },
    { Header: "Employee Name", accessor: "employeeName" },
    { Header: "Office/Project", accessor: "project" },
    { Header: "Vehicle Type", accessor: "vehicleType" },
    { Header: "Vehicle No", accessor: "vehicleNo" },
    { Header: "From", accessor: "from" },
    { Header: "To", accessor: "to" },
    { Header: "KMs", accessor: "distance" },
    { Header: "Amt", accessor: "amount" },
    { Header: "Remarks", accessor: "remarks" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddRecord = (record) => {
    setTravelData([...travelData, { ...record, id: travelData.length + 1 }]);
    setIsModalOpen(false);
  };

  const handleEditRecord = (updatedRecord) => {
    setTravelData(
      travelData.map((record) =>
        record.id === updatedRecord.id ? updatedRecord : record
      )
    );
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    setTravelData(travelData.filter((record) => record.id !== id));
  };

  const handleAddClick = () => {
    setSelectedRecord(null);
    setIsModalOpen(true);
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRecord(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
      <h1 className="text-2xl font-bold mb-6">Travel Allowance Management</h1>

        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Travel Allowance
        </button>
      </div>
      <ReusableTable columns={columns} data={travelData} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedRecord ? "Edit Travel Allowance" : "Add Travel Allowance"}
        >
          <TravelAllowanceForm
            onSubmit={selectedRecord ? handleEditRecord : handleAddRecord}
            onCancel={closeModal}
            initialValues={
              selectedRecord || {
                date: "",
                employeeName: "",
                project: "",
                vehicleType: "",
                vehicleNo: "",
                from: "",
                to: "",
                distance: "",
                amount: "",
                remarks: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default TravelAllowance;
