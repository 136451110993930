import React, { useState, useRef, useEffect } from 'react';
import { BellIcon, UserIcon } from '@heroicons/react/outline';

const Header = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [showProfileOptions, setShowProfileOptions] = useState(false);

    const notificationsRef = useRef(null);
    const profileOptionsRef = useRef(null);

    // Close dropdowns if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                notificationsRef.current &&
                !notificationsRef.current.contains(event.target)
            ) {
                setShowNotifications(false);
            }
            if (
                profileOptionsRef.current &&
                !profileOptionsRef.current.contains(event.target)
            ) {
                setShowProfileOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="bg-white m-4 p-4 flex items-center justify-between shadow-md rounded-2xl">
            {/* Left Section: Logo and College Name */}
            <div className="flex items-center space-x-3">
                <img
                    src="/images/logo.jpg" // Correct path to logo
                    alt="Logo"
                    className="h-8 w-8 rounded"
                />
                <h1 className="text-primary text-lg font-semibold">
                    Nikam Systems
                </h1>
            </div>

            {/* Right Section: Notifications, User Icon, and Username */}
            <div className="flex items-center space-x-4">
                {/* Notifications Button */}
                <div className="relative" ref={notificationsRef}>
                    <button
                        className="relative flex items-center"
                        onClick={() => setShowNotifications(!showNotifications)}
                    >
                        <BellIcon className="h-6 w-6 text-gray-700" />
                        {/* Optional: Notification Badge */}
                        <span className="absolute top-0 right-0 h-2 w-2 bg-red-600 rounded-full"></span>
                    </button>
                    {/* Notifications Panel */}
                    {showNotifications && (
                        <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-md p-4">
                            <h2 className="font-semibold text-gray-700 mb-2">Notifications</h2>
                            <ul className="space-y-2">
                                <li className="text-sm text-gray-600">No new notifications</li>
                                {/* Add actual notifications here */}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Profile Button */}
                <div className="relative" ref={profileOptionsRef}>
                    <button
                        className="flex items-center space-x-2"
                        onClick={() => setShowProfileOptions(!showProfileOptions)}
                    >
                        <UserIcon className="h-6 w-6 text-gray-700" />
                        <span className=" text-gray-800 font-medium">Username</span>
                    </button>
                    {/* Profile Options Panel */}
                    {showProfileOptions && (
                        <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md p-4">
                            <ul className="space-y-2">
                                <li className="text-sm text-gray-700 hover:text-blue-600 cursor-pointer">
                                    Profile
                                </li>
                                <li className="text-sm text-gray-700 hover:text-blue-600 cursor-pointer">
                                    Settings
                                </li>
                                <li className="text-sm text-gray-700 hover:text-blue-600 cursor-pointer">
                                    Logout
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
