
import React from "react";

const AttendanceReport = () => {
  return (
    <div>
      <h1>AttendanceReport</h1>
      <p>This is the AttendanceReport page.</p>
    </div>
  );
};

export default AttendanceReport;
