import React, { useState } from "react";

const SalesCustomersForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(
    initialValues || {
      customerName: "",
      contactPerson: "",
      phoneNumber: "",
      email: "",
      address: "",
    }
  );

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error on change
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.customerName) newErrors.customerName = "Customer/Company Name is required.";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Form Fields */}
      <div className="grid grid-cols-2 gap-4">
        {/* Customer/Company Name */}
        <div>
          <label className="block text-sm font-medium">Name of Customer/Company</label>
          <input
            type="text"
            name="customerName"
            className={`w-full border rounded p-2 ${
              errors.customerName ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.customerName}
            onChange={handleInputChange}
          />
          {errors.customerName && (
            <p className="text-red-500 text-sm mt-1">{errors.customerName}</p>
          )}
        </div>

        {/* Contact Person */}
        <div>
          <label className="block text-sm font-medium">Contact Person Name</label>
          <input
            type="text"
            name="contactPerson"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.contactPerson}
            onChange={handleInputChange}
          />
        </div>

        {/* Phone Number */}
        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            className={`w-full border rounded p-2 ${
              errors.phoneNumber ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>
          )}
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium">E Mail</label>
          <input
            type="email"
            name="email"
            className={`w-full border rounded p-2 ${
              errors.email ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
          )}
        </div>

        {/* Address */}
        <div className="col-span-2">
          <label className="block text-sm font-medium">Address</label>
          <textarea
            name="address"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.address}
            onChange={handleInputChange}
            rows="4"
          ></textarea>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SalesCustomersForm;
