import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Assuming you have a reusable table component
import Modal from "../../components/common/Modal"; // Modal component for adding/editing customers
import SalesCustomersForm from "../../components/forms/SalesCustomersForm"; // Form component

const SalesCustomers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedCustomer, setSelectedCustomer] = useState(null); // For editing
  const [data, setData] = useState([
    {
      id: "CUST-001",
      customerName: "ABC Corp",
      contactPerson: "John Doe",
      phoneNumber: "1234567890",
      email: "john@abccorp.com",
      address: "123 Business Street, City, Country",
      taxNo: "TAX12345",
      stateCode: "NY",
      createdOn: "2024-12-01",
    },
    {
      id: "CUST-002",
      customerName: "XYZ Ltd",
      contactPerson: "Jane Smith",
      phoneNumber: "0987654321",
      email: "jane@xyzltd.com",
      address: "456 Corporate Blvd, City, Country",
      taxNo: "TAX54321",
      stateCode: "CA",
      createdOn: "2024-12-02",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: (_, index) => index + 1 },
      { Header: "Customer/Company Name", accessor: "customerName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Phone", accessor: "phoneNumber" },
      { Header: "Email", accessor: "email" },
      { Header: "Address", accessor: "address" },
      { Header: "Tax No", accessor: "taxNo" },
      { Header: "State Code", accessor: "stateCode" },
      { Header: "Created On", accessor: "createdOn" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedCustomer(null); // Reset the selected customer
    setIsModalOpen(true);
  };

  const handleEdit = (customer) => {
    setModalMode("Edit");
    setSelectedCustomer(customer); // Pass selected customer data
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      // Add new customer
      const newCustomer = {
        ...formData,
        id: `CUST-${data.length + 1}`,
        createdOn: new Date().toISOString().split("T")[0],
      };
      setData([...data, newCustomer]);
    } else if (modalMode === "Edit") {
      // Update existing customer
      setData(
        data.map((item) =>
          item.id === selectedCustomer.id ? { ...item, ...formData } : item
        )
      );
    }
    setIsModalOpen(false);
    setSelectedCustomer(null); // Reset selected customer
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Sales Customers</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Customer
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Customer" : "Edit Customer"}
        width="w-1/2"
      >
        <SalesCustomersForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedCustomer} // Prefill the form for editing
        />
      </Modal>
    </div>
  );
};

export default SalesCustomers;
