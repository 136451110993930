import React from "react";

const Modal = ({ isOpen, onClose, title, children, customWidth = "w-1/2" }) => {
  if (!isOpen) return null; // Don't render anything if modal is not open

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal when clicking on the overlay
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOverlayClick} // Handle outside clicks
    >
      <div
        className={`bg-white rounded-lg shadow-lg ${customWidth} max-h-[90vh] overflow-y-auto`}
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b border-gray-300 p-4">
          <h3 className="text-lg font-bold">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✕
          </button>
        </div>

        {/* Modal Content */}
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
