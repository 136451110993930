import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import UserForm from "../../components/forms/UserForm"; // User Form Component

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // User table columns
  const userColumns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Name", accessor: "name" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone Number", accessor: "phoneNumber" },
    { Header: "Designation", accessor: "designation" },
    { Header: "Account Type", accessor: "accountType" },
    { Header: "Duty Start Time", accessor: "dutyStartTime" },
    { Header: "Duty End Time", accessor: "dutyEndTime" },
    { Header: "Joined Date", accessor: "joinedDate" },
    { Header: "Date of Birth", accessor: "dateOfBirth" },
    { Header: "Address", accessor: "address" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Summary table columns
  const summaryColumns = [
    { Header: "#", accessor: (_row, i) => i + 1 },
    { Header: "User Role", accessor: "role" },
    { Header: "Web App Access", accessor: "webAccess" },
    { Header: "Mobile App Access", accessor: "mobileAccess" },
    { Header: "Total No of Accounts", accessor: "totalAccounts" },
    { Header: "Active No of Accounts", accessor: "activeAccounts" },
  ];

  const summaryData = [
    {
      role: "Administrator",
      webAccess: "Yes",
      mobileAccess: "No",
      totalAccounts: 1,
      activeAccounts: 1,
    },
    {
      role: "General Manager",
      webAccess: "Yes",
      mobileAccess: "No",
      totalAccounts: 8,
      activeAccounts: 8,
    },
    // Add remaining rows here
    {
      role: "Quantity Surveyor",
      webAccess: "Yes",
      mobileAccess: "No",
      totalAccounts: 0,
      activeAccounts: 0,
    },
  ];

  const handleAddUser = (user) => {
    setUsers([...users, { ...user, id: users.length + 1 }]);
    setIsModalOpen(false);
  };

  const handleEditUser = (updatedUser) => {
    setUsers(
      users.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    );
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const handleAddClick = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="">

      {/* User Table */}
      <div className="bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">User Management</h1>
        <button
            onClick={handleAddClick}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Add New User
          </button>
        </div>
        <ReusableTable columns={userColumns} data={users} />
      </div>

      {/* Summary Table */}
      <div className="bg-white rounded-xl mt-5 p-6">
        <h2 className="text-xl font-bold mb-4">User Roles Summary</h2>
        <ReusableTable columns={summaryColumns} data={summaryData} />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedUser ? "Edit User" : "Add User"}
        >
          <UserForm
            onSubmit={selectedUser ? handleEditUser : handleAddUser}
            onCancel={closeModal}
            initialValues={
              selectedUser || {
                name: "",
                employeeCode: "",
                email: "",
                phoneNumber: "",
                designation: "",
                accountType: "",
                dutyStartTime: "",
                dutyEndTime: "",
                joinedDate: "",
                dateOfBirth: "",
                address: "",
                status: "Active",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default UserManagement;
