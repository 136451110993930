import React, { useState } from "react";

const DocumentForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium">Document Title</label>
        <input
          type="text"
          name="title"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.title}
          onChange={handleChange}
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Description</label>
        <textarea
          name="description"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.description}
          onChange={handleChange}
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Google Drive Link</label>
        <input
          type="url"
          name="file"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.file}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center space-x-4">
        <label className="block text-sm font-medium">Visible to All</label>
        <select
          name="visibleTo"
          className="border border-gray-300 rounded p-2"
          value={formData.visibleTo}
          onChange={handleChange}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default DocumentForm;
