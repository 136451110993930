import React from "react";

const InvoiceDetails = ({ invoice = {}, payments = [], onReceivePayment, onPrint, onEdit }) => {
  return (
    <div className="border p-4 rounded-lg shadow bg-gray-50">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-bold">Invoice ID : #{invoice.invoiceId || "N/A"}</h1>
        <div className="flex space-x-4">
          <button
            onClick={onReceivePayment}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Receive Payment
          </button>
          <button
            onClick={onPrint}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
          >
            Print Invoice
          </button>
          <button
            onClick={onEdit}
            className="bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-600"
          >
            Edit Invoice
          </button>
        </div>
      </div>

      {/* Invoice Header Details */}
      <div className="grid grid-cols-2 mb-6">
        <div>
          <p><strong>Invoice To:</strong></p>
          <p>{invoice.clientName || "N/A"}</p>
          <p>Contact No: {invoice.contactNo || "N/A"}</p>
          <p>GSTIN: {invoice.gstin || "N/A"}</p>
          <p>Project: {invoice.projectName || "N/A"}</p>
        </div>
        <div className="text-right">
          <p><strong>Invoice No:</strong> {invoice.invoiceNo || "N/A"}</p>
          <p><strong>Invoice Date:</strong> {invoice.invoiceDate || "N/A"}</p>
        </div>
      </div>

      {/* Invoice Items Table */}
      <table className="w-full border mb-6">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2 text-left">Sl No</th>
            <th className="border px-4 py-2 text-left">Description</th>
            <th className="border px-4 py-2 text-left">Item Code</th>
            <th className="border px-4 py-2 text-left">Quantity</th>
            <th className="border px-4 py-2 text-left">Unit</th>
            <th className="border px-4 py-2 text-left">Price</th>
            <th className="border px-4 py-2 text-left">Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoice.items && invoice.items.length > 0 ? (
            invoice.items.map((item, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{item.description || "N/A"}</td>
                <td className="border px-4 py-2">{item.itemCode || "N/A"}</td>
                <td className="border px-4 py-2">{item.quantity || "N/A"}</td>
                <td className="border px-4 py-2">{item.unit || "N/A"}</td>
                <td className="border px-4 py-2">{item.price || "N/A"}</td>
                <td className="border px-4 py-2">{item.totalAmount || "N/A"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="border px-4 py-2 text-center" colSpan="7">
                No items available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Payments Table */}
      <h3 className="text-lg font-bold mb-4">Payments Received</h3>
      <table className="w-full border mb-6">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2 text-left">Sl No</th>
            <th className="border px-4 py-2 text-left">Received Date</th>
            <th className="border px-4 py-2 text-left">Received Amount</th>
            <th className="border px-4 py-2 text-left">Credit Acc</th>
            <th className="border px-4 py-2 text-left">Remarks</th>
            <th className="border px-4 py-2 text-left">Received From</th>
            <th className="border px-4 py-2 text-left">Created By</th>
            <th className="border px-4 py-2 text-left">Created Date</th>
            <th className="border px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments && payments.length > 0 ? (
            payments.map((payment, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{payment.receivedDate || "N/A"}</td>
                <td className="border px-4 py-2">{payment.receivedAmount || "N/A"}</td>
                <td className="border px-4 py-2">{payment.creditAccount || "N/A"}</td>
                <td className="border px-4 py-2">{payment.remarks || "N/A"}</td>
                <td className="border px-4 py-2">{payment.receivedFrom || "N/A"}</td>
                <td className="border px-4 py-2">{payment.createdBy || "N/A"}</td>
                <td className="border px-4 py-2">{payment.createdDate || "N/A"}</td>
                <td className="border px-4 py-2">
                  <button className="text-blue-500 hover:underline mr-2">Edit</button>
                  <button className="text-red-500 hover:underline">Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="border px-4 py-2 text-center" colSpan="9">
                No payments available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceDetails;
