
import React from "react";
import SubcontractWorks from "../../components/ProjectDetails/SubContractWorks";

const SubcontractWork = () => {
  return (
    <div>
     <SubcontractWorks/>
    </div>
  );
};

export default SubcontractWork;
