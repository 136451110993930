import React, { useMemo, useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal"; // Assuming the reusable modal is already created
import InvoiceForm from "../forms/InvoiceForm";
import InvoiceDetails from "./InvoiceDetails"; // Invoice Details Component

const ClientPayments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for creating invoices
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal for editing invoices
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // Modal for receiving payment
  const [selectedInvoice, setSelectedInvoice] = useState(null); // Selected invoice for detailed view
  const [editInvoiceData, setEditInvoiceData] = useState(null); // Data for editing the invoice
  const [paymentDetails, setPaymentDetails] = useState({
    workName: "Demo",
    clientName: "RAVI",
    receivedAmount: "",
    receivedDate: "",
    receivedToAcc: "",
    remarks: "",
    receiptNo: "",
    receivedFrom: "",
  });
  const data = [
    {
      slNo: 1,
      invoiceId: "INV-001",
      clientName: "Client A",
      projectName: "Project X",
      invoiceDate: "2024-01-01",
      taxableAmount: "₹100,000",
      retentionAmount: "₹10,000",
      totalReceivableAmount: "₹118,000",
      receivedAmount: "₹50,000",
      balanceAmount: "₹68,000",
      status: "Pending",
      items: [
        { description: "Service A", itemCode: "S001", quantity: 1, unit: "Nos", price: 100000, totalAmount: 100000 },
      ],
      paymentStatus: "Pending",
      amountInWords: "One Lakh Only",
      payments: [],
    },
  ];

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      {
        Header: "Invoice No",
        accessor: "invoiceId",
        Cell: ({ row }) => (
          <button
            onClick={() => setSelectedInvoice(row.original)} // Set selected invoice
            className="text-blue-500 hover:underline"
          >
            {row.original.invoiceId}
          </button>
        ),
      },
      { Header: "Client Name", accessor: "clientName" },
      { Header: "Project Name", accessor: "projectName" },
      { Header: "Invoice Date", accessor: "invoiceDate" },
      { Header: "Taxable Amount", accessor: "taxableAmount" },
      { Header: "Retention Amount", accessor: "retentionAmount" },
      { Header: "Total Receivable Amount", accessor: "totalReceivableAmount" },
      { Header: "Received Amount", accessor: "receivedAmount" },
      { Header: "Balance Amount", accessor: "balanceAmount" },
      { Header: "Status", accessor: "status" },
    ],
    []
  );

  const handlePrintInvoice = (invoice) => {
    const printableContent = `
      <html>
        <head>
          <title>Invoice Print</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            h1 { text-align: center; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          <h1>Invoice: ${invoice.invoiceId}</h1>
          <p><strong>Client Name:</strong> ${invoice.clientName}</p>
          <p><strong>Project Name:</strong> ${invoice.projectName}</p>
          <p><strong>Invoice Date:</strong> ${invoice.invoiceDate}</p>
          <table>
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Description</th>
                <th>Item Code</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Price</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              ${invoice.items
        .map(
          (item, index) => `
                    <tr>
                      <td>${index + 1}</td>
                      <td>${item.description}</td>
                      <td>${item.itemCode}</td>
                      <td>${item.quantity}</td>
                      <td>${item.unit}</td>
                      <td>${item.price}</td>
                      <td>${item.totalAmount}</td>
                    </tr>
                  `
        )
        .join("")}
            </tbody>
          </table>
          <p><strong>Amount in Words:</strong> ${invoice.amountInWords}</p>
        </body>
      </html>
    `;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(printableContent);
    printWindow.document.close();
    printWindow.print();
  };

  const handleEditInvoice = (invoice) => {
    setEditInvoiceData(invoice); // Pre-fill data in the form
    setIsEditModalOpen(true); // Open the edit modal
  };
  const handlePaymentSubmit = () => {
    console.log("Payment Details:", paymentDetails);
    setIsPaymentModalOpen(false);
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        {selectedInvoice ? (
          <>
            <h2 className="text-xl font-bold">Invoice Details</h2>
            <button
              onClick={() => setSelectedInvoice(null)} // Switch back to list
              className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600"
            >
              Back to Invoice List
            </button>
          </>
        ) : (
          <>
            <h2 className="text-xl font-bold">Client Payments & Invoices</h2>
            <button
              onClick={() => setIsModalOpen(true)} // Open modal on button click
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
            >
              Create Invoice
            </button>
          </>
        )}
      </div>

      {selectedInvoice ? (
        <InvoiceDetails
          invoice={selectedInvoice}
          onReceivePayment={() => setIsPaymentModalOpen(true)} // Open payment modal
          onPrint={() => handlePrintInvoice(selectedInvoice)} // Print invoice
          onEdit={() => handleEditInvoice(selectedInvoice)} // Edit invoice
        />
      ) : (
        <ReusableTable columns={columns} data={data} />
      )}
      <Modal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        title="Receive Payment From Client"
        width="w-3/4 max-w-6xl"
      >
        <form className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Work Name</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.workName}
              disabled
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Client Name</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.clientName}
              disabled
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Received Amount</label>
            <input
              type="number"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.receivedAmount}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, receivedAmount: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Received Date</label>
            <input
              type="date"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.receivedDate}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, receivedDate: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Received To Acc</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.receivedToAcc}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, receivedToAcc: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Remarks</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.remarks}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, remarks: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Receipt No</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.receiptNo}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, receiptNo: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Received From</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2"
              value={paymentDetails.receivedFrom}
              onChange={(e) =>
                setPaymentDetails({ ...paymentDetails, receivedFrom: e.target.value })
              }
            />
          </div>
        </form>
        <div className="flex justify-end mt-4">
          <button
            onClick={handlePaymentSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </Modal>
      {/* Modal for Create/Edit Invoice */}
      <Modal
        isOpen={isModalOpen || isEditModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsEditModalOpen(false);
        }}
        title={isEditModalOpen ? "Edit Invoice" : "Create Invoice"}
        width="w-3/4 max-w-6xl"
      >
        <InvoiceForm
          onSubmit={() => {
            setIsModalOpen(false);
            setIsEditModalOpen(false);
          }}
          initialValues={editInvoiceData} // Pass initial values for editing
        />
      </Modal>
    </div>
  );
};

export default ClientPayments;
