import React, { useState } from "react";

const UpdateStatusForm = ({ currentStatus, onSubmit, onCancel }) => {
  const [status, setStatus] = useState(currentStatus || "Pending");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(status); // Pass the selected status to the parent component
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Status</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full border border-gray-300 rounded p-2"
        >
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
        </select>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Update Status
        </button>
      </div>
    </form>
  );
};

export default UpdateStatusForm;
