import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import ReminderForm from "../../components/forms/ReminderForm"; // Form for Reminders

const Reminders = () => {
  const [reminders, setReminders] = useState([
    {
      id: 1,
      type: "Meeting",
      reference: "Project A",
      title: "Client Discussion",
      description: "Discuss project milestones and deliverables.",
      reminderDate: "2024-12-10",
      fileLink: "https://drive.google.com/examplefile",
      creationDate: "2024-12-08",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedReminder, setSelectedReminder] = useState(null); // For editing

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setReminders([
        ...reminders,
        { id: reminders.length + 1, creationDate: new Date().toISOString().split("T")[0], ...formData },
      ]);
    } else {
      setReminders(
        reminders.map((reminder) =>
          reminder.id === selectedReminder.id ? { ...formData } : reminder
        )
      );
    }
    closeModal();
  };

  const handleDelete = (id) => {
    setReminders(reminders.filter((reminder) => reminder.id !== id));
  };

  const openModal = (mode, reminder = null) => {
    setModalMode(mode);
    setSelectedReminder(reminder);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReminder(null);
  };

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Reminder Type", accessor: "type" },
    { Header: "Reference", accessor: "reference" },
    { Header: "Title", accessor: "title" },
    { Header: "Description", accessor: "description" },
    { Header: "Reminder Date", accessor: "reminderDate" },
    {
      Header: "File",
      accessor: "fileLink",
      Cell: ({ value }) =>
        value ? (
          <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-500">
            Open File
          </a>
        ) : (
          "No File"
        ),
    },
    { Header: "Creation", accessor: "creationDate" },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl">

      {/* Reminders Section */}
      <div className="mb-10">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Reminders</h1>
        <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add")}
          >
            Add New Reminder
          </button>
        </div>
        <ReusableTable columns={columns} data={reminders} />
      </div>

      {/* Modal for Add/Edit */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Reminder" : "Edit Reminder"}
        >
          <ReminderForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedReminder || {
                type: "",
                reference: "",
                title: "",
                description: "",
                reminderDate: "",
                fileLink: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Reminders;
