import React, { useState } from "react";

const NoteForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium">Work Name</label>
        <select
          name="work"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.work}
          onChange={handleChange}
        >
          <option value="">Select Work</option>
          <option value="Road Construction">Road Construction</option>
          <option value="Building Construction">Building Construction</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium">Title</label>
        <input
          type="text"
          name="noteTitle"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.noteTitle}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Description</label>
        <textarea
          name="description"
          className="w-full border border-gray-300 rounded p-2"
          rows="4"
          value={formData.description}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Note Date</label>
        <input
          type="date"
          name="noteDate"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.noteDate}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Image (Optional)</label>
        <input
          type="file"
          name="image"
          className="w-full border border-gray-300 rounded p-2"
          onChange={(e) => handleChange({ target: { name: "image", value: e.target.files[0].name } })}
        />
      </div>

      <hr className="my-4" />
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default NoteForm;
