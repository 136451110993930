import React, { useState } from "react";

// Reusable Input Component
const InputField = ({ label, name, value, onChange, type = "text", readOnly = false }) => (
  <div>
    <label className="block text-sm font-medium">{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="w-full border border-gray-300 rounded p-2"
      readOnly={readOnly}
    />
  </div>
);

// Reusable TextArea Component
const TextAreaField = ({ label, name, value, onChange }) => (
  <div>
    <label className="block text-sm font-medium">{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      className="w-full border border-gray-300 rounded p-2"
      rows="4"
    ></textarea>
  </div>
);

const SubContractWorkForm = ({ initialValues, projectName, onSubmit }) => {
  const [formData, setFormData] = useState(
    initialValues || {
      contractDetails: {
        projectName: projectName || "", // Use projectName if provided
        subContractWorkName: "",
        contractType: "Fixed Rate", // Default value
        referenceNo: "",
        contractor: "",
        date: "",
        workDescription: "",
      },
      workDetails: [{ id: 1, workType: "", totalAmount: 0, remarks: "" }],
      summary: {
        totalNoOfWorks: 1,
        retentionPercentage: 0,
        retentionAmount: 0,
        totalEstimatedAmount: 0,
        totalPayableAmount: 0,
        openingBalancePaid: 0,
        balancePayableAmount: 0,
        billingAddress: "Genova Technologies\n1st Floor, Basil Building\nWesthill\nCalicut - 673005",
      },
    }
  );

  const handleChange = (section, key, value, index = null) => {
    if (index !== null) {
      const updatedWorkDetails = [...formData.workDetails];
      updatedWorkDetails[index][key] = value;
      updateSummary(updatedWorkDetails);
      setFormData((prev) => ({ ...prev, workDetails: updatedWorkDetails }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [section]: { ...prev[section], [key]: value },
      }));
    }
  };

  const addWorkDetail = () => {
    setFormData((prev) => ({
      ...prev,
      workDetails: [
        ...prev.workDetails,
        { id: prev.workDetails.length + 1, workType: "", totalAmount: 0, remarks: "" },
      ],
      summary: { ...prev.summary, totalNoOfWorks: prev.summary.totalNoOfWorks + 1 },
    }));
  };

  const removeWorkDetail = (id) => {
    const updatedWorkDetails = formData.workDetails.filter((work) => work.id !== id);
    updateSummary(updatedWorkDetails);
    setFormData((prev) => ({
      ...prev,
      workDetails: updatedWorkDetails,
      summary: { ...prev.summary, totalNoOfWorks: updatedWorkDetails.length },
    }));
  };

  const updateSummary = (workDetails) => {
    const totalEstimatedAmount = workDetails.reduce((sum, work) => sum + parseFloat(work.totalAmount || 0), 0);
    const retentionAmount = (totalEstimatedAmount * formData.summary.retentionPercentage) / 100;
    const totalPayableAmount = totalEstimatedAmount - retentionAmount;

    setFormData((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        totalEstimatedAmount,
        retentionAmount,
        totalPayableAmount,
        balancePayableAmount: totalPayableAmount - prev.summary.openingBalancePaid,
      },
    }));
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(formData);
  };

  return (
    <div className="bg-white space-y-6">
      {/* Contract Details */}
      <h2 className="text-lg font-bold">Contract Details</h2>
      <div className="grid grid-cols-3 gap-4">
        {[
          { label: "Project Name", name: "projectName", readOnly: !!projectName }, // Read-only if projectName is provided
          { label: "Subcontract Work Name", name: "subContractWorkName" },
          { label: "Reference No", name: "referenceNo" },
          { label: "Contractor", name: "contractor" },
          {
            label: "Contract Type",
            name: "contractType",
            type: "select",
            options: ["Fixed Rate", "Unit Based Rate"],
          },
          { label: "Date", name: "date", type: "date" },
        ].map((field, idx) => (
          <div key={idx}>
            {field.type === "select" ? (
              <div>
                <label className="block text-sm font-medium">{field.label}</label>
                <select
                  name={field.name}
                  value={formData.contractDetails[field.name]}
                  onChange={(e) => handleChange("contractDetails", field.name, e.target.value)}
                  className="w-full border border-gray-300 rounded p-2"
                >
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <InputField
                label={field.label}
                name={field.name}
                value={formData.contractDetails[field.name]}
                onChange={(e) => handleChange("contractDetails", field.name, e.target.value)}
                type={field.type}
                readOnly={field.readOnly}
              />
            )}
          </div>
        ))}
      </div>
      <TextAreaField
        label="Work Description"
        name="workDescription"
        value={formData.contractDetails.workDescription}
        onChange={(e) => handleChange("contractDetails", "workDescription", e.target.value)}
      />

      {/* Work Details */}
      <h2 className="text-lg font-bold">Work Details</h2>
      {formData.workDetails.map((work, index) => (
        <div key={work.id} className="grid grid-cols-4 gap-4 items-center">
          {["Work Type", "Total Amt", "Remarks"].map((label, idx) => (
            <InputField
              key={idx}
              label={label}
              name={label === "Total Amt" ? "totalAmount" : label.toLowerCase().replace(" ", "")}
              value={label === "Total Amt" ? work.totalAmount : work[label.toLowerCase().replace(" ", "")]}
              onChange={(e) => handleChange("workDetails", e.target.name, e.target.value, index)}
              type={label === "Total Amt" ? "number" : "text"}
            />
          ))}
          <button
            onClick={() => removeWorkDetail(work.id)}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      ))}
      <button
        onClick={addWorkDetail}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
      >
        Add New Work
      </button>

      {/* Summary */}
      <h2 className="text-lg font-bold">Summary</h2>
      <div className="grid grid-cols-3 gap-4">
        {[
          { label: "Total No. of Works", name: "totalNoOfWorks", readOnly: true },
          { label: "Retention %", name: "retentionPercentage" },
          { label: "Retention Amount", name: "retentionAmount", readOnly: true },
          { label: "Total Estimated Amount", name: "totalEstimatedAmount", readOnly: true },
          { label: "Total Payable Amount", name: "totalPayableAmount", readOnly: true },
          { label: "Opening Balance Paid", name: "openingBalancePaid" },
        ].map((field, idx) => (
          <InputField
            key={idx}
            label={field.label}
            name={field.name}
            value={formData.summary[field.name]}
            onChange={(e) => handleChange("summary", field.name, e.target.value)}
            type="number"
            readOnly={field.readOnly}
          />
        ))}
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-6 py-2 rounded shadow hover:bg-green-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SubContractWorkForm;
