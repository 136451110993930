import React, { useState } from "react";
import ReusableTable from "../common/ReusableTable"; // Reusable Table Component

const LabourWageGroupForm = ({ onSubmit, onCancel }) => {
  const [teamLeader, setTeamLeader] = useState("");
  const [workDate, setWorkDate] = useState("");
  const [labourList, setLabourList] = useState([
    {
      srNo: 1,
      labour: "John Doe",
      worked: "Yes",
      work: "Construction",
      days: 5,
      labours: 1,
      basicWage: 500,
      overtime: 2,
      extra: 100,
      ta: 50,
      total: 1250,
      totalPaid: 1000,
    },
    {
      srNo: 2,
      labour: "Jane Smith",
      worked: "No",
      work: "",
      days: 0,
      labours: 0,
      basicWage: 0,
      overtime: 0,
      extra: 0,
      ta: 0,
      total: 0,
      totalPaid: 0,
    },
  ]);

  const columns = [
    { Header: "Sr No", accessor: "srNo" },
    { Header: "Labour", accessor: "labour" },
    { Header: "Worked", accessor: "worked" },
    { Header: "Work", accessor: "work" },
    { Header: "Days", accessor: "days" },
    { Header: "Labours", accessor: "labours" },
    { Header: "Basic Wage", accessor: "basicWage" },
    { Header: "Overtime", accessor: "overtime" },
    { Header: "Extra", accessor: "extra" },
    { Header: "TA", accessor: "ta" },
    { Header: "Total", accessor: "total" },
    { Header: "Total Paid", accessor: "totalPaid" },
  ];

  const handleSubmit = () => {
    onSubmit({
      teamLeader,
      workDate,
      labourList,
    });
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium">Select Team Leader</label>
          <select
            value={teamLeader}
            onChange={(e) => setTeamLeader(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">-- Select Team Leader --</option>
            <option value="John Leader">John Leader</option>
            <option value="Jane Leader">Jane Leader</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">Work Date</label>
          <input
            type="date"
            value={workDate}
            onChange={(e) => setWorkDate(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      {/* Labour List Table */}
      <ReusableTable columns={columns} data={labourList} />

      {/* Actions */}
      <div className="flex justify-end space-x-4 mt-4">
        <button
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default LabourWageGroupForm;
