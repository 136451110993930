import React from "react";
import AdminRoutes from "../routes/AdminRoutes";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";

const AdminLayout = () => {
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div className="sidebar bg-white shadow h-full">
        <Sidebar />
      </div>

      {/* Main Content Area */}
      <div className="flex-grow flex flex-col bg-gray-100 overflow-hidden">
        {/* Topbar */}
        <Header />

        {/* Main Content */}
        <main className="flex-grow p-6 overflow-y-auto">
          <AdminRoutes /> {/* Render the child routes */}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
