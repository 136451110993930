import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Import the reusable table
import ToolForm from "../../components/forms/ToolForm"; // Import the tool form
import Modal from "../../components/common/Modal"; // Reusable Modal

const ToolsManagement = () => {
  const [tools, setTools] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Tool Name", accessor: "toolName" },
    { Header: "Category", accessor: "category" },
    { Header: "Description", accessor: "description" },
    { Header: "Total Owned Qty", accessor: "totalOwnedQty" },
    { Header: "Price / Day", accessor: "pricePerDay" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add new tool
  const handleAddTool = (tool) => {
    setTools([...tools, { ...tool, id: tools.length + 1 }]);
    setIsModalOpen(false);
  };

  // Edit existing tool
  const handleEditTool = (updatedTool) => {
    setTools(tools.map((tool) => (tool.id === updatedTool.id ? updatedTool : tool)));
    setIsModalOpen(false);
  };

  // Delete tool
  const handleDelete = (id) => {
    setTools(tools.filter((tool) => tool.id !== id));
  };

  // Open modal for adding a new tool
  const handleAddClick = () => {
    setSelectedTool(null);
    setIsModalOpen(true);
  };

  // Open modal for editing a tool
  const handleEdit = (tool) => {
    setSelectedTool(tool);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTool(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
      <h1 className="text-2xl font-bold ">Tools & Equipment Management</h1>

        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Tool
        </button>
      </div>
      <ReusableTable columns={columns} data={tools} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedTool ? "Edit Tool" : "Add New Tool"}
        >
          <ToolForm
            onSubmit={selectedTool ? handleEditTool : handleAddTool}
            onCancel={closeModal}
            initialValues={selectedTool || { toolName: "", category: "", description: "", totalOwnedQty: 0, pricePerDay: 0 }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ToolsManagement;
