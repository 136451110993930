import React, { useState } from "react";

const SecurityDepositForm = ({ onSubmit, onCancel, projectName = "", clientName = "" }) => {
  const [formData, setFormData] = useState({
    projectName: projectName,
    clientName: clientName,
    date: "",
    depositAmount: "",
    retentionMoney: "",
    ld: "",
    incomeTax: "",
    gstTds: "",
  });

  const [errors, setErrors] = useState({}); // Track field-specific errors

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error on input change
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) newErrors.date = "Date is required.";
    if (!formData.depositAmount) newErrors.depositAmount = "Deposit Amount is required.";
    if (!formData.projectName) newErrors.projectName = "Project Name is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Grid Layout */}
      <div className="grid grid-cols-2 gap-4">
        {/* Project Name */}
        <div>
          <label className="block text-sm font-medium">Project Name</label>
          {projectName ? (
            <input
              type="text"
              name="projectName"
              className="w-full border border-gray-300 rounded p-2 bg-gray-100 text-gray-700 cursor-not-allowed"
              value={formData.projectName}
              readOnly
              disabled
            />
          ) : (
            <select
              name="projectName"
              className={`w-full border rounded p-2 ${
                errors.projectName ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.projectName}
              onChange={handleInputChange}
            >
              <option value="">Select a project</option>
              {/* Add project options dynamically or statically */}
              <option value="Project A">Project A</option>
              <option value="Project B">Project B</option>
              <option value="Project C">Project C</option>
            </select>
          )}
          {errors.projectName && (
            <p className="text-red-500 text-sm mt-1">{errors.projectName}</p>
          )}
        </div>

        {/* Client Name */}
        <div>
          <label className="block text-sm font-medium">Client Name</label>
          <input
            type="text"
            name="clientName"
            className="w-full border border-gray-300 rounded p-2 bg-gray-100 text-gray-700 cursor-not-allowed"
            value={formData.clientName}
            readOnly
            disabled
          />
        </div>

        {/* Date */}
        <div>
          <label className="block text-sm font-medium">Date</label>
          <input
            type="date"
            name="date"
            className={`w-full border rounded p-2 ${
              errors.date ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.date}
            onChange={handleInputChange}
          />
          {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date}</p>}
        </div>

        {/* Deposit Amount */}
        <div>
          <label className="block text-sm font-medium">Deposit Amount</label>
          <input
            type="number"
            name="depositAmount"
            className={`w-full border rounded p-2 ${
              errors.depositAmount ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.depositAmount}
            onChange={handleInputChange}
          />
          {errors.depositAmount && (
            <p className="text-red-500 text-sm mt-1">{errors.depositAmount}</p>
          )}
        </div>

        {/* Retention Money */}
        <div>
          <label className="block text-sm font-medium">Retention Money</label>
          <input
            type="number"
            name="retentionMoney"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.retentionMoney}
            onChange={handleInputChange}
          />
        </div>

        {/* Liquidated Damages (LD) */}
        <div>
          <label className="block text-sm font-medium">Liquidated Damages (LD)</label>
          <input
            type="number"
            name="ld"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.ld}
            onChange={handleInputChange}
          />
        </div>

        {/* Income Tax */}
        <div>
          <label className="block text-sm font-medium">Income Tax</label>
          <input
            type="number"
            name="incomeTax"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.incomeTax}
            onChange={handleInputChange}
          />
        </div>

        {/* GST/TDS */}
        <div>
          <label className="block text-sm font-medium">GST/TDS</label>
          <input
            type="number"
            name="gstTds"
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter GST or TDS"
            value={formData.gstTds}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4 mt-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SecurityDepositForm;
