
import React from "react";
import StockManagement from "../../components/ProjectDetails/StockManagement";

const StockManagements = () => {
  return (
    <div>
     <StockManagement/>
    </div>
  );
};

export default StockManagements;
