import React from "react";

const ForgotPassword = () => {
  return (
    <div>
      <h1>Forgot Password</h1>
      <form>
        <input type="email" placeholder="Enter your email" required />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
