import React, { useState } from "react";

const TaxForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium">Tax Name</label>
        <input
          type="text"
          name="taxName"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.taxName}
          onChange={handleChange}
        />
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium">CGST %</label>
          <input
            type="number"
            name="cgst"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.cgst}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">SGST %</label>
          <input
            type="number"
            name="sgst"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.sgst}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">IGST %</label>
          <input
            type="number"
            name="igst"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.igst}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Total GST %</label>
          <input
            type="number"
            className="w-full border border-gray-300 rounded p-2 bg-gray-100"
            value={
              (parseFloat(formData.cgst) || 0) +
              (parseFloat(formData.sgst) || 0) +
              (parseFloat(formData.igst) || 0)
            }
            readOnly
          />
        </div>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default TaxForm;
