import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import BOQForm from "../../components/forms/BOQForm"; // Form for adding/editing BOQ entries
import UpdateStatusForm from "../../components/forms/UpdateStatusForm"; // Form for updating status

const BOQ = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentBOQ, setCurrentBOQ] = useState(null); // Holds the currently selected BOQ for editing or status update
  const [data, setData] = useState([
    {
      srNo: 1,
      boqName: "BOQ-001",
      workOrderNo: "WO-123",
      totalAmount: "₹1,000,000",
      status: "Approved",
      attachmentDate: "2024-12-01",
      id: "BOQ-001",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sr No", accessor: "srNo" },
      { Header: "BOQ Name", accessor: "boqName" },
      { Header: "Work Order No", accessor: "workOrderNo" },
      { Header: "Total Amount", accessor: "totalAmount" },
      { Header: "Status", accessor: "status" },
      { Header: "Attachment Date", accessor: "attachmentDate" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-yellow-500 text-white px-3 py-1 rounded"
              onClick={() => handleUpdateStatus(row.original)}
            >
              Update Status
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setCurrentBOQ(null); // Clear the current BOQ for a fresh form
    setIsModalOpen(true);
  };

  const handleEdit = (boq) => {
    setCurrentBOQ(boq); // Set the selected BOQ for editing
    setIsModalOpen(true);
  };

  const handleUpdateStatus = (boq) => {
    setCurrentBOQ(boq); // Set the selected BOQ for status update
    setIsStatusModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (newBOQ) => {
    if (currentBOQ) {
      // Update existing BOQ
      const updatedData = data.map((item) =>
        item.id === currentBOQ.id ? { ...item, ...newBOQ } : item
      );
      setData(updatedData);
    } else {
      // Add new BOQ
      const newData = {
        ...newBOQ,
        srNo: data.length + 1,
        attachmentDate: new Date().toISOString().split("T")[0],
        id: `BOQ-${data.length + 1}`,
      };
      setData([...data, newData]);
    }
    setIsModalOpen(false);
    setCurrentBOQ(null); // Clear current BOQ after submission
  };

  const handleStatusSubmit = (updatedStatus) => {
    const updatedData = data.map((item) =>
      item.id === currentBOQ.id ? { ...item, status: updatedStatus } : item
    );
    setData(updatedData);
    setIsStatusModalOpen(false);
    setCurrentBOQ(null); // Clear current BOQ after status update
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">BOQ</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New BOQ
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      {/* Modal for Adding/Editing BOQ */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setCurrentBOQ(null);
        }}
        title={currentBOQ ? "Edit BOQ" : "Add New BOQ"}
        width="w-1/2"
      >
        <BOQForm
          initialValues={currentBOQ || {}}
          onSubmit={handleFormSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setCurrentBOQ(null);
          }}
        />
      </Modal>

      {/* Modal for Updating Status */}
      <Modal
        isOpen={isStatusModalOpen}
        onClose={() => {
          setIsStatusModalOpen(false);
          setCurrentBOQ(null);
        }}
        title="Update BOQ Status"
        width="w-1/2"
      >
        <UpdateStatusForm
          currentStatus={currentBOQ?.status || ""}
          onSubmit={handleStatusSubmit}
          onCancel={() => {
            setIsStatusModalOpen(false);
            setCurrentBOQ(null);
          }}
        />
      </Modal>
    </div>
  );
};

export default BOQ;
