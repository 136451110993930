
import React from "react";
import Purchase from "../../components/ProjectDetails/Purchase";

const Purchases = () => {
  return (
    <div>
      <Purchase/>
    </div>
  );
};

export default Purchases;
