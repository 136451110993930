import React, { useState } from "react";
import Summary from "../../components/ProjectDetails/Summary";
import ProjectHeader from "../../components/ProjectDetails/ProjectHeader";
import Sidebar from "../../components/ProjectDetails/Sidebar";
import ProjectInfo from "../../components/ProjectDetails/ProjectInfo";
import Modal from "../../components/common/Modal"; // Import the reusable modal
import ClientPayments from "../../components/ProjectDetails/ClientPayments";
import SecurityDeposits from "../../components/ProjectDetails/SecurityDeposits";
import BOQ from "../../components/ProjectDetails/BOQ";
import CustomRequirements from "../../components/ProjectDetails/CustomRequirements";
import StockManagement from "../../components/ProjectDetails/StockManagement";
import LabourWageSlips from "../../components/ProjectDetails/LabourWageSlips";
import ProjectScheduling from "../../components/ProjectDetails/ProjectScheduling";
import Purchase from "../../components/ProjectDetails/Purchase";
import SalarySlips from "../../components/ProjectDetails/SalarySlips";
import VehicleWorkSlip from "../../components/ProjectDetails/VehicleWorkSlip";
import ExpenseBills from "../../components/ProjectDetails/ExpenseBills";
import SubcontractWorks from "../../components/ProjectDetails/SubContractWorks";
import DailyTasks from "./DailyTasks";
import DailyNotes from "./DailyNotes";
import ToolsManagement from "./ToolsManagement";
import AssignSupervisor from "../../components/ProjectDetails/AssignSupervisor";
import CreditorsDebitors from "../../components/ProjectDetails/CreditorsDebitors";
import BalanceSheet from "../../components/ProjectDetails/BalanceSheet";
import ProjectDocuments from "../../components/ProjectDetails/ProjectDocuments";

const ProjectDetails = () => {
    const [activeTab, setActiveTab] = useState("overview");
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
    const [projectStatus, setProjectStatus] = useState("In Progress"); // Current project status

    const tabs = [
        { id: "overview", label: "Project Overview", icon: "📊" },
        { id: "info", label: "Project Info", icon: "ℹ️" },
        { id: "payments", label: "Client Payments & Invoices", icon: "💰" },
        { id: "security", label: "Security Deposits", icon: "🔒" },
        { id: "boq", label: "BOQ", icon: "📄" },
        { id: "scheduling", label: "Project Scheduling", icon: "📅" },
        { id: "requirements", label: "Custom Requirements", icon: "🛠️" },
        { id: "purchases", label: "Purchases", icon: "🛒" },
        { id: "stock", label: "Stock Management", icon: "📦" },
        { id: "wages", label: "Labour Wage Slips", icon: "📝" },
        { id: "tools", label: "Tools Management", icon: "🔧" },
        { id: "salary", label: "Salary Slips & Payments", icon: "💳" },
        { id: "vehicle", label: "Vehicle Work Slips & Payments", icon: "🚗" },
        { id: "expenses", label: "Expense Bills", icon: "🧾" },
        { id: "subcontract", label: "Subcontract Works & Payments", icon: "🔨" },
        { id: "documents", label: "Project Documents", icon: "📂" },
        { id: "tasks", label: "Daily Tasks & Activities", icon: "✅" },
        { id: "notes", label: "Daily Notes", icon: "📝" },
        { id: "supervisors", label: "Assigned Supervisors & Commissions", icon: "👷" },
        { id: "creditors", label: "Creditors & Debitors List", icon: "💼" },
        { id: "balance", label: "Balance Sheet", icon: "📊" },
    ];


    const renderContent = () => {
        switch (activeTab) {
            case "overview":
                return (
                    <Summary
                        stats={[
                            { label: "Project Budget", value: "₹1,30,000.00", color: "text-green-500" },
                            { label: "Received From Client", value: "₹0.00", color: "text-gray-500" },
                            { label: "Total Project Cost", value: "₹0.00", color: "text-gray-500" },
                            { label: "Total Expense Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Purchase Bill", value: "₹0.00", color: "text-gray-500" },
                            { label: "Subcontract Bill", value: "₹0.00", color: "text-gray-500" },
                            { label: "Labour Wages", value: "₹0.00", color: "text-gray-500" },
                            { label: "Expense Bill", value: "₹0.00", color: "text-gray-500" },
                            { label: "Salary Slip", value: "₹0.00", color: "text-gray-500" },
                            { label: "Vehicle Slip", value: "₹0.00", color: "text-gray-500" },
                            { label: "Purchase Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Subcontract Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Tools Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Other Expense Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Salary Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Vehicle Payments", value: "₹0.00", color: "text-gray-500" },
                            { label: "Payable Credits", value: "₹0.00", color: "text-gray-500" },
                            { label: "Actual Profit", value: "₹1,30,000.00", color: "text-green-500" },
                            { label: "Balance to Receive on Budget", value: "₹1,30,000.00", color: "text-green-500" },
                        ]}
                        tableData={[
                            { head: "Project Budget Estimated", amount: "₹1,30,000.00" },
                            { head: "Custom Requirement Budget Estimated", amount: "₹0.00" },
                            { head: "Total Project Budget", amount: "₹1,30,000.00" },
                        ]}
                    />
                );
            case "info":
                return <ProjectInfo />;
            case "payments":
                return <ClientPayments />;
            case "security":
                return <SecurityDeposits projectName="Project A" />;
            case "boq":
                return <BOQ />;
            case "scheduling":
                return <ProjectScheduling />;
            case "requirements":
                return <CustomRequirements />;
            case "purchases":
                return <Purchase />;
            case "stock":
                return <StockManagement />;
            case "wages":
                return <LabourWageSlips />;
            case "tools":
                return <ToolsManagement/>;
            case "salary":
                return <SalarySlips />;
            case "vehicle":
                return <VehicleWorkSlip />;
            case "expenses":
                return <ExpenseBills />;
            case "subcontract":
                return <SubcontractWorks projectName="Project A" />;
            case "documents":
                return <ProjectDocuments/>;
            case "tasks":
                return <DailyTasks
                sectionTitle="Tasks for Project: Building Construction"
                isProjectSpecific={true}
                projectName="Building Construction"
                projectId={101}
              />
              ;
            case "notes":
                return <DailyNotes/>;
            case "supervisors":
                return <AssignSupervisor/>;
            case "creditors":
                return <CreditorsDebitors/>;
            case "balance":
                return <BalanceSheet/>;
            default:
                return <div>Content not found</div>;
        }
    };

    const handleStatusChange = (status) => {
        setProjectStatus(status); // Update project status
        setIsModalOpen(false); // Close the modal
    };

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className="flex bg-gray-100 gap-4">
            {/* Sidebar */}
            <Sidebar activeTab={activeTab} setActiveTab={handleTabChange} tabs={tabs} />

            {/* Main Content */}
            <main className="w-3/4 flex flex-col">
                {/* Project Header */}
                <ProjectHeader
                    onEditDetails={() => alert("Edit Project Details Clicked")}
                    onEditStatus={() => setIsModalOpen(true)} // Open modal
                    onPrintSummary={() => alert("Print Project Summary Clicked")}
                />
                {/* Tab Content */}
                <div className="flex-grow">{renderContent()}</div>
            </main>

            {/* Reusable Modal for Editing Status */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Edit Work Status"
            >
                <div className="space-y-3">
                    {["In Progress", "Completed", "Freeze"].map((status) => (
                        <button
                            key={status}
                            className={`w-full px-4 py-2 rounded text-left ${projectStatus === status ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700"
                                }`}
                            onClick={() => handleStatusChange(status)}
                        >
                            {status}
                        </button>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default ProjectDetails;
