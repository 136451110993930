import React, { useState } from "react";

const VehicleWorkSlipForm = ({ onSubmit }) => {
  const [formDetails, setFormDetails] = useState({
    vehicleName: "",
    workDate: "",
    project: "",
    workedDuration: 0,
    durationType: "",
    basicCharge: 0,
    overtimeDuration: 0,
    overtimeRate: 0,
    overtimeAmount: 0,
    commission: 0,
    labourCharge: 0,
    driverBata: 0,
    includeDriverBata: false,
    loadingCharge: 0,
    otherExpenses: 0,
    totalPayableAmount: 0,
    remarks: "",
    transactionDetails: {
      paidAmount: 0,
      paidDate: "",
      paidFromAcc: "Cash Account",
      paymentMode: "",
      trReferenceNo: "",
      trReferenceDate: "",
      transactionRemarks: "",
      totalBalance: 0,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTransactionChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      transactionDetails: {
        ...prev.transactionDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Submitted Form Details:", formDetails);
    if (onSubmit) onSubmit(formDetails);
  };

  return (
    <div className="bg-white  space-y-6">
      {/* Work Slip Details */}
      <div className="space-y-4">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Vehicle Name</label>
            <input
              type="text"
              name="vehicleName"
              value={formDetails.vehicleName}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Select Vehicle"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Work Date</label>
            <input
              type="date"
              name="workDate"
              value={formDetails.workDate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Project</label>
            <input
              type="text"
              name="project"
              value={formDetails.project}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Select Work"
            />
          </div>
        </div>

        {/* Additional Details */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Worked Duration</label>
            <input
              type="number"
              name="workedDuration"
              value={formDetails.workedDuration}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Duration Type</label>
            <input
              type="text"
              name="durationType"
              value={formDetails.durationType}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="e.g., Hours or Days"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Basic Charge</label>
            <input
              type="number"
              name="basicCharge"
              value={formDetails.basicCharge}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Overtime Duration</label>
            <input
              type="number"
              name="overtimeDuration"
              value={formDetails.overtimeDuration}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Overtime Rate</label>
            <input
              type="number"
              name="overtimeRate"
              value={formDetails.overtimeRate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Overtime Amount</label>
            <input
              type="number"
              name="overtimeAmount"
              value={formDetails.overtimeAmount}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>

        {/* Expenses */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Driver Bata</label>
            <input
              type="number"
              name="driverBata"
              value={formDetails.driverBata}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Include Driver Bata</label>
            <input
              type="checkbox"
              name="includeDriverBata"
              checked={formDetails.includeDriverBata}
              onChange={handleChange}
              className="h-5 w-5"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Loading Charge</label>
            <input
              type="number"
              name="loadingCharge"
              value={formDetails.loadingCharge}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Commission</label>
            <input
              type="number"
              name="commission"
              value={formDetails.commission}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Labour Charge</label>
            <input
              type="number"
              name="labourCharge"
              value={formDetails.labourCharge}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Other Expenses</label>
            <input
              type="number"
              name="otherExpenses"
              value={formDetails.otherExpenses}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium">Remarks</label>
          <textarea
            name="remarks"
            value={formDetails.remarks}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Work slip remarks"
          ></textarea>
        </div>
      </div>

      {/* Transaction Details */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold">Transaction Details</h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Paid Amount</label>
            <input
              type="number"
              name="paidAmount"
              value={formDetails.transactionDetails.paidAmount}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid Date</label>
            <input
              type="date"
              name="paidDate"
              value={formDetails.transactionDetails.paidDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid From Acc</label>
            <select
              name="paidFromAcc"
              value={formDetails.transactionDetails.paidFromAcc}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Cash Account">Cash Account</option>
              <option value="Bank Account">Bank Account</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Payment Mode</label>
            <input
              type="text"
              name="paymentMode"
              value={formDetails.transactionDetails.paymentMode}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference No</label>
            <input
              type="text"
              name="trReferenceNo"
              value={formDetails.transactionDetails.trReferenceNo}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference Date</label>
            <input
              type="date"
              name="trReferenceDate"
              value={formDetails.transactionDetails.trReferenceDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Transaction Remarks</label>
            <input
              type="text"
              name="transactionRemarks"
              value={formDetails.transactionDetails.transactionRemarks}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Total Balance</label>
            <input
              type="number"
              name="totalBalance"
              value={formDetails.transactionDetails.totalBalance}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default VehicleWorkSlipForm;
