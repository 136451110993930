import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import MaterialForm from "../../components/forms/MaterialForm"; // Form for Materials

const Materials = () => {
  const [materials, setMaterials] = useState([
    {
      id: 1,
      name: "Cement",
      section: "Building Materials",
      specification: "Ordinary Portland Cement",
      itemCode: "CEM001",
      category: "Construction",
      unit: "Bag",
      unitPrice: 300,
      gst: 18,
    },
    {
      id: 2,
      name: "Steel",
      section: "Building Materials",
      specification: "TMT Bars",
      itemCode: "STE002",
      category: "Construction",
      unit: "Ton",
      unitPrice: 50000,
      gst: 18,
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedMaterial, setSelectedMaterial] = useState(null); // For editing

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setMaterials([
        ...materials,
        { id: materials.length + 1, ...formData },
      ]);
    } else {
      setMaterials(
        materials.map((material) =>
          material.id === selectedMaterial.id ? { ...formData } : material
        )
      );
    }
    closeModal();
  };

  const handleDelete = (id) => {
    setMaterials(materials.filter((material) => material.id !== id));
  };

  const openModal = (mode, material = null) => {
    setModalMode(mode);
    setSelectedMaterial(material);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMaterial(null);
  };

  const columns = [
    { Header: "Material Id", accessor: "id", id: "materialId" }, // Unique id
    { Header: "Material Name", accessor: "name", id: "materialName" }, // Unique id
    { Header: "Section", accessor: "section", id: "section" },
    { Header: "Specification", accessor: "specification", id: "specification" },
    { Header: "Item Code", accessor: "itemCode", id: "itemCode" },
    { Header: "Category", accessor: "category", id: "category" },
    { Header: "Unit", accessor: "unit", id: "unit" },
    { Header: "Unit Price (Purchase)", accessor: "unitPrice", id: "unitPrice" },
    { Header: "GST %", accessor: "gst", id: "gst" },
    {
      Header: "Actions",
      id: "actions", // Unique id
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  

  return (
    <div className="p-6 bg-white rounded-xl">

      {/* Materials Section */}
      <div className="mb-10">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold ">Materials</h1>
        <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add")}
          >
            Add New Material
          </button>
        </div>
        <ReusableTable columns={columns} data={materials} />
      </div>

      {/* Modal for Add/Edit */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Material" : "Edit Material"}
        >
          <MaterialForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedMaterial || {
                name: "",
                section: "",
                specification: "",
                itemCode: "",
                category: "",
                unit: "",
                unitPrice: "",
                gst: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Materials;
