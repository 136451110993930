import React, { useState } from "react";

const ExpenseBillForm = ({ onSubmit, onCancel }) => {
  const [formDetails, setFormDetails] = useState({
    expenseType: "Work Expense",
    workName: "",
    expenseHead: "",
    billNo: "",
    billDate: "",
    gstPercent: 0,
    billAmount: 0,
    consignee: "",
    billDescription: "",
    payableAmount: 0,
    gstNo: "",
    billRemarks: "",
    uploadBillCopy: null,
    transactionDetails: {
      paidAmount: 0,
      paidDate: "",
      paidFromAcc: "Cash Account",
      paymentMode: "",
      trReferenceNo: "",
      trReferenceDate: "",
      transactionRemarks: "",
      billBalanceAmt: 0,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormDetails((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleTransactionChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      transactionDetails: {
        ...prev.transactionDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Submitted Form Details:", formDetails);
    if (onSubmit) onSubmit(formDetails);
  };

  return (
    <div className="bg-white  space-y-6">
      {/* Bill Details Section */}
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Expense Type</label>
            <select
              name="expenseType"
              value={formDetails.expenseType}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Work Expense">Work Expense</option>
              <option value="Office Expense">Office Expense</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Work Name</label>
            <input
              type="text"
              name="workName"
              value={formDetails.workName}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Expense Head</label>
            <input
              type="text"
              name="expenseHead"
              value={formDetails.expenseHead}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Select Expense Head"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Bill No</label>
            <input
              type="text"
              name="billNo"
              value={formDetails.billNo}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Bill Date</label>
            <input
              type="date"
              name="billDate"
              value={formDetails.billDate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">GST %</label>
            <input
              type="number"
              name="gstPercent"
              value={formDetails.gstPercent}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Bill Amount</label>
            <input
              type="number"
              name="billAmount"
              value={formDetails.billAmount}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Payable Amount</label>
            <input
              type="number"
              name="payableAmount"
              value={formDetails.payableAmount}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Consignee</label>
            <input
              type="text"
              name="consignee"
              value={formDetails.consignee}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">GST No</label>
            <input
              type="text"
              name="gstNo"
              value={formDetails.gstNo}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Upload Bill Copy</label>
            <input
              type="file"
              name="uploadBillCopy"
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium">Bill Description</label>
          <textarea
            name="billDescription"
            value={formDetails.billDescription}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium">Bill Remarks</label>
          <textarea
            name="billRemarks"
            value={formDetails.billRemarks}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          ></textarea>
        </div>
      </div>

      {/* Transaction Details Section */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold">Transaction Details</h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Paid Amount</label>
            <input
              type="number"
              name="paidAmount"
              value={formDetails.transactionDetails.paidAmount}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid Date</label>
            <input
              type="date"
              name="paidDate"
              value={formDetails.transactionDetails.paidDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid From Acc</label>
            <select
              name="paidFromAcc"
              value={formDetails.transactionDetails.paidFromAcc}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Cash Account">Cash Account</option>
              <option value="Bank Account">Bank Account</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Payment Mode</label>
            <input
              type="text"
              name="paymentMode"
              value={formDetails.transactionDetails.paymentMode}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference No</label>
            <input
              type="text"
              name="trReferenceNo"
              value={formDetails.transactionDetails.trReferenceNo}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference Date</label>
            <input
              type="date"
              name="trReferenceDate"
              value={formDetails.transactionDetails.trReferenceDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium">Transaction Remarks</label>
          <textarea
            name="transactionRemarks"
            value={formDetails.transactionDetails.transactionRemarks}
            onChange={handleTransactionChange}
            className="w-full border border-gray-300 rounded p-2"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium">Bill Balance Amt</label>
          <input
            type="number"
            name="billBalanceAmt"
            value={formDetails.transactionDetails.billBalanceAmt}
            onChange={handleTransactionChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ExpenseBillForm;
