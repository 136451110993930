import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import ServiceForm from "../../components/forms/ServiceForm"; // Form for Service
import ServiceTypeForm from "../../components/forms/ServiceTypeForm.js"; // Form for Service Types

const Services = () => {
  const [services, setServices] = useState([
    { id: 1, name: "Service A", isActive: true },
    { id: 2, name: "Service B", isActive: false },
  ]);

  const [serviceTypes, setServiceTypes] = useState([
    { id: 1, name: "Service Type 1" },
    { id: 2, name: "Service Type 2" },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedItem, setSelectedItem] = useState(null);
  const [isServiceTypeModal, setIsServiceTypeModal] = useState(false);

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      if (isServiceTypeModal) {
        setServiceTypes([
          ...serviceTypes,
          { id: serviceTypes.length + 1, name: formData.name },
        ]);
      } else {
        setServices([
          ...services,
          { id: services.length + 1, name: formData.name, isActive: formData.isActive },
        ]);
      }
    } else {
      if (isServiceTypeModal) {
        setServiceTypes(
          serviceTypes.map((type) =>
            type.id === selectedItem.id ? { ...type, ...formData } : type
          )
        );
      } else {
        setServices(
          services.map((service) =>
            service.id === selectedItem.id ? { ...service, ...formData } : service
          )
        );
      }
    }
    closeModal();
  };

  const handleDelete = (id, isType) => {
    if (isType) {
      setServiceTypes(serviceTypes.filter((type) => type.id !== id));
    } else {
      setServices(services.filter((service) => service.id !== id));
    }
  };

  const openModal = (mode, item = null, isTypeModal = false) => {
    setModalMode(mode);
    setSelectedItem(item);
    setIsModalOpen(true);
    setIsServiceTypeModal(isTypeModal);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const serviceColumns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Service Name", accessor: "name" },
    {
      Header: "Active Status",
      accessor: "isActive",
      Cell: ({ value }) => (
        <span
          className={`px-3 py-1 rounded font-semibold text-xs ${
            value ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
          }`}
        >
          {value ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-200"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
            onClick={() => handleDelete(value, false)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const serviceTypeColumns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Service Type Name", accessor: "name" },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-200"
            onClick={() => openModal("Edit", row.original, true)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
            onClick={() => handleDelete(value, true)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-8 ">

      {/* Services Section */}
      <div className="mb-12 bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-700">List of Services</h2>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-600 transition"
            onClick={() => openModal("Add")}
          >
            Add Service
          </button>
        </div>
        <ReusableTable columns={serviceColumns} data={services} />
      </div>

      {/* Service Types Section */}
      <div className="mb-12 bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-700">List of Service Types</h2>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-600 transition"
            onClick={() => openModal("Add", null, true)}
          >
            Add Service Type
          </button>
        </div>
        <ReusableTable columns={serviceTypeColumns} data={serviceTypes} />
      </div>

      {/* Modal for Add/Edit */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Item" : "Edit Item"}
          width="w-1/2"
        >
          {isServiceTypeModal ? (
            <ServiceTypeForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedItem || { name: "" }}
            />
          ) : (
            <ServiceForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedItem || { name: "", isActive: true }}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default Services;
