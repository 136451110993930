import React, { useState } from "react";
import Modal from "../../components/common/Modal";
import ReusableTable from "../../components/common/ReusableTable";
import DocumentForm from "../../components/forms/DocumentForm"; // Form Component for adding/editing documents

const ProjectDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); // For editing

  // Handle Add/Edit Document
  const handleFormSubmit = (formData) => {
    if (selectedDocument) {
      // Edit document
      setDocuments(
        documents.map((doc) =>
          doc.documentId === selectedDocument.documentId
            ? { ...formData, documentId: doc.documentId }
            : doc
        )
      );
    } else {
      // Add new document
      setDocuments([
        ...documents,
        {
          ...formData,
          documentId: documents.length + 1,
          creation: new Date().toISOString(),
        },
      ]);
    }
    closeModal();
  };

  // Delete Document
  const handleDelete = (id) => {
    setDocuments(documents.filter((doc) => doc.documentId !== id));
  };

  // Open Modal for Add/Edit
  const openModal = (doc = null) => {
    setSelectedDocument(doc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedDocument(null);
    setIsModalOpen(false);
  };

  // Table Columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1, id: "slNo" },
    { Header: "Document ID", accessor: "documentId", id: "documentId" },
    { Header: "Work", accessor: "work", id: "work" },
    { Header: "Title", accessor: "title", id: "title" },
    { Header: "Description", accessor: "description", id: "description" },
    { Header: "Google Drive Link", accessor: "file", id: "file" },
    { Header: "Visible to", accessor: "visibleTo", id: "visibleTo" },
    { Header: "Creation", accessor: "creation", id: "creation" },
    {
      Header: "Actions",
      accessor: "documentId",
      id: "actions",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => openModal(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Project Documents</h1>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
          onClick={() => openModal()}
        >
          Add New Document
        </button>
      </div>

      {/* Table Section */}
      <ReusableTable columns={columns} data={documents} showFooter={false} />

      {/* Modal Section */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedDocument ? "Edit Document" : "Add New Document"}
        >
          <DocumentForm
            onSubmit={handleFormSubmit}
            onCancel={closeModal}
            initialValues={
              selectedDocument || {
                work: "",
                title: "",
                description: "",
                file: "",
                visibleTo: "Yes",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectDocuments;
