import React from "react";

const Summary = ({ stats, tableData }) => {
  return (
    <div>
      {/* Responsive Summary Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
        {stats.map((stat, index) => (
          <div key={index} className="bg-white p-4 rounded shadow">
            <h3 className="text-sm font-medium text-gray-700">{stat.label}</h3>
            <p className={`text-xl font-bold ${stat.color}`}>{stat.value}</p>
          </div>
        ))}
      </div>

      {/* Project Summary Table */}
      {tableData && tableData.length > 0 && (
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-bold mb-4">Project Overall Summary</h3>
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-left">Heads</th>
                <th className="border border-gray-300 px-4 py-2 text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-4 py-2">{row.head}</td>
                  <td className="border border-gray-300 px-4 py-2 text-right">{row.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Summary;
