import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import WorkCategoryForm from "../../components/forms/WorkCategoryForm"; // Work Category Form Component

const WorkCategories = () => {
  const [workCategories, setWorkCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Work Category Name", accessor: "workCategoryName" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add a new work category
  const handleAddCategory = (category) => {
    setWorkCategories([
      ...workCategories,
      { ...category, id: workCategories.length + 1 },
    ]);
    closeModal();
  };

  // Edit an existing work category
  const handleEditCategory = (updatedCategory) => {
    setWorkCategories(
      workCategories.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      setWorkCategories(workCategories.filter((category) => category.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedCategory(null);
    setIsModalOpen(true);
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Work Categories</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Category
        </button>
      </div>
      <ReusableTable columns={columns} data={workCategories} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedCategory ? "Edit Category" : "Add New Category"}
        >
          <WorkCategoryForm
            onSubmit={selectedCategory ? handleEditCategory : handleAddCategory}
            onCancel={closeModal}
            initialValues={
              selectedCategory || {
                workCategoryName: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkCategories;
