import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import QuotationForm from "../../components/forms/QuotationForm";

const Quotations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // Tracks whether we're adding or editing
  const [selectedQuotation, setSelectedQuotation] = useState(null); // Stores data for editing
  const [data, setData] = useState([
    {
      slNo: 1,
      quotationNo: "Q-001",
      subject: "New Building Construction",
      customerName: "Customer A",
      address: "123 Main St",
      contactPerson: "John Doe",
      date: "2024-12-01",
      preparedBy: "Admin",
      netTotal: "₹100,000",
      status: "Open",
      creation: "2024-11-30",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Quotation No", accessor: "quotationNo" },
      { Header: "Subject", accessor: "subject" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Address", accessor: "address" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Date", accessor: "date" },
      { Header: "Prepared By", accessor: "preparedBy" },
      { Header: "Net Total", accessor: "netTotal" },
      { Header: "Status", accessor: "status" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.quotationNo)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedQuotation(null); // Clear selection for new quotation
    setIsModalOpen(true);
  };

  const handleEdit = (quotation) => {
    setModalMode("Edit");
    setSelectedQuotation(quotation); // Populate form with selected quotation data
    setIsModalOpen(true);
  };

  const handleDelete = (quotationNo) => {
    setData(data.filter((item) => item.quotationNo !== quotationNo));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      setData([
        ...data,
        {
          ...formData,
          slNo: data.length + 1,
          quotationNo: `Q-${data.length + 1}`, // Auto-generate quotation number
          creation: new Date().toISOString().split("T")[0], // Current date
        },
      ]);
    } else if (modalMode === "Edit") {
      setData(
        data.map((item) =>
          item.quotationNo === selectedQuotation.quotationNo
            ? { ...item, ...formData }
            : item
        )
      );
    }
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedQuotation(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Quotations</h2>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          onClick={handleAdd}
        >
          Create Quotation
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalMode === "Add" ? "Create Quotation" : "Edit Quotation"}
        width="w-1/2"
      >
        <QuotationForm
          onSubmit={handleFormSubmit}
          onCancel={closeModal}
          initialValues={selectedQuotation} // Pass initial data when editing
        />
      </Modal>
    </div>
  );
};

export default Quotations;
