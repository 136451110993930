import React, { useState } from "react";

const EMDForm = ({ onSubmit, onCancel, projectName = "", initialValues = {} }) => {
  const [formData, setFormData] = useState({
    project: initialValues.project || projectName || "",
    client: initialValues.client || "",
    date: initialValues.date || "",
    emdAmount: initialValues.emdAmount || "",
    tds: initialValues.tds || "",
    gstTds: initialValues.gstTds || "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) newErrors.date = "Date is required.";
    if (!formData.emdAmount) newErrors.emdAmount = "EMD Amount is required.";
    if (!formData.project) newErrors.project = "Project is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Project Name</label>
          {projectName ? (
            <input
              type="text"
              name="project"
              className="w-full border border-gray-300 rounded p-2 bg-gray-100"
              value={formData.project}
              readOnly
              disabled
            />
          ) : (
            <select
              name="project"
              className={`w-full border rounded p-2 ${
                errors.project ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.project}
              onChange={handleChange}
            >
              <option value="">Select a project</option>
              <option value="Road Work Demo">Road Work Demo</option>
              <option value="Bridge Work">Bridge Work</option>
            </select>
          )}
          {errors.project && <p className="text-red-500 text-sm mt-1">{errors.project}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium">Client Name</label>
          <input
            type="text"
            name="client"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.client}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Date</label>
          <input
            type="date"
            name="date"
            className={`w-full border rounded p-2 ${
              errors.date ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.date}
            onChange={handleChange}
          />
          {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium">EMD Amount</label>
          <input
            type="number"
            name="emdAmount"
            className={`w-full border rounded p-2 ${
              errors.emdAmount ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.emdAmount}
            onChange={handleChange}
          />
          {errors.emdAmount && (
            <p className="text-red-500 text-sm mt-1">{errors.emdAmount}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium">TDS</label>
          <input
            type="number"
            name="tds"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.tds}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium">GST TDS</label>
          <input
            type="number"
            name="gstTds"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.gstTds}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EMDForm;
