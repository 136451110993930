import React, { useState } from "react";

const AssignEnquiryForm = ({ onSubmit, onCancel }) => {
    const [assignTo, setAssignTo] = useState("");
    const [remarks, setRemarks] = useState("");
  
    const handleSubmit = () => {
      if (!assignTo) {
        alert("Please assign the enquiry!");
        return;
      }
      onSubmit({ assignTo, remarks });
    };
  
    return (
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Assign To</label>
          <input
            type="text"
            className="w-full mt-1 border border-gray-300 rounded p-2"
            value={assignTo}
            onChange={(e) => setAssignTo(e.target.value)}
            placeholder="Enter assignee's name"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Remarks</label>
          <textarea
            className="w-full mt-1 border border-gray-300 rounded p-2"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter remarks"
          ></textarea>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded shadow hover:bg-gray-400"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  };
  

export default AssignEnquiryForm;
