import React from "react";

const Dashboard = () => {
  const cards = [
    { title: "Projects", description: "View All Projects", icon: "📁" },
    { title: "Client Payments", description: "Manage Client Payments", icon: "💼" },
    { title: "User Payments (Petty Cash)", description: "Manage User Payments", icon: "💵" },
    { title: "Purchase Orders", description: "Manage Purchase Orders", icon: "✏️" },
    { title: "Purchase Bills", description: "Manage Purchase Bills", icon: "🛒" },
    { title: "Purchase Bill Payments", description: "Purchase Payments", icon: "💰" },
    { title: "Labour Wages", description: "View Labours", icon: "👷" },
    { title: "Vehicle Management", description: "View Work Slips", icon: "🚚" },
    { title: "Other Expenses", description: "Other Expenses", icon: "📝" },
    { title: "Subcontracts", description: "Subcontracts & Payments", icon: "💳" },
    { title: "Sales Enquiries", description: "Manage Sales Enquiries", icon: "🛍️" },
    { title: "Customers", description: "View Customers", icon: "👥" },
    { title: "Services", description: "View Services", icon: "🛠️" },
    { title: "Clients", description: "View Clients", icon: "👤" },
    { title: "Suppliers", description: "View Suppliers", icon: "📦" },
  ];

  return (
    <div className="bg-gray-100 ">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition duration-300"
          >
            <div className="flex flex-col items-center justify-center p-6">
              <div className="text-4xl mb-4 text-purple-500">{card.icon}</div>
              <h3 className="text-lg font-semibold text-gray-700">{card.title}</h3>
              <p className="text-gray-500 text-sm mt-2 text-center">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
