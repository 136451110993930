import React, { useMemo, useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SalarySlipForm from "../../components/forms/SalarySlipForm"; // Import the form component

const SalarySlips = () => {
  const [data, setData] = useState([
    {
      slNo: 1,
      slipId: "SL-001",
      employeeName: "John Doe",
      designation: "Software Engineer",
      month: "December 2024",
      slipUnder: "HR",
      project: "Project A",
      workedNos: 22,
      basicWage: 500,
      totalBasicWage: 11000,
      bonus: 916.39, // 8.33% of Total Basic Wage
      tiffinAllowance: 110, // 5 * 22
      overtimeHours: 10,
      overtimeAmount: 1000, // Example
      hra: 550, // 5% of Total Basic Wage
      ceb: 459, // 4.17% of Total Basic Wage
      el: 562.1, // 5.11% of Total Basic Wage
      total: 13797.49, // Example
      pf: 1320, // 12% of Total Basic Wage
      esi: 82.5, // 0.75% of Total Basic Wage
      allowance: 500,
      incentive: 300,
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlip, setSelectedSlip] = useState(null); // To track the slip being edited or added

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Slip ID", accessor: "slipId" },
      { Header: "Employee Name", accessor: "employeeName" },
      { Header: "Designation", accessor: "designation" },
      { Header: "Month", accessor: "month" },
      { Header: "Slip Under", accessor: "slipUnder" },
      { Header: "Project", accessor: "project" },
      { Header: "Worked Nos", accessor: "workedNos" },
      { Header: "Basic Wage", accessor: "basicWage" },
      { Header: "Total Basic Wage", accessor: "totalBasicWage" },
      { Header: "Bonus (8.33%)", accessor: "bonus" },
      { Header: "Tiffin Allowance (5/Day)", accessor: "tiffinAllowance" },
      { Header: "Overtime Hours", accessor: "overtimeHours" },
      { Header: "Overtime Amount", accessor: "overtimeAmount" },
      { Header: "HRA (5%)", accessor: "hra" },
      { Header: "CEB (4.17%)", accessor: "ceb" },
      { Header: "EL (5.11%)", accessor: "el" },
      { Header: "Total", accessor: "total" },
      { Header: "PF (12%)", accessor: "pf" },
      { Header: "ESI (0.75%)", accessor: "esi" },
      { Header: "Allowance", accessor: "allowance" },
      { Header: "Incentive", accessor: "incentive" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.slipId)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAddNew = () => {
    setSelectedSlip(null); // Reset the selected slip for a fresh form
    setIsModalOpen(true);
  };

  const handleEdit = (slip) => {
    setSelectedSlip(slip); // Pass the slip details to the form for editing
    setIsModalOpen(true);
  };

  const handleDelete = (slipId) => {
    setData(data.filter((item) => item.slipId !== slipId));
  };

  const handleFormSubmit = (formData) => {
    if (selectedSlip) {
      // Update existing slip
      setData(
        data.map((item) => (item.slipId === selectedSlip.slipId ? { ...formData } : item))
      );
    } else {
      // Add new slip
      const newSlip = { ...formData, slNo: data.length + 1, slipId: `SL-${data.length + 1}` };
      setData([...data, newSlip]);
    }
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Salary Slips</h2>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          onClick={handleAddNew}
        >
          Add New Slip
        </button>
      </div>
      <ReusableTable columns={columns} data={data} />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedSlip ? "Edit Salary Slip" : "Add New Salary Slip"}
        width="w-3/4"
      >
        <SalarySlipForm
          initialData={selectedSlip}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default SalarySlips;
