import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal"; // Reusable Modal
import EnquiryForm from "../../components/forms/EnquiryForm";
import AssignEnquiryForm from "../../components/forms/AssignEnquiryForm";

const Enquiries = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // Track Add, Update, or Assign modes
  const [selectedEnquiry, setSelectedEnquiry] = useState(null); // Store enquiry for editing or assigning
  const [data, setData] = useState([
    {
      slNo: 1,
      id: "ENQ-001",
      enqRefNo: "REF-123",
      enquiryStatus: "Open",
      assignedTo: "John Doe",
      customer: "Customer A",
      actionsTaken: "Follow-up initiated",
      remarks: "Client requested urgency",
      creation: "2024-11-30",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "ID", accessor: "id" },
      { Header: "Enq Ref No", accessor: "enqRefNo" },
      { Header: "Enquiry Status", accessor: "enquiryStatus" },
      { Header: "Assigned To", accessor: "assignedTo" },
      { Header: "Customer", accessor: "customer" },
      { Header: "Remarks", accessor: "remarks" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-green-500 text-white px-3 py-1 rounded"
              onClick={() => handleAssign(row.original)}
            >
              Assign Enquiry
            </button>
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Update
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedEnquiry(null);
    setIsModalOpen(true);
  };

  const handleEdit = (enquiry) => {
    setModalMode("Update");
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const handleAssign = (enquiry) => {
    setModalMode("Assign");
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (newData) => {
    // Validation logic based on mode
    if (modalMode === "Add" || modalMode === "Update") {
      if (!newData.customer || !newData.enquiryDate) {
        alert("Please fill in the required fields!");
        return;
      }
    }

    if (modalMode === "Assign") {
      if (!newData.assignTo) {
        alert("Please assign the enquiry!");
        return;
      }
    }

    // Add logic
    if (modalMode === "Add") {
      setData([
        ...data,
        { ...newData, slNo: data.length + 1, id: `ENQ-${data.length + 1}` },
      ]);
    }
    // Update logic
    else if (modalMode === "Update") {
      setData(
        data.map((item) =>
          item.id === selectedEnquiry.id ? { ...item, ...newData } : item
        )
      );
    }
    // Assign logic
    else if (modalMode === "Assign") {
      setData(
        data.map((item) =>
          item.id === selectedEnquiry.id
            ? { ...item, assignedTo: newData.assignTo, remarks: newData.remarks }
            : item
        )
      );
    }

    // Close modal after successful submission
    setIsModalOpen(false);
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case "Add":
        return "Create Enquiry";
      case "Update":
        return "Update Enquiry";
      case "Assign":
        return "Assign Enquiry";
      default:
        return "";
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEnquiry(null); // Reset selected enquiry
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Enquiries</h2>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          onClick={handleAdd}
        >
          Create Enquiry
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={getModalTitle()}
        width="w-1/2"
      >
        {modalMode === "Assign" ? (
          <AssignEnquiryForm
            onSubmit={handleFormSubmit} // Only handle submission
            onCancel={closeModal} // Close modal on cancel
          />

        ) : (
          <EnquiryForm
            initialValues={selectedEnquiry}
            mode={modalMode}
            onSubmit={handleFormSubmit}
            onCancel={closeModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default Enquiries;
