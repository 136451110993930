import React, { useState } from "react";

const ClientForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(
    initialValues || {
      clientName: "",
      contactPerson: "",
      phone: "",
      whatsappNo: "",
      email: "",
      companyName: "",
      groupName: "",
      poBox: "",
      address: "",
      civilIdNo: "",
      gstTrnNo: "",
      gstDate: "",
      stateCode: "",
    }
  );

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors on change
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.clientName) newErrors.clientName = "Client Name is required.";
    if (!formData.phone) newErrors.phone = "Phone is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        {/* Client Name */}
        <div>
          <label className="block text-sm font-medium">Client Name</label>
          <input
            type="text"
            name="clientName"
            className={`w-full border rounded p-2 ${
              errors.clientName ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.clientName}
            onChange={handleInputChange}
          />
          {errors.clientName && (
            <p className="text-red-500 text-sm mt-1">{errors.clientName}</p>
          )}
        </div>

        {/* Contact Person */}
        <div>
          <label className="block text-sm font-medium">Contact Person</label>
          <input
            type="text"
            name="contactPerson"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.contactPerson}
            onChange={handleInputChange}
          />
        </div>

        {/* Phone */}
        <div>
          <label className="block text-sm font-medium">Phone</label>
          <input
            type="text"
            name="phone"
            className={`w-full border rounded p-2 ${
              errors.phone ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.phone}
            onChange={handleInputChange}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
          )}
        </div>

        {/* WhatsApp No */}
        <div>
          <label className="block text-sm font-medium">WhatsApp No</label>
          <input
            type="text"
            name="whatsappNo"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.whatsappNo}
            onChange={handleInputChange}
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            className={`w-full border rounded p-2 ${
              errors.email ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
          )}
        </div>

        {/* Company Name */}
        <div>
          <label className="block text-sm font-medium">Company Name</label>
          <input
            type="text"
            name="companyName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.companyName}
            onChange={handleInputChange}
          />
        </div>

        {/* Group Name */}
        <div>
          <label className="block text-sm font-medium">Group Name</label>
          <input
            type="text"
            name="groupName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.groupName}
            onChange={handleInputChange}
          />
        </div>

        {/* PO Box */}
        <div>
          <label className="block text-sm font-medium">PO Box</label>
          <input
            type="text"
            name="poBox"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.poBox}
            onChange={handleInputChange}
          />
        </div>

        {/* Address */}
        <div className="col-span-2">
          <label className="block text-sm font-medium">Address</label>
          <textarea
            name="address"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>

        {/* Civil ID No */}
        <div>
          <label className="block text-sm font-medium">Civil ID No</label>
          <input
            type="text"
            name="civilIdNo"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.civilIdNo}
            onChange={handleInputChange}
          />
        </div>

        {/* GST/TRN No */}
        <div>
          <label className="block text-sm font-medium">GST/TRN No</label>
          <input
            type="text"
            name="gstTrnNo"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.gstTrnNo}
            onChange={handleInputChange}
          />
        </div>

        {/* GST Date */}
        <div>
          <label className="block text-sm font-medium">GST Date</label>
          <input
            type="date"
            name="gstDate"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.gstDate}
            onChange={handleInputChange}
          />
        </div>

        {/* State Code */}
        <div>
          <label className="block text-sm font-medium">State Code</label>
          <select
            name="stateCode"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.stateCode}
            onChange={handleInputChange}
          >
            <option value="">Select State</option>
            <option value="CA">California</option>
            <option value="NY">New York</option>
            <option value="TX">Texas</option>
          </select>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ClientForm;
