import React from "react";
import { useTable, useSortBy } from "react-table";

const ReusableTable = ({
  columns = [],
  data = [],
  filters = [],
  onFilterChange = () => {},
  loading = false,
  showFooter = false, // Enable footer rendering
  footerRows = [], // Additional custom footer rows
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({ columns, data }, useSortBy);

  return (
    <div className="p-6">
      {/* Filters */}
      {filters.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
          {filters.map((filter) => (
            <div key={filter.id} className="w-full">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor={filter.id}
              >
                {filter.label}
              </label>
              <select
                id={filter.id}
                aria-label={`Filter by ${filter.label}`}
                className="mt-1 block p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={filter.value || ""}
                onChange={(e) => onFilterChange(filter.id, e.target.value)}
              >
                {filter.options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}

      {/* Table */}
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="table-fixed min-w-full bg-white border-collapse border border-gray-300"
        >
          {/* Header */}
          <thead className="sticky top-0 bg-gray-100 z-10">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b-2 border-gray-200 px-4 py-2 text-left text-sm font-semibold text-gray-600"
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Body */}
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-b border-gray-200">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-2 text-sm text-gray-700"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>

          {/* Footer */}
          {showFooter && (
            <tfoot>
              {/* Default React Table Footer */}
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()} className="bg-gray-50">
                  {footerGroup.headers.map((column) => (
                    <td
                      {...column.getFooterProps()}
                      className="border-t px-4 py-2 font-semibold text-gray-700"
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}

              {/* Custom Footer Rows */}
              {footerRows.map((footerRow, index) => (
                <tr key={index} className="bg-gray-50">
                  {footerRow.cells.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      colSpan={cell.colSpan || 1}
                      className={`border-t px-4 py-2 text-sm font-semibold text-gray-700 text-${cell.align || "left"}`}
                    >
                      {cell.value}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

export default ReusableTable;
