import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import AccountForm from "../../components/forms/AccountForm";

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Define table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Account Head Name", accessor: "accountHeadName" },
    { Header: "Account Type", accessor: "accountType" },
    { Header: "Opening Balance", accessor: "openingBalance" },
    { Header: "Opening Balance Date", accessor: "openingBalanceDate" },
    { Header: "Created On", accessor: "createdOn" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded shadow hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Handlers
  const handleAddAccount = (account) => {
    setAccounts([
      ...accounts,
      { ...account, id: accounts.length + 1, createdOn: new Date().toISOString().split("T")[0] },
    ]);
    setIsModalOpen(false);
  };

  const handleEditAccount = (updatedAccount) => {
    setAccounts(
      accounts.map((account) =>
        account.id === updatedAccount.id ? updatedAccount : account
      )
    );
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    setAccounts(accounts.filter((account) => account.id !== id));
  };

  const handleAddClick = () => {
    setSelectedAccount(null);
    setIsModalOpen(true);
  };

  const handleEdit = (account) => {
    setSelectedAccount(account);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAccount(null);
  };

  return (
    <div className="p-6 bg-white  rounded-xl shadow">
      <div className="">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold mb-6 text-gray-700">Accounts Management</h1>
          <button
            onClick={handleAddClick}
            className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          >
            Add New Account
          </button>
        </div>
        <ReusableTable columns={columns} data={accounts} />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedAccount ? "Edit Account" : "Add New Account"}
        >
          <AccountForm
            onSubmit={selectedAccount ? handleEditAccount : handleAddAccount}
            onCancel={closeModal}
            initialValues={
              selectedAccount || {
                accountHeadName: "",
                accountType: "",
                openingBalance: 0,
                openingBalanceDate: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Accounts;
