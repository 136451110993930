import React, { useState } from "react";

const SupervisorForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium">User</label>
          <select
            name="userName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.userName}
            onChange={handleChange}
          >
            <option value="">Select User</option>
            <option value="John Doe">John Doe</option>
            <option value="Jane Smith">Jane Smith</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">Role</label>
          <input
            type="text"
            name="role"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.role}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">
            Project Commission Percentage
          </label>
          <input
            type="number"
            name="commissionPercentage"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.commissionPercentage}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Remarks</label>
          <textarea
            name="remarks"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.remarks}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default SupervisorForm;
