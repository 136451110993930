import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { hasPermission } from "../utils/checkPermissions";

const ProtectedRoute = ({ action, children }) => {
  const { userRole } = useAuth();

  if (!hasPermission(userRole, action)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
