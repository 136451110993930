import React from "react";
import ReusableTable from "../../components/common/ReusableTable";

const BalanceSheet = () => {
  const data = [
    {
      description: "Client Payment Received",
      qty: "",
      debit: 0,
      credit: 400000,
      remarks: "",
    },
    {
      description: "Food",
      qty: "",
      debit: 500,
      credit: 0,
      remarks: "Expense Bill",
    },
    {
      description: "Materials",
      qty: 100,
      debit: 1800,
      credit: 0,
      remarks: "Material Purchase",
    },
    {
      description: "Road Work [Albin]",
      qty: "",
      debit: 2000,
      credit: 0,
      remarks: "Subcontract Payment",
    },
    {
      description: "Construction",
      qty: "",
      debit: 900,
      credit: 0,
      remarks: "Labour Wage",
    },
    {
      description: "Purchase Discount/Round (-)",
      qty: "",
      debit: 0,
      credit: 0,
      remarks: "Material Purchase",
    },
  ];

  // Utility to calculate totals
  const calculateTotal = (rows, column) =>
    rows.reduce((sum, row) => sum + (parseFloat(row[column]) || 0), 0);

  const totalDebit = calculateTotal(data, "debit");
  const totalCredit = calculateTotal(data, "credit");
  const profitLoss = totalCredit - totalDebit;

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Description", accessor: "description" },
    { Header: "QTY", accessor: "qty" },
    { Header: "Debit Amount", accessor: "debit" },
    { Header: "Credit Amount", accessor: "credit" },
    { Header: "Remarks", accessor: "remarks" },
  ];

  const footerRows = [
    {
      cells: [
        { colSpan: 3, value: <strong>Total</strong> },
        { value: `₹ ${totalDebit.toFixed(2)}`, align: "right" },
        { value: `₹ ${totalCredit.toFixed(2)}`, align: "right" },
        { value: "", align: "left" },
      ],
    },
    {
      cells: [
        { colSpan: 3, value: <strong>Profit/Loss</strong> },
        { value: "", align: "right" },
        { value: `₹ ${profitLoss.toFixed(2)}`, align: "right" },
        { value: "", align: "left" },
      ],
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold mb-6">Balance Sheet</h1>
      <ReusableTable
        columns={columns}
        data={data}
        showFooter={true}
        footerRows={footerRows}
      />
    </div>
  );
};

export default BalanceSheet;
