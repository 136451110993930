import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import LabourForm from "../../components/forms/LabourForm"; // Form for Individual Labour
import GroupLabourForm from "../../components/forms/GroupLabourForm"; // Form for Group Labours

const LabourManagement = () => {
  // State for Individual Labours
  const [individualLabours, setIndividualLabours] = useState([
    {
      labourId: 1,
      name: "John Doe",
      contact: "1234567890",
      workType: "Carpentry",
      fixedWage: 15000,
      wagePerDay: 500,
      overtimeRate: 50,
      taPerDay: 100,
      foodAllowance: 50,
      opBalance: 500,
      aadharNo: "1234-5678-9012",
      address: "Street 123, City",
      document: "Aadhar.pdf",
      creation: "2024-12-08",
    },
  ]);

  // State for Group Labours
  const [groupLabours, setGroupLabours] = useState([
    {
      groupLabourId: 1,
      groupName: "Labour Group A",
      contact: "9876543210",
      workType: "Construction",
      fixedWage: 50000,
      wagePerDay: 1500,
      overtimeRate: 100,
      taPerDay: 200,
      foodAllowance: 150,
      opBalance: 2000,
      aadharNo: "4567-1234-8910",
      address: "Industrial Area, City",
      document: "GroupDetails.pdf",
      creation: "2024-12-08",
    },
  ]);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedLabour, setSelectedLabour] = useState(null);
  const [isGroupLabour, setIsGroupLabour] = useState(false); // Differentiates between individual/group modal

  // Common add/edit functionality
  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      if (isGroupLabour) {
        setGroupLabours([
          ...groupLabours,
          { groupLabourId: groupLabours.length + 1, ...formData },
        ]);
      } else {
        setIndividualLabours([
          ...individualLabours,
          { labourId: individualLabours.length + 1, ...formData },
        ]);
      }
    } else {
      if (isGroupLabour) {
        setGroupLabours(
          groupLabours.map((labour) =>
            labour.groupLabourId === selectedLabour.groupLabourId
              ? { ...formData }
              : labour
          )
        );
      } else {
        setIndividualLabours(
          individualLabours.map((labour) =>
            labour.labourId === selectedLabour.labourId ? { ...formData } : labour
          )
        );
      }
    }
    closeModal();
  };

  const handleDelete = (id, isGroup) => {
    if (isGroup) {
      setGroupLabours(groupLabours.filter((labour) => labour.groupLabourId !== id));
    } else {
      setIndividualLabours(
        individualLabours.filter((labour) => labour.labourId !== id)
      );
    }
  };

  const openModal = (mode, labour = null, isGroup = false) => {
    setModalMode(mode);
    setSelectedLabour(labour);
    setIsGroupLabour(isGroup);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLabour(null);
  };

  // Table columns for Individual Labours
  const individualColumns = [
    { Header: "Labour ID", accessor: "individualLabourId" }, // Unique accessor
    { Header: "Labour Name", accessor: "name" },
    { Header: "Contact", accessor: "contact" },
    { Header: "Work Type", accessor: "workType" },
    { Header: "Fixed Wage", accessor: "fixedWage" },
    { Header: "Wage Per Day", accessor: "wagePerDay" },
    { Header: "Overtime Rate/Hr", accessor: "overtimeRate" },
    { Header: "TA/Day", accessor: "taPerDay" },
    { Header: "Food Allowance", accessor: "foodAllowance" },
    { Header: "Op. Balance", accessor: "opBalance" },
    { Header: "Aadhar No", accessor: "aadharNo" },
    { Header: "Address", accessor: "address" },
    {
      Header: "Actions",
      accessor: "individualActions", // Unique accessor for actions column
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original, false)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(row.original.individualLabourId, false)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  
  const groupColumns = [
    { Header: "Group ID", accessor: "groupLabourId" }, // Unique accessor
    { Header: "Group Name", accessor: "groupName" },
    { Header: "Contact", accessor: "contact" },
    { Header: "Work Type", accessor: "workType" },
    { Header: "Fixed Wage", accessor: "fixedWage" },
    { Header: "Wage Per Day", accessor: "wagePerDay" },
    { Header: "Overtime Rate/Hr", accessor: "overtimeRate" },
    { Header: "TA/Day", accessor: "taPerDay" },
    { Header: "Food Allowance", accessor: "foodAllowance" },
    { Header: "Op. Balance", accessor: "opBalance" },
    { Header: "Aadhar No", accessor: "aadharNo" },
    { Header: "Address", accessor: "address" },
    {
      Header: "Actions",
      accessor: "groupActions", // Unique accessor for actions column
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original, true)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(row.original.groupLabourId, true)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  
  return (
    <div className="p-6 bg-white rounded-xl">

      {/* Individual Labours Section */}
      <div className="mb-10">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Individual Labours</h2>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add", null, false)}
          >
            Add Individual Labour
          </button>
        </div>
        <ReusableTable columns={individualColumns} data={individualLabours} />
      </div>

      {/* Group Labours Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Group Labours</h2>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add", null, true)}
          >
            Add Group Labour
          </button>
        </div>
        <ReusableTable columns={groupColumns} data={groupLabours} />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Labour" : "Edit Labour"}
        >
          {isGroupLabour ? (
            <GroupLabourForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedLabour || {}}
            />
          ) : (
            <LabourForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedLabour || {}}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default LabourManagement;
