import React, { useState } from "react";

const ServiceTypeForm = ({ onSubmit, onCancel, initialValues }) => {
    const [formData, setFormData] = useState(initialValues);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(formData);
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Service Type Name</label>
            <input
              type="text"
              name="name"
              className="w-full border border-gray-300 rounded p-2"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <hr className="my-4 border-gray-300" />

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  };

  export default ServiceTypeForm;
