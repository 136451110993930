import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";

const StockManagement = () => {
  const [activeTab, setActiveTab] = useState("stockRegister");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName] = useState("Project A"); // Example project name
  const [consumedDate, setConsumedDate] = useState("");
  const [consumedMaterials, setConsumedMaterials] = useState([]);

  const tabs = [
    { key: "stockRegister", label: "Stock Register" },
    { key: "stockTransferIn", label: "Stock Transfer In" },
    { key: "stockTransferOut", label: "Stock Transfer Out" },
    { key: "stockConsumed", label: "Stock Consumed" },
  ];

  const availableMaterials = [
    {
      srNo: 1,
      materialName: "Cement",
      unit: "kg",
      unitPrice: 50,
      supplier: "Supplier A",
      currentlyAvailableStock: 500,
      consumedQty: 0,
    },
    {
      srNo: 2,
      materialName: "Sand",
      unit: "kg",
      unitPrice: 20,
      supplier: "Supplier B",
      currentlyAvailableStock: 1000,
      consumedQty: 0,
    },
  ];

  const columns = useMemo(
    () => ({
      stockRegister: [
        { Header: "Sr No", accessor: "srNo" },
        { Header: "Material Name", accessor: "materialName" },
        { Header: "Unit", accessor: "unit" },
        { Header: "Unit Price", accessor: "unitPrice" },
        { Header: "Supplier", accessor: "supplier" },
        {
            Header: "Available Stock",
            columns: [
                { Header: "Qty", accessor: "currentlyAvailableQty" },
                { Header: "Amount", accessor: "currentlyAvailableAmount" },
            ],
        },
        {
            Header: "Opening Stock",
            columns: [
                { Header: "Qty", accessor: "totalOpeningQty" },
                { Header: "Amount", accessor: "totalOpeningAmount" },
            ],
        },
        {
            Header: "Purchased Stock",
            columns: [
                { Header: "Qty", accessor: "totalPurchasedQty" },
                { Header: "Amount", accessor: "totalPurchasedAmount" },
            ],
        },
        {
            Header: "Transferred In Stock",
            columns: [
                { Header: "Qty", accessor: "totalTransferredInQty" },
                { Header: "Amount", accessor: "totalTransferredInAmount" },
            ],
        },
        {
            Header: "Transferred Out Stock",
            columns: [
                { Header: "Qty", accessor: "totalTransferredOutQty" },
                { Header: "Amount", accessor: "totalTransferredOutAmount" },
            ],
        },
        {
            Header: "Consumed Stock",
            columns: [
                { Header: "Qty", accessor: "totalConsumedQty" },
                { Header: "Amount", accessor: "totalConsumedAmount" },
            ],
        },
        {
            Header: "Purchase Returned Stock",
            columns: [
                { Header: "Qty", accessor: "totalPurchaseReturnedQty" },
                { Header: "Amount", accessor: "totalPurchaseReturnedAmount" },
            ],
        },
      ],
      stockTransferIn: [
        { Header: "Sr No", accessor: "srNo" },
        { Header: "Material Name", accessor: "materialName" },
        { Header: "Unit", accessor: "unit" },
        { Header: "Transferred Stock Qty", accessor: "transferredStockQty" },
        { Header: "Transferred Stock Amt", accessor: "transferredStockAmt" },
        { Header: "Transferred From", accessor: "transferredFrom" },
        { Header: "Transferred By", accessor: "transferredBy" },
        { Header: "Transferred On", accessor: "transferredOn" },
      ],
      stockTransferOut: [
        { Header: "Sr No", accessor: "srNo" },
        { Header: "Material Name", accessor: "materialName" },
        { Header: "Unit", accessor: "unit" },
        { Header: "Transferred Stock Qty", accessor: "transferredStockQty" },
        { Header: "Transferred Stock Amt", accessor: "transferredStockAmt" },
        { Header: "Transferred To", accessor: "transferredTo" },
        { Header: "Transferred By", accessor: "transferredBy" },
        { Header: "Transferred On", accessor: "transferredOn" },
      ],
      stockConsumed: [
        { Header: "Sr No", accessor: "srNo" },
        { Header: "Material Name", accessor: "materialName" },
        { Header: "Unit", accessor: "unit" },
        { Header: "Unit Price", accessor: "unitPrice" },
        { Header: "Supplier", accessor: "supplier" },
        {
            Header: "Currently Available Stock",
            columns: [
                { Header: "Qty", accessor: "currentlyAvailableStockQty" },
                { Header: "Amount", accessor: "currentlyAvailableStockAmount" },
            ],
        },
        {
          Header: "Consumed Quantity",
          accessor: "consumedQty",
          Cell: ({ row }) => (
            <input
              type="number"
              className="w-full border border-gray-300 rounded p-1"
              value={row.original.consumedQty}
              min={0}
              max={row.original.currentlyAvailableStock}
              onChange={(e) =>
                handleConsumedQtyChange(row.original.srNo, e.target.value)
              }
            />
          ),
        },
      ],
    }),
    []
  );

  const data = useMemo(
    () => ({
      stockRegister: [],
      stockTransferIn: [],
      stockTransferOut: [],
      stockConsumed: [],
    }),
    []
  );

  const handleConsumedQtyChange = (srNo, qty) => {
    setConsumedMaterials((prev) =>
      prev.map((item) =>
        item.srNo === srNo
          ? { ...item, consumedQty: Math.min(Number(qty), item.currentlyAvailableStock) }
          : item
      )
    );
  };

  const handleAddStockConsumption = () => {
    setConsumedMaterials(availableMaterials);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    if (!consumedDate) {
      alert("Please select a consumed date!");
      return;
    }
    console.log({
      projectName,
      consumedDate,
      consumedMaterials: consumedMaterials.filter(
        (item) => item.consumedQty > 0
      ),
    });
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-4 rounded shadow">
      {/* Tabs */}
      <div className="flex space-x-4 mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`px-3 py-1 rounded ${activeTab === tab.key ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Add Stock Consumed Button */}
      {activeTab === "stockConsumed" && (
        <div className="flex justify-end mb-4">
          <button
            onClick={handleAddStockConsumption}
            className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          >
            Add Stock Consumption
          </button>
        </div>
      )}

      {/* Table */}
      <ReusableTable columns={columns[activeTab]} data={data[activeTab]} />

      {/* Modal for Adding Stock Consumption */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Add Stock Consumption"
          width="w-3/4"
        >
          <div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium">Project Name</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded p-2"
                  value={projectName}
                  disabled
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Consumed Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded p-2"
                  value={consumedDate}
                  onChange={(e) => setConsumedDate(e.target.value)}
                />
              </div>
            </div>

            {/* Materials Table */}
            <ReusableTable columns={columns.stockConsumed} data={consumedMaterials} />

            <div className="flex justify-end mt-4 space-x-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StockManagement;
