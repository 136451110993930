import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Assuming you have a reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import RequirementForm from "../../components/forms/RequirementForm"; // Form for adding/editing requirements

const CustomRequirements = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // Track Add or Edit mode
  const [selectedRequirement, setSelectedRequirement] = useState(null); // Store selected requirement for editing
  const [data, setData] = useState([
    {
      srNo: 1,
      requirementTitle: "Requirement 1",
      requirementDate: "2024-12-01",
      type: "Material",
      taxableAmount: 5000,
      gstPercentage: 18,
      estimatedAmount: 5900,
      description: "Requirement for cement",
      status: "Pending",
      creation: "2024-12-01",
      id: "REQ-001",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sr No", accessor: "srNo" },
      { Header: "Requirement Title", accessor: "requirementTitle" },
      { Header: "Requirement Date", accessor: "requirementDate" },
      { Header: "Type", accessor: "type" },
      { Header: "Taxable Amount", accessor: "taxableAmount" },
      { Header: "GST%", accessor: "gstPercentage" },
      { Header: "Estimated Amount", accessor: "estimatedAmount" },
      { Header: "Description", accessor: "description" },
      { Header: "Status", accessor: "status" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedRequirement(null);
    setIsModalOpen(true);
  };

  const handleEdit = (requirement) => {
    setModalMode("Edit");
    setSelectedRequirement(requirement);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      const newData = {
        ...formData,
        srNo: data.length + 1,
        creation: new Date().toISOString().split("T")[0],
        id: `REQ-${data.length + 1}`,
      };
      setData([...data, newData]);
    } else if (modalMode === "Edit") {
      setData(
        data.map((item) =>
          item.id === selectedRequirement.id ? { ...item, ...formData } : item
        )
      );
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Custom Requirements</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Requirement
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add Requirement" : "Edit Requirement"}
        width="w-1/2"
      >
        <RequirementForm
          initialValues={selectedRequirement}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default CustomRequirements;
