
import React from "react";

const QuickWageSlipForm = () => {
  return (
    <div>
      <h1>QuickWageSlipForm</h1>
      <p>This is the QuickWageSlipForm page.</p>
    </div>
  );
};

export default QuickWageSlipForm;
