import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import EMDForm from "../../components/forms/EMDForm";

const EMD = ({ projectName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedEMD, setSelectedEMD] = useState(null);
  const [data, setData] = useState([
    {
      srNo: 1,
      date: "2024-12-01",
      project: "Road Work Demo",
      client: "Vinod",
      emdAmount: 5000,
      tds: 500,
      gstTds: 800,
      creation: "2024-12-01",
      id: "EMD-001",
    },
    {
      srNo: 2,
      date: "2024-12-02",
      project: "Bridge Work",
      client: "Rajesh",
      emdAmount: 8000,
      tds: 1000,
      gstTds: 1200,
      creation: "2024-12-02",
      id: "EMD-002",
    },
  ]);

  const filteredData = projectName
    ? data.filter((item) => item.project === projectName)
    : data;

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "srNo" },
      { Header: "Date", accessor: "date" },
      { Header: "Project", accessor: "project" },
      { Header: "Client", accessor: "client" },
      { Header: "EMD Amount", accessor: "emdAmount" },
      { Header: "TDS", accessor: "tds" },
      { Header: "GST TDS", accessor: "gstTds" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedEMD(null);
    setIsModalOpen(true);
  };

  const handleEdit = (emd) => {
    setModalMode("Edit");
    setSelectedEMD(emd);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      const newData = {
        ...formData,
        srNo: data.length + 1,
        id: `EMD-${data.length + 1}`,
        creation: new Date().toISOString().split("T")[0],
        project: projectName || formData.project, // Use provided projectName if available
      };
      setData([...data, newData]);
    } else if (modalMode === "Edit") {
      setData(
        data.map((item) =>
          item.id === selectedEMD.id ? { ...item, ...formData } : item
        )
      );
    }
    setIsModalOpen(false);
    setSelectedEMD(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {projectName ? `EMD Records for ${projectName}` : "All EMD Records"}
        </h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New EMD
        </button>
      </div>

      <ReusableTable columns={columns} data={filteredData} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New EMD" : "Edit EMD"}
        width="w-3/4"
      >
        <EMDForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          projectName={projectName || ""}
          initialValues={selectedEMD || {}}
        />
      </Modal>
    </div>
  );
};

export default EMD;
