import React, { useState } from "react";

const AccountForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Account Type</label>
          <select
            name="accountType"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.accountType}
            onChange={handleChange}
          >
            <option value="">Select Account Type</option>
            <option value="Asset">Asset</option>
            <option value="Liability">Liability</option>
            <option value="Expense">Expense</option>
            <option value="Income">Income</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">Account Head Name</label>
          <input
            type="text"
            name="accountHeadName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.accountHeadName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Opening Balance</label>
          <input
            type="number"
            name="openingBalance"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.openingBalance}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Op Balance Date</label>
          <input
            type="date"
            name="openingBalanceDate"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.openingBalanceDate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default AccountForm;
