import React, { useState } from "react";

const SalarySlipForm = () => {
  const [formDetails, setFormDetails] = useState({
    salarySlipUnder: "Project Expense",
    workName: "",
    employee: "",
    month: "Dec 2024",
    basicWage: 0,
    type: "Daily",
    extraAllowance: 0,
    incentive: 0,
    bonus: 0,
    totalSalary: 0,
    extraDeductions: 0,
    netSalaryAmount: 0,
    advancePaidAmount: 0,
    netPayableAmount: 0,
    remarks: "",
    transactionDetails: {
      paidAmount: 0,
      paidDate: "",
      paidFromAcc: "Cash Account",
      paymentMode: "",
      trReferenceNo: "",
      trReferenceDate: "",
      transactionRemarks: "",
      balanceAmount: 0,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTransactionChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      transactionDetails: {
        ...prev.transactionDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Form Details Submitted:", formDetails);
  };

  return (
    <div className="bg-white  rounded-lg  space-y-6">
      {/* Salary Slip Section */}
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Salary Slip Under</label>
            <select
              name="salarySlipUnder"
              value={formDetails.salarySlipUnder}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Project Expense">Project Expense</option>
              <option value="Office Expense">Office Expense</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Work Name</label>
            <input
              type="text"
              name="workName"
              value={formDetails.workName}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium">Employee</label>
            <select
              name="employee"
              value={formDetails.employee}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="">Select Employee</option>
              <option value="John Doe">John Doe</option>
              <option value="Jane Smith">Jane Smith</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Month</label>
            <input
              type="text"
              name="month"
              value={formDetails.month}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Basic Wage</label>
            <input
              type="number"
              name="basicWage"
              value={formDetails.basicWage}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Type</label>
            <select
              name="type"
              value={formDetails.type}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Daily">Daily</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium">Extra Allowance</label>
            <input
              type="number"
              name="extraAllowance"
              value={formDetails.extraAllowance}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Incentive</label>
            <input
              type="number"
              name="incentive"
              value={formDetails.incentive}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Bonus</label>
            <input
              type="number"
              name="bonus"
              value={formDetails.bonus}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Total Salary</label>
            <input
              type="number"
              name="totalSalary"
              value={formDetails.totalSalary}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Extra Deductions</label>
            <input
              type="number"
              name="extraDeductions"
              value={formDetails.extraDeductions}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Advance Paid Amount</label>
            <input
              type="number"
              name="advancePaidAmount"
              value={formDetails.advancePaidAmount}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">NET Payable Amount</label>
            <input
              type="number"
              name="netPayableAmount"
              value={formDetails.netPayableAmount}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
      </div>

      {/* Transaction Details Section */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold">Transaction Details</h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Paid Amount</label>
            <input
              type="number"
              name="paidAmount"
              value={formDetails.transactionDetails.paidAmount}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid Date</label>
            <input
              type="date"
              name="paidDate"
              value={formDetails.transactionDetails.paidDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Paid From Acc</label>
            <select
              name="paidFromAcc"
              value={formDetails.transactionDetails.paidFromAcc}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="Cash Account">Cash Account</option>
              <option value="Bank Account">Bank Account</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Payment Mode</label>
            <select
              name="paymentMode"
              value={formDetails.transactionDetails.paymentMode}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            >
              <option value="">Select Payment Mode</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="Online">Online</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference No</label>
            <input
              type="text"
              name="trReferenceNo"
              value={formDetails.transactionDetails.trReferenceNo}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">TR Reference Date</label>
            <input
              type="date"
              name="trReferenceDate"
              value={formDetails.transactionDetails.trReferenceDate}
              onChange={handleTransactionChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium">Transaction Remarks</label>
          <textarea
            name="transactionRemarks"
            value={formDetails.transactionDetails.transactionRemarks}
            onChange={handleTransactionChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SalarySlipForm;
