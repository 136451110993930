import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import HolidayForm from "../../components/forms/HolidayForm"; // Holiday Form Component

const Holidays = () => {
  const [holidays, setHolidays] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  // Table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Holiday Name", accessor: "holidayName" },
    { Header: "Date", accessor: "date" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Holiday"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Holiday"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add new holiday
  const handleAddHoliday = (holiday) => {
    setHolidays([
      ...holidays,
      { ...holiday, id: holidays.length + 1 },
    ]);
    closeModal();
  };

  // Edit existing holiday
  const handleEditHoliday = (updatedHoliday) => {
    setHolidays(
      holidays.map((holiday) =>
        holiday.id === updatedHoliday.id ? updatedHoliday : holiday
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this holiday?")) {
      setHolidays(holidays.filter((holiday) => holiday.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedHoliday(null);
    setIsModalOpen(true);
  };

  const handleEdit = (holiday) => {
    setSelectedHoliday(holiday);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHoliday(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Holidays</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Holiday
        </button>
      </div>
      <ReusableTable columns={columns} data={holidays} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedHoliday ? "Edit Holiday" : "Add New Holiday"}
        >
          <HolidayForm
            onSubmit={selectedHoliday ? handleEditHoliday : handleAddHoliday}
            onCancel={closeModal}
            initialValues={
              selectedHoliday || {
                holidayName: "",
                date: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Holidays;
