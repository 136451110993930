import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import ReusableTable from "../../components/common/ReusableTable";

const Projects = () => {
  const initialData = [
    {
      slNo: 1,
      projectId: "508",
      projectName: "#508 - Bharat",
      supervisors: "Dileep",
      clientName: "Nava",
      location: "Nava",
      area: "Residential",
      section: "All In All",
      category: "Residential",
      status: "In Progress",
    },
    {
      slNo: 2,
      projectId: "507",
      projectName: "#507 - Yousuf Suhami",
      supervisors: "Umesh",
      clientName: "Raka",
      location: "Raka",
      area: "Raka Kober",
      section: "Residential",
      category: "All In All",
      status: "In Progress",
    },
    {
      slNo: 3,
      projectId: "506",
      projectName: "#506 - Ramgiri Asana",
      supervisors: "Mr. Ismail",
      clientName: "Krishnan",
      location: "Ramgiri",
      area: "Akkulam",
      section: "Odisha",
      category: "Civil Works",
      status: "In Progress",
    },
  ];

  const [filters, setFilters] = useState({
    supervisors: "All",
    clientName: "All",
    category: "All",
    status: "All",
  });

  const handleFilterChange = (id, value) => {
    setFilters((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const filteredData = useMemo(() => {
    return initialData.filter((row) => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === "All") return true;
        return row[key]
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    });
  }, [filters, initialData]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      {
        Header: "Project ID & Name",
        accessor: "projectName",
        Cell: ({ row }) => (
          <Link
            to={`/admin/projects/${row.original.projectId}`} // Dynamic route with project ID
            className="text-blue-500 hover:underline"
          >
            {row.original.projectName}
          </Link>
        ),
      },
      { Header: "Assigned Supervisors", accessor: "supervisors" },
      { Header: "Client Name", accessor: "clientName" },
      { Header: "Location", accessor: "location" },
      { Header: "Area", accessor: "area" },
      { Header: "Section", accessor: "section" },
      { Header: "Project Category", accessor: "category" },
      {
        Header: "Project Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`px-2 py-1 rounded text-white ${
              value === "In Progress" ? "bg-blue-500" : "bg-gray-500"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  const filterOptions = [
    {
      id: "supervisors",
      label: "Assigned Supervisors",
      value: filters.supervisors,
      options: ["All", ...new Set(initialData.map((row) => row.supervisors))],
    },
    {
      id: "clientName",
      label: "Client Name",
      value: filters.clientName,
      options: ["All", ...new Set(initialData.map((row) => row.clientName))],
    },
    {
      id: "category",
      label: "Project Category",
      value: filters.category,
      options: ["All", ...new Set(initialData.map((row) => row.category))],
    },
    {
      id: "status",
      label: "Project Status",
      value: filters.status,
      options: ["All", ...new Set(initialData.map((row) => row.status))],
    },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Projects</h2>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Create Project
        </button>
      </div>
    <ReusableTable
      columns={columns}
      data={filteredData}
      filters={filterOptions}
      onFilterChange={handleFilterChange}
    />
    </div>
  );
};

export default Projects;
