import React, { useState } from "react";

const WorkTypeForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Work Type Name</label>
          <input
            type="text"
            name="workTypeName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.workTypeName}
            onChange={handleChange}
            placeholder="Enter Work Type Name"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Item Code</label>
          <input
            type="text"
            name="itemCode"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.itemCode}
            onChange={handleChange}
            placeholder="Enter Item Code"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default WorkTypeForm;
