import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SubContractWorkForm from "../../components/forms/SubContractWorkForm";

const SubcontractWorks = ({ projectName = null }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState(null);
  const [data, setData] = useState([
    {
      slNo: 1,
      id: "SCW-001",
      subWorkType: "Civil",
      contractType: "Labor",
      subWorkName: "Foundation Work",
      contractor: "ABC Contractors",
      estBudget: "₹1,000,000",
      billsReceived: 5,
      totalBillAmount: "₹500,000",
      totalPaidOnBill: "₹450,000",
      opBalancePaid: "₹20,000",
      advancePaid: "₹30,000",
      totalPaid: "₹500,000",
      balancePayable: "₹500,000",
      tds: "₹5,000",
      retention: "₹15,000",
      balanceToBudget: "₹485,000",
      createdBy: "Admin",
      createdDate: "2024-12-01",
    },
  ]);

  // Table Columns
  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "ID", accessor: "id" },
      { Header: "Sub Work Type", accessor: "subWorkType" },
      { Header: "Contract Type", accessor: "contractType" },
      {
        Header: "Sub Work Name",
        accessor: "subWorkName",
        Cell: ({ value, row }) => (
          <button
            className="text-blue-500 hover:underline"
            onClick={() => navigate(`/admin/subcontract-works/${row.original.id}`)}
          >
            {value}
          </button>
        ),
      },
      { Header: "Contractor", accessor: "contractor" },
      { Header: "Est. Budget", accessor: "estBudget" },
      { Header: "No. of Bills Received", accessor: "billsReceived" },
      { Header: "Total Bill Amount", accessor: "totalBillAmount" },
      { Header: "Total Paid (On Bill)", accessor: "totalPaidOnBill" },
      { Header: "Op. Balance Paid", accessor: "opBalancePaid" },
      { Header: "Advance Paid", accessor: "advancePaid" },
      { Header: "Total Paid", accessor: "totalPaid" },
      { Header: "Balance Payable", accessor: "balancePayable" },
      { Header: "TDS", accessor: "tds" },
      { Header: "Retention", accessor: "retention" },
      { Header: "Balance to Budget", accessor: "balanceToBudget" },
      { Header: "Created By", accessor: "createdBy" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  // Handlers
  const handleAdd = () => {
    setSelectedWork(null); // Reset selected work
    setIsModalOpen(true);
  };

  const handleEdit = (work) => {
    setSelectedWork(work);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (workDetails) => {
    if (selectedWork) {
      // Edit existing work
      setData(
        data.map((item) =>
          item.id === selectedWork.id ? { ...item, ...workDetails } : item
        )
      );
    } else {
      // Add new work
      setData([
        ...data,
        {
          ...workDetails,
          slNo: data.length + 1,
          id: `SCW-${data.length + 1}`,
        },
      ]);
    }
    setIsModalOpen(false);
  };

  // Filter data if projectName is provided
  const filteredData = projectName
    ? data.filter((item) => item.subWorkName.includes(projectName))
    : data;

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {projectName ? `Subcontract Works for ${projectName}` : "All Subcontract Works"}
        </h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add Subcontract Work
        </button>
      </div>

      <ReusableTable columns={columns} data={filteredData} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedWork ? "Edit Subcontract Work" : "Add Subcontract Work"}
        width="w-3/4"
      >
        <SubContractWorkForm
          initialValues={selectedWork}
          projectName={projectName} // Pass project name for pre-filling
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default SubcontractWorks;
