import React from "react";
import { useParams } from "react-router-dom";

const SubContractDetails = () => {
  const { id } = useParams();

  // Mock fetching details using the ID
  const subcontractDetails = {
    id,
    subContractWorkName: "Road Construction",
    contractor: "John Doe",
    totalAmount: 200000,
    description: "This subcontract work involves constructing a 2 km road.",
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold mb-6">{subcontractDetails.subContractWorkName}</h1>
      <p><strong>Contractor:</strong> {subcontractDetails.contractor}</p>
      <p><strong>Total Amount:</strong> ₹ {subcontractDetails.totalAmount.toFixed(2)}</p>
      <p><strong>Description:</strong> {subcontractDetails.description}</p>
    </div>
  );
};

export default SubContractDetails;
