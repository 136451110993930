import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import SupervisorForm from "../../components/forms/SupervisorForm"; // Form for Assigning Supervisors

const AssignSupervisor = () => {
  const [supervisors, setSupervisors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedSupervisor, setSelectedSupervisor] = useState(null); // For editing

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setSupervisors([
        ...supervisors,
        {
          id: supervisors.length + 1,
          commissionAmount: ((formData.commissionPercentage || 0) * 100).toFixed(2),
          totalPaid: 0,
          totalBalance: ((formData.commissionPercentage || 0) * 100).toFixed(2), // Assuming initial balance is equal to commission
          ...formData,
        },
      ]);
    } else {
      setSupervisors(
        supervisors.map((supervisor) =>
          supervisor.id === selectedSupervisor.id
            ? { ...formData, id: selectedSupervisor.id }
            : supervisor
        )
      );
    }
    closeModal();
  };

  const handleDelete = (id) => {
    setSupervisors(supervisors.filter((supervisor) => supervisor.id !== id));
  };

  const openModal = (mode, supervisor = null) => {
    setModalMode(mode);
    setSelectedSupervisor(supervisor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSupervisor(null);
  };

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Supervisor Name", accessor: "userName" },
    { Header: "Role", accessor: "role" },
    { Header: "Commission %", accessor: "commissionPercentage" },
    { Header: "Commission Amount", accessor: "commissionAmount" },
    { Header: "Total Paid", accessor: "totalPaid" },
    { Header: "Total Balance", accessor: "totalBalance" },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Assign Supervisor</h1>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={() => openModal("Add")}
        >
          Add Supervisor
        </button>
      </div>
      <ReusableTable columns={columns} data={supervisors} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add Supervisor" : "Edit Supervisor"}
        >
          <SupervisorForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedSupervisor || {
                userName: "",
                role: "",
                commissionPercentage: "",
                remarks: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default AssignSupervisor;
