import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import NoteForm from "../../components/forms/NoteForm"; // Form for Notes

const DailyNotes = () => {
  const [notes, setNotes] = useState([
    {
      id: 1,
      user: "John Doe",
      work: "Road Construction",
      noteTitle: "Morning Update",
      noteDate: "2024-12-08",
      description: "Supervised the construction site.",
      image: "image1.jpg",
      location: "Construction Site A",
      createdDate: "2024-12-08",
      status: "Active",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedNote, setSelectedNote] = useState(null); // For editing

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setNotes([
        ...notes,
        { id: notes.length + 1, createdDate: new Date().toISOString().split("T")[0], ...formData },
      ]);
    } else {
      setNotes(
        notes.map((note) => (note.id === selectedNote.id ? { ...formData } : note))
      );
    }
    closeModal();
  };

  const handleDelete = (id) => {
    setNotes(notes.filter((note) => note.id !== id));
  };

  const openModal = (mode, note = null) => {
    setModalMode(mode);
    setSelectedNote(note);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNote(null);
  };

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "User", accessor: "user" },
    { Header: "Work", accessor: "work" },
    { Header: "Note Title", accessor: "noteTitle" },
    { Header: "Note Date", accessor: "noteDate" },
    { Header: "Description", accessor: "description" },
    { Header: "Image", accessor: "image" },
    { Header: "Location", accessor: "location" },
    { Header: "Created Date", accessor: "createdDate" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`px-3 py-1 rounded ${
            value === "Active"
              ? "bg-green-200 text-green-800"
              : "bg-red-200 text-red-800"
          }`}
        >
          {value}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl">

      {/* Notes Section */}
      <div className="mb-10">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold mb-6">Daily Notes</h1>
        <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add")}
          >
            Add New Note
          </button>
        </div>
        <ReusableTable columns={columns} data={notes} />
      </div>

      {/* Modal for Add/Edit */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Note" : "Edit Note"}
        >
          <NoteForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedNote || {
                user: "",
                work: "",
                noteTitle: "",
                noteDate: "",
                description: "",
                image: "",
                location: "",
                status: "Active",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default DailyNotes;
