import React, { useState } from "react";

const SupplierForm = ({ onSubmit, onCancel, initialValues = {} }) => {
  const defaultValues = {
    category: "",
    supplierName: "",
    contactPerson: "",
    supplierAddress: "",
    phone: "",
    email: "",
    gstNo: "",
    gstDate: "",
    openingBalance: "",
    panNo: "",
    aadharNo: "",
    bankAccountNo: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    vendorNo: "",
  };

  const [formData, setFormData] = useState({ ...defaultValues, ...initialValues });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        {/* Category */}
        <div>
          <label className="block text-sm font-medium">Category</label>
          <input
            type="text"
            name="category"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.category}
            onChange={handleInputChange}
          />
        </div>

        {/* Supplier Name */}
        <div>
          <label className="block text-sm font-medium">Supplier Name</label>
          <input
            type="text"
            name="supplierName"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.supplierName}
            onChange={handleInputChange}
          />
        </div>

        {/* Contact Person */}
        <div>
          <label className="block text-sm font-medium">Contact Person</label>
          <input
            type="text"
            name="contactPerson"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.contactPerson}
            onChange={handleInputChange}
          />
        </div>

        {/* Supplier Address */}
        <div className="col-span-3">
          <label className="block text-sm font-medium">Supplier Address</label>
          <textarea
            name="supplierAddress"
            className="w-full border border-gray-300 rounded p-2"
            rows="2"
            value={formData.supplierAddress}
            onChange={handleInputChange}
          ></textarea>
        </div>

        {/* Phone */}
        <div>
          <label className="block text-sm font-medium">Phone</label>
          <input
            type="text"
            name="phone"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>

        {/* GST No */}
        <div>
          <label className="block text-sm font-medium">GST No</label>
          <input
            type="text"
            name="gstNo"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.gstNo}
            onChange={handleInputChange}
          />
        </div>

        {/* GST Date */}
        <div>
          <label className="block text-sm font-medium">GST Date</label>
          <input
            type="date"
            name="gstDate"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.gstDate}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SupplierForm;
