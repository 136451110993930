import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component

const CreditorsDebitors = () => {
  const [data, setData] = useState([
    { id: 1, consignee: "#597 Material Purchase Bill - NIKIL", type: "Material Supplier", debit: 0, credit: 800.0 },
    { id: 2, consignee: "#706 (Bhaskar)", type: "Expense Bill", debit: 0, credit: 500.0 },
  ]);

  // Calculate totals for debit and credit
  const totals = useMemo(() => {
    const totalDebit = data.reduce((sum, item) => sum + (item.debit || 0), 0);
    const totalCredit = data.reduce((sum, item) => sum + (item.credit || 0), 0);
    return { totalDebit, totalCredit };
  }, [data]);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Consignee", accessor: "consignee" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Debit",
      accessor: "debit",
      Cell: ({ value }) => (value ? value.toFixed(2) : "0.00"), // Format the cell value
      Footer: ({ rows }) => {
        // Calculate total Debit
        const total = rows.reduce((sum, row) => sum + (row.values.debit || 0), 0);
        return <strong>{total.toFixed(2)}</strong>;
      },
    },
    {
      Header: "Credit",
      accessor: "credit",
      Cell: ({ value }) => (value ? value.toFixed(2) : "0.00"), // Format the cell value
      Footer: ({ rows }) => {
        // Calculate total Credit
        const total = rows.reduce((sum, row) => sum + (row.values.credit || 0), 0);
        return <strong>{total.toFixed(2)}</strong>;
      },
    },
  ];
  


  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <h1 className="text-2xl font-bold text-gray-700 mb-6">Creditors/Debitors</h1>
      <ReusableTable columns={columns} data={data} showFooter={true} />
    </div>
  );
};

export default CreditorsDebitors;
