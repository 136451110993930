import React from "react";

const Sidebar = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <aside className="w-1/4 bg-white shadow-md h-full flex flex-col rounded-lg">
      {/* Sidebar Project Info */}
      <div className="p-6 border-b border-gray-200">
        <img
          src="https://via.placeholder.com/100"
          alt="Project Icon"
          className="w-24 h-24 mx-auto rounded-full mb-4"
        />
        <h1 className="text-lg font-bold text-center">#508 - Bharat</h1>
        <span className="block text-center bg-purple-100 text-purple-700 px-2 py-1 rounded text-sm mt-2">
          Residential
        </span>
        <p className="text-center text-gray-600 text-sm mt-1">All In All</p>
      </div>

      {/* Sidebar Tabs */}
      <ul className="space-y-2 p-4 flex-grow">
        {tabs.map((tab) => (
          <li key={tab.id}>
            <button
              className={`flex items-center w-full text-left p-2 rounded ${
                activeTab === tab.id
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <span className="mr-2">{tab.icon}</span>
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
