import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import TaxForm from "../../components/forms/TaxForm"; // Tax Form Component

const TaxTypes = () => {
  const [taxTypes, setTaxTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);

  // Table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Tax Name", accessor: "taxName" },
    { Header: "Total GST %", accessor: "totalGst" },
    { Header: "CGST %", accessor: "cgst" },
    { Header: "SGST %", accessor: "sgst" },
    { Header: "IGST %", accessor: "igst" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Tax"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Tax"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add new tax
  const handleAddTax = (tax) => {
    const totalGst = (parseFloat(tax.cgst) || 0) + (parseFloat(tax.sgst) || 0) + (parseFloat(tax.igst) || 0);
    setTaxTypes([
      ...taxTypes,
      { ...tax, id: taxTypes.length + 1, totalGst },
    ]);
    closeModal();
  };

  // Edit existing tax
  const handleEditTax = (updatedTax) => {
    const totalGst = (parseFloat(updatedTax.cgst) || 0) + (parseFloat(updatedTax.sgst) || 0) + (parseFloat(updatedTax.igst) || 0);
    setTaxTypes(
      taxTypes.map((tax) =>
        tax.id === updatedTax.id ? { ...updatedTax, totalGst } : tax
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this tax type?")) {
      setTaxTypes(taxTypes.filter((tax) => tax.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedTax(null);
    setIsModalOpen(true);
  };

  const handleEdit = (tax) => {
    setSelectedTax(tax);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTax(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
      <h1 className="text-2xl font-bold text-gray-700 mb-6">Tax Types</h1>

        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Tax
        </button>
      </div>
  
        <ReusableTable columns={columns} data={taxTypes} />
     
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedTax ? "Edit Tax" : "Add New Tax"}
        >
          <TaxForm
            onSubmit={selectedTax ? handleEditTax : handleAddTax}
            onCancel={closeModal}
            initialValues={
              selectedTax || {
                taxName: "",
                cgst: "",
                sgst: "",
                igst: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default TaxTypes;
