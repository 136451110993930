import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import SecurityDepositForm from "../../components/forms/SecurityDepositForm"; // Form for security deposits

const SecurityDeposits = ({ projectName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedDeposit, setSelectedDeposit] = useState(null); // For editing
  const [data, setData] = useState([
    {
      srNo: 1,
      date: "2024-12-01",
      depositAmount: 10000,
      retentionMoney: 2000,
      incomeTax: 1000,
      gstTds: 1800,
      creation: "2024-12-01",
      projectName: "Project A",
      id: "SD-001",
    },
    {
      srNo: 2,
      date: "2024-12-02",
      depositAmount: 15000,
      retentionMoney: 3000,
      incomeTax: 1200,
      gstTds: 2500,
      creation: "2024-12-02",
      projectName: "Project B",
      id: "SD-002",
    },
  ]);

  // Filter data if `projectName` is provided
  const filteredData = projectName
    ? data.filter((item) => item.projectName === projectName)
    : data;

  const columns = useMemo(
    () => [
      { Header: "Sr No", accessor: "srNo" },
      { Header: "Date", accessor: "date" },
      { Header: "Deposit Amount", accessor: "depositAmount" },
      { Header: "Retention Money", accessor: "retentionMoney" },
      { Header: "Income Tax", accessor: "incomeTax" },
      { Header: "GST/TDS", accessor: "gstTds" },
      { Header: "Project Name", accessor: "projectName" }, // New column for project name
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedDeposit(null);
    setIsModalOpen(true);
  };

  const handleEdit = (deposit) => {
    setModalMode("Edit");
    setSelectedDeposit(deposit); // Pass selected deposit data
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      // Add new deposit
      const newData = {
        ...formData,
        srNo: data.length + 1,
        id: `SD-${data.length + 1}`,
        creation: new Date().toISOString().split("T")[0],
        projectName: projectName || formData.projectName, // Use provided projectName if available
      };
      setData([...data, newData]);
    } else if (modalMode === "Edit") {
      // Update existing deposit
      setData(
        data.map((item) =>
          item.id === selectedDeposit.id ? { ...item, ...formData } : item
        )
      );
    }
    setIsModalOpen(false);
    setSelectedDeposit(null); // Reset selected deposit
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {projectName ? `Security Deposits for ${projectName}` : "Security Deposits"}
        </h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Security Deposit
        </button>
      </div>

      <ReusableTable columns={columns} data={filteredData} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Security Deposit" : "Edit Security Deposit"}
        width="w-1/2"
      >
        <SecurityDepositForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          projectName={projectName || ""} // Pass projectName if available
          clientName=""  // Pass clientName if available
        />



      </Modal>
    </div>
  );
};

export default SecurityDeposits;
