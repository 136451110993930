import React, { useState, useEffect } from "react";

const RequirementForm = ({ onSubmit, onCancel, initialValues = {} }) => {
    const [formData, setFormData] = useState({
        requirementDate: "",
        requirementTitle: "",
        type: "",
        taxableAmount: "",
        gstPercentage: "",
        estimatedAmount: "",
        description: "",
        status: "Pending",
        ...initialValues, // Populate initial values for editing
    });

    useEffect(() => {
        setFormData({ ...formData, ...initialValues });
    }, [initialValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.requirementTitle || !formData.requirementDate || !formData.taxableAmount) {
            alert("Please fill in the required fields!");
            return;
        }
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium">Type</label>
                    <select
                        name="type"
                        className="w-full border border-gray-300 rounded p-2"
                        value={formData.type}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Type</option>
                        <option value="Material">Material</option>
                        <option value="Service">Service</option>
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium">Requirement Date</label>
                    <input
                        type="date"
                        name="requirementDate"
                        className="w-full border border-gray-300 rounded p-2"
                        value={formData.requirementDate}
                        onChange={handleInputChange}
                        required
                    />
                </div>

            </div>
            <div>
                <label className="block text-sm font-medium">Requirement Title</label>
                <input
                    type="text"
                    name="requirementTitle"
                    className="w-full border border-gray-300 rounded p-2"
                    placeholder="Enter requirement title"
                    value={formData.requirementTitle}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <label className="block text-sm font-medium">Taxable Amount</label>
                    <input
                        type="number"
                        name="taxableAmount"
                        className="w-full border border-gray-300 rounded p-2"
                        placeholder="Enter taxable amount"
                        value={formData.taxableAmount}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">GST%</label>
                    <input
                        type="number"
                        name="gstPercentage"
                        className="w-full border border-gray-300 rounded p-2"
                        placeholder="Enter GST percentage"
                        value={formData.gstPercentage}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Estimated Amount</label>
                    <input
                        type="number"
                        name="estimatedAmount"
                        className="w-full border border-gray-300 rounded p-2"
                        placeholder="Enter estimated amount"
                        value={formData.estimatedAmount}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>



            <div>
                <label className="block text-sm font-medium">Requirement Description</label>
                <textarea
                    name="description"
                    className="w-full border border-gray-300 rounded p-2"
                    placeholder="Enter requirement description"
                    value={formData.description}
                    onChange={handleInputChange}
                />
            </div>

            <div>
                <label className="block text-sm font-medium">Requirement Status</label>
                <select
                    name="status"
                    className="w-full border border-gray-300 rounded p-2"
                    value={formData.status}
                    onChange={handleInputChange}
                    required
                >
                    <option value="Pending">Pending</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Rejected">Rejected</option>
                </select>
            </div>

            <div className="flex justify-end space-x-4">
                <button
                    type="button"
                    onClick={onCancel}
                    className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                >
                    Submit
                </button>
            </div>
        </form>
    );
};

export default RequirementForm;
