import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import PurchaseOrderForm from "../../components/forms/PurchaseOrderForm"; // Form for adding/editing purchase orders

const Purchase = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null); // Track the current purchase order being edited
  const [data, setData] = useState([
    {
      slNo: 1,
      orderId: "PO-001",
      orderDate: "2024-12-01",
      supplierName: "Supplier A",
      estAmount: "₹500,000",
      totalPaid: "₹300,000",
      billCreationStatus: "Partially Billed",
      totalBilledAmt: "₹300,000",
      balanceAmtToBill: "₹200,000",
      otherDetails: "Priority Order",
      billingAddress: "123 Street, City",
      approval: "Pending",
      creation: "2024-12-01",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Order ID", accessor: "orderId" },
      { Header: "Order Date", accessor: "orderDate" },
      { Header: "Supplier Name", accessor: "supplierName" },
      { Header: "Est Amount", accessor: "estAmount" },
      { Header: "Total Paid", accessor: "totalPaid" },
      { Header: "Bill Creation Status", accessor: "billCreationStatus" },
      { Header: "Total Billed Amt", accessor: "totalBilledAmt" },
      { Header: "Balance Amt to Bill", accessor: "balanceAmtToBill" },
      { Header: "Other Details", accessor: "otherDetails" },
      { Header: "Billing Address", accessor: "billingAddress" },
      { Header: "Approval", accessor: "approval" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.orderId)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setEditingOrder(null); // Clear editing data for new purchase order
    setIsModalOpen(true);
  };

  const handleEdit = (purchaseOrder) => {
    setEditingOrder(purchaseOrder); // Set the purchase order for editing
    setIsModalOpen(true); // Open the modal for editing
  };

  const handleDelete = (orderId) => {
    setData(data.filter((item) => item.orderId !== orderId));
  };

  const handleFormSubmit = (updatedOrder) => {
    if (editingOrder) {
      // Update existing purchase order
      setData(
        data.map((item) =>
          item.orderId === updatedOrder.orderId ? { ...item, ...updatedOrder } : item
        )
      );
    } else {
      // Add new purchase order
      const newData = {
        ...updatedOrder,
        slNo: data.length + 1,
        creation: new Date().toISOString().split("T")[0],
        orderId: `PO-${data.length + 1}`,
      };
      setData([...data, newData]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Purchase</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Purchase Order
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={editingOrder ? "Edit Purchase Order" : "Add New Purchase Order"}
        width="w-1/2"
      >
        <PurchaseOrderForm
          order={editingOrder} // Pass the editing order data to the form
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Purchase;
