
import React from "react";

const IndividualWageSlipForm = () => {
  return (
    <div>
      <h1>IndividualWageSlipForm</h1>
      <p>This is the IndividualWageSlipForm page.</p>
    </div>
  );
};

export default IndividualWageSlipForm;
