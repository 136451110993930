import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Assuming you have a reusable table component
import Modal from "../../components/common/Modal"; // For the modal form
import ClientForm from "../../components/forms/ClientForm"; // Form for adding/editing clients

const Clients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedClient, setSelectedClient] = useState(null);
  const [data, setData] = useState([
    {
      id: "CL-001",
      name: "John Doe",
      contactPerson: "Jane Doe",
      phone: "1234567890",
      whatsappNo: "1234567890",
      email: "john@example.com",
      poBox: "12345",
      address: "123 Street, City, Country",
      companyName: "Doe Enterprises",
      groupName: "Group A",
      gstTrnNo: "GST123456",
      gstDate: "2024-01-01",
      gstState: "California",
      civilId: "CIV123456",
      password: "password123",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Client ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Whatsapp No", accessor: "whatsappNo" },
      { Header: "Email", accessor: "email" },
      { Header: "PO Box", accessor: "poBox" },
      { Header: "Address", accessor: "address" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Group Name", accessor: "groupName" },
      { Header: "GST/TRN No", accessor: "gstTrnNo" },
      { Header: "GST Date", accessor: "gstDate" },
      { Header: "GST State", accessor: "gstState" },
      { Header: "Civil ID", accessor: "civilId" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedClient(null);
    setIsModalOpen(true);
  };

  const handleEdit = (client) => {
    setModalMode("Edit");
    setSelectedClient(client);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      const newClient = {
        ...formData,
        id: `CL-${data.length + 1}`,
      };
      setData([...data, newClient]);
    } else if (modalMode === "Edit") {
      setData(
        data.map((item) =>
          item.id === selectedClient.id ? { ...item, ...formData } : item
        )
      );
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Clients</h1>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Client
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Client" : "Edit Client"}
        width="w-3/4"
      >
        <ClientForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedClient}
        />
      </Modal>
    </div>
  );
};

export default Clients;
