import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import VehicleForm from "../../components/forms/VehicleForm"; // Vehicle Form Component

const VehicleManagement = () => {
  const [vehicles, setVehicles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Vehicle Name", accessor: "vehicleName" },
    { Header: "Vehicle Number", accessor: "vehicleNumber" },
    { Header: "Consignee", accessor: "consignee" },
    { Header: "Vehicle Type", accessor: "vehicleType" },
    { Header: "Basic Charge", accessor: "basicCharge" },
    { Header: "Duration Type", accessor: "durationType" },
    { Header: "Load Capacity (CFT)", accessor: "loadCapacityCFT" },
    { Header: "Load Capacity (TON)", accessor: "loadCapacityTON" },
    { Header: "OP Balance Payable", accessor: "opBalancePayable" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddVehicle = (vehicle) => {
    setVehicles([...vehicles, { ...vehicle, id: vehicles.length + 1 }]);
    setIsModalOpen(false);
  };

  const handleEditVehicle = (updatedVehicle) => {
    setVehicles(
      vehicles.map((vehicle) =>
        vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle
      )
    );
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    setVehicles(vehicles.filter((vehicle) => vehicle.id !== id));
  };

  const handleAddClick = () => {
    setSelectedVehicle(null);
    setIsModalOpen(true);
  };

  const handleEdit = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVehicle(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
      <h1 className="text-2xl font-bold ">Vehicle Management</h1>

        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Vehicle
        </button>
      </div>
      <ReusableTable columns={columns} data={vehicles} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedVehicle ? "Edit Vehicle" : "Add Vehicle"}
        >
          <VehicleForm
            onSubmit={selectedVehicle ? handleEditVehicle : handleAddVehicle}
            onCancel={closeModal}
            initialValues={
              selectedVehicle || {
                vehicleName: "",
                vehicleNumber: "",
                consignee: "",
                vehicleType: "",
                basicCharge: "",
                durationType: "",
                loadCapacityCFT: "",
                loadCapacityTON: "",
                opBalancePayable: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default VehicleManagement;
