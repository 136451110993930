import React, { useState } from "react";

const QuotationForm = ({ onSubmit, onCancel, initialValues = {} }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        customerId: "",
        customerName: "",
        location: "",
        phone: "",
        contactPerson: "",
        email: "",
        quotationDate: "",
        expiryDate: "",
        subject: "",
        quotationDescription: "",
        paymentTerms: [{ stage: "", payment: "", items: "", amount: "", remarks: "" }],
        termsAndConditions: [{ title: "", description: "" }],
        ...initialValues,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNestedChange = (e, index, key, section) => {
        const updatedArray = formData[section].map((item, i) =>
            i === index ? { ...item, [key]: e.target.value } : item
        );
        setFormData({ ...formData, [section]: updatedArray });
    };

    const handleAddRow = (section, defaultRow) => {
        setFormData({
            ...formData,
            [section]: [...formData[section], defaultRow],
        });
    };

    const handleRemoveRow = (index, section) => {
        setFormData({
            ...formData,
            [section]: formData[section].filter((_, i) => i !== index),
        });
    };

    const handleNextStep = () => {
        if (currentStep === 1) {
            if (!formData.customerName || !formData.quotationDate || !formData.subject) {
                alert("Please fill in the required fields!");
                return;
            }
            setCurrentStep(2);
        }
    };

    const handlePreviousStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const stepOneFields = [
        { name: "customerId", type: "text", placeholder: "Customer ID" },
        { name: "customerName", type: "text", placeholder: "Customer Name", required: true },
        { name: "location", type: "text", placeholder: "Location" },
        { name: "phone", type: "text", placeholder: "Phone" },
        { name: "contactPerson", type: "text", placeholder: "Contact Person" },
        { name: "email", type: "email", placeholder: "Email" },
        { name: "quotationDate", type: "date", placeholder: "Quotation Date", required: true },
        { name: "expiryDate", type: "date", placeholder: "Expiry Date" },
        { name: "subject", type: "text", placeholder: "Subject", required: true },
        {
            name: "quotationDescription",
            type: "textarea",
            placeholder: "Quotation Description",
        },
    ];

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {/* Step Navigation */}
            <div className="flex justify-between items-center border-b pb-3">
                <div className="flex items-center space-x-4">
                    <div
                        className={`px-4 py-2 rounded-full ${
                            currentStep === 1 ? "bg-blue-500 text-white" : "bg-gray-300"
                        }`}
                    >
                        1. Customer & Quotation Information
                    </div>
                    <div
                        className={`px-4 py-2 rounded-full ${
                            currentStep === 2 ? "bg-blue-500 text-white" : "bg-gray-300"
                        }`}
                    >
                        2. Items & Quote Value
                    </div>
                </div>
            </div>

            {/* Step 1: Customer & Quotation Information */}
            {currentStep === 1 && (
                <div>
                    {/* Dynamically Render Fields */}
                    <div className="grid grid-cols-2 gap-4">
                        {stepOneFields.map((field) => (
                            <div key={field.name}>
                                {field.type === "textarea" ? (
                                    <textarea
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formData[field.name]}
                                        onChange={handleInputChange}
                                        className="border border-gray-300 rounded p-2 w-full"
                                    />
                                ) : (
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formData[field.name]}
                                        onChange={handleInputChange}
                                        className="border border-gray-300 rounded p-2 w-full"
                                        required={field.required}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Payment Terms */}
                    <h3 className="text-lg font-bold mt-6 mb-4">Payment Terms</h3>
                    {formData.paymentTerms.map((term, index) => (
                        <div key={index} className="grid grid-cols-5 gap-4 mb-2">
                            <input
                                type="text"
                                placeholder="Stage"
                                value={term.stage}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "stage", "paymentTerms")
                                }
                                className="border border-gray-300 rounded p-2"
                            />
                            <input
                                type="text"
                                placeholder="Payment"
                                value={term.payment}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "payment", "paymentTerms")
                                }
                                className="border border-gray-300 rounded p-2"
                            />
                            <textarea
                                placeholder="Items"
                                value={term.items}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "items", "paymentTerms")
                                }
                                className="border border-gray-300 rounded p-2"
                            ></textarea>
                            <input
                                type="text"
                                placeholder="Amount"
                                value={term.amount}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "amount", "paymentTerms")
                                }
                                className="border border-gray-300 rounded p-2"
                            />
                            <input
                                type="text"
                                placeholder="Remarks"
                                value={term.remarks}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "remarks", "paymentTerms")
                                }
                                className="border border-gray-300 rounded p-2"
                            />
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() =>
                            handleAddRow("paymentTerms", {
                                stage: "",
                                payment: "",
                                items: "",
                                amount: "",
                                remarks: "",
                            })
                        }
                        className="text-blue-500 mt-4"
                    >
                        Add Payment Term
                    </button>

                    {/* Terms & Conditions */}
                    <h3 className="text-lg font-bold mt-6 mb-4">Terms & Conditions</h3>
                    {formData.termsAndConditions.map((condition, index) => (
                        <div key={index} className="grid grid-cols-2 gap-4 mb-2">
                            <input
                                type="text"
                                placeholder="Title"
                                value={condition.title}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "title", "termsAndConditions")
                                }
                                className="border border-gray-300 rounded p-2"
                            />
                            <textarea
                                placeholder="Description"
                                value={condition.description}
                                onChange={(e) =>
                                    handleNestedChange(e, index, "description", "termsAndConditions")
                                }
                                className="border border-gray-300 rounded p-2"
                            ></textarea>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() =>
                            handleAddRow("termsAndConditions", { title: "", description: "" })
                        }
                        className="text-blue-500 mt-4"
                    >
                        Add Term
                    </button>
                </div>
            )}

            {/* Step 2: Items & Quote Value */}
            {currentStep === 2 && (
                <div>
                    <p className="text-gray-500">Step 2 is under development.</p>
                </div>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-6">
                {currentStep > 1 && (
                    <button
                        type="button"
                        onClick={handlePreviousStep}
                        className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
                    >
                        Previous
                    </button>
                )}
                {currentStep === 1 && (
                    <button
                        type="button"
                        onClick={handleNextStep}
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                    >
                        Next
                    </button>
                )}
                {currentStep === 2 && (
                    <div className="flex space-x-4">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                        >
                            Submit
                        </button>
                    </div>
                )}
            </div>
        </form>
    );
};

export default QuotationForm;
