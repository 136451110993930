import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import WorkTypeForm from "../../components/forms/WorkTypeForm"; // Work Type Form Component

const WorkTypes = () => {
  const [workTypes, setWorkTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorkType, setSelectedWorkType] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Work Type Id", accessor: (_row, i) => i + 1 },
    { Header: "Work Type Name", accessor: "workTypeName" },
    { Header: "Item Code", accessor: "itemCode" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add a new work type
  const handleAddWorkType = (workType) => {
    setWorkTypes([
      ...workTypes,
      { ...workType, id: workTypes.length + 1 },
    ]);
    closeModal();
  };

  // Edit an existing work type
  const handleEditWorkType = (updatedWorkType) => {
    setWorkTypes(
      workTypes.map((workType) =>
        workType.id === updatedWorkType.id ? updatedWorkType : workType
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this work type?")) {
      setWorkTypes(workTypes.filter((workType) => workType.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedWorkType(null);
    setIsModalOpen(true);
  };

  const handleEdit = (workType) => {
    setSelectedWorkType(workType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedWorkType(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Work Types</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Work Type
        </button>
      </div>
      <ReusableTable columns={columns} data={workTypes} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedWorkType ? "Edit Work Type" : "Add New Work Type"}
        >
          <WorkTypeForm
            onSubmit={
              selectedWorkType ? handleEditWorkType : handleAddWorkType
            }
            onCancel={closeModal}
            initialValues={
              selectedWorkType || {
                workTypeName: "",
                itemCode: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkTypes;
