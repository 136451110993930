import React, { useState } from "react";

const GroupLabourForm = ({ onSubmit, onCancel, initialValues }) => {
  const [teamLeader, setTeamLeader] = useState(
    initialValues?.teamLeader || {
      name: "",
      workType: "",
      contactNo: "",
      alternateContactNo: "",
      aadharNo: "",
      address: "",
      photo: null,
      documentFile: null,
      openingBalance: 0,
      dailyBasicWage: "fixed", // "fixed" or "notFixed"
      wagePerDay: "",
    }
  );

  const [teamMembers, setTeamMembers] = useState(
    initialValues?.teamMembers || []
  );

  const [newMember, setNewMember] = useState({
    name: "",
    contactNo: "",
    workType: "",
    dailyWage: "",
    openingBalance: "",
  });

  const handleTeamLeaderChange = (e) => {
    const { name, value, type, files } = e.target;
    setTeamLeader((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleNewMemberChange = (e) => {
    const { name, value } = e.target;
    setNewMember((prev) => ({ ...prev, [name]: value }));
  };

  const addNewMember = () => {
    if (!newMember.name || !newMember.contactNo || !newMember.workType) {
      alert("Please fill all the required fields for the team member.");
      return;
    }
    setTeamMembers([...teamMembers, { ...newMember, id: Date.now() }]);
    setNewMember({
      name: "",
      contactNo: "",
      workType: "",
      dailyWage: "",
      openingBalance: "",
    });
  };

  const removeMember = (id) => {
    setTeamMembers(teamMembers.filter((member) => member.id !== id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ teamLeader, teamMembers });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Team Leader Section */}
      <h3 className="text-lg font-bold">Team Leader Details</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Labour Name</label>
          <input
            type="text"
            name="name"
            value={teamLeader.name}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Work Type</label>
          <select
            name="workType"
            value={teamLeader.workType}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">Select Work Type</option>
            <option value="Carpentry">Carpentry</option>
            <option value="Masonry">Masonry</option>
            <option value="Plumbing">Plumbing</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium">Contact No</label>
          <input
            type="text"
            name="contactNo"
            value={teamLeader.contactNo}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Alternate Contact No</label>
          <input
            type="text"
            name="alternateContactNo"
            value={teamLeader.alternateContactNo}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Aadhar No</label>
          <input
            type="text"
            name="aadharNo"
            value={teamLeader.aadharNo}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Address</label>
          <textarea
            name="address"
            value={teamLeader.address}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
            rows="3"
          ></textarea>
        </div>

        <div>
          <label className="block text-sm font-medium">Photo</label>
          <input
            type="file"
            name="photo"
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Document File</label>
          <input
            type="file"
            name="documentFile"
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Wage Per Day</label>
          <input
            type="number"
            name="wagePerDay"
            value={teamLeader.wagePerDay}
            onChange={handleTeamLeaderChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      <hr className="my-4" />

      {/* Team Members Section */}
      <h3 className="text-lg font-bold">Team Members Details</h3>
      <div className="grid grid-cols-5 gap-4 items-center">
        <input
          type="text"
          name="name"
          value={newMember.name}
          onChange={handleNewMemberChange}
          placeholder="Labour Name"
          className="w-full border border-gray-300 rounded p-2"
        />
        <input
          type="text"
          name="contactNo"
          value={newMember.contactNo}
          onChange={handleNewMemberChange}
          placeholder="Contact No"
          className="w-full border border-gray-300 rounded p-2"
        />
        <select
          name="workType"
          value={newMember.workType}
          onChange={handleNewMemberChange}
          className="w-full border border-gray-300 rounded p-2"
        >
          <option value="">Select Work Type</option>
          <option value="Carpentry">Carpentry</option>
          <option value="Masonry">Masonry</option>
          <option value="Plumbing">Plumbing</option>
        </select>
        <input
          type="number"
          name="dailyWage"
          value={newMember.dailyWage}
          onChange={handleNewMemberChange}
          placeholder="Daily Wage"
          className="w-full border border-gray-300 rounded p-2"
        />
        <button
          type="button"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={addNewMember}
        >
          Add Member
        </button>
      </div>

      <table className="w-full mt-4 border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2">Sl No</th>
            <th className="p-2">Labour Name</th>
            <th className="p-2">Contact No</th>
            <th className="p-2">Work Type</th>
            <th className="p-2">Daily Wage</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((member, index) => (
            <tr key={member.id}>
              <td className="p-2 text-center">{index + 1}</td>
              <td className="p-2">{member.name}</td>
              <td className="p-2">{member.contactNo}</td>
              <td className="p-2">{member.workType}</td>
              <td className="p-2 text-center">{member.dailyWage}</td>
              <td className="p-2 text-center">
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                  onClick={() => removeMember(member.id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr className="my-4" />

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default GroupLabourForm;
