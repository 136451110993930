import React, { useState } from "react";

const EmployeeForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-4 grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium">Name</label>
          <input
            type="text"
            name="name"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Designation</label>
          <input
            type="text"
            name="designation"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.designation}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Employee Code</label>
          <input
            type="text"
            name="employeeCode"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.employeeCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Contact No</label>
          <input
            type="text"
            name="contact"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.contact}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Gender</label>
          <div className="flex space-x-4">
            <label>
              <input
                type="radio"
                name="gender"
                value="Male"
                checked={formData.gender === "Male"}
                onChange={handleChange}
              />
              Male
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="Female"
                checked={formData.gender === "Female"}
                onChange={handleChange}
              />
              Female
            </label>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium">Date of Birth</label>
          <input
            type="date"
            name="dob"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.dob}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Nationality</label>
          <input
            type="text"
            name="nationality"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.nationality}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Sponsor</label>
          <input
            type="text"
            name="sponsor"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.sponsor}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Work Location</label>
          <input
            type="text"
            name="workLocation"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.workLocation}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Basic Wage</label>
          <input
            type="number"
            name="basicWage"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.basicWage}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Wage Type</label>
          <select
            name="wageType"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.wageType}
            onChange={handleChange}
          >
            <option value="Hourly">Hourly</option>
            <option value="Daily">Daily</option>
            <option value="Monthly">Monthly</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">Fixed Basic Wage</label>
          <div className="flex space-x-4">
            <label>
              <input
                type="radio"
                name="basicWageFixed"
                value="true"
                checked={formData.basicWageFixed === true}
                onChange={(e) =>
                  handleChange({ target: { name: "basicWageFixed", value: true } })
                }
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="basicWageFixed"
                value="false"
                checked={formData.basicWageFixed === false}
                onChange={(e) =>
                  handleChange({ target: { name: "basicWageFixed", value: false } })
                }
              />
              No
            </label>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium">Opening Balance</label>
          <input
            type="number"
            name="openingBalance"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.openingBalance}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Food Allowance</label>
          <input
            type="number"
            name="foodAllowance"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.foodAllowance}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Incentive</label>
          <input
            type="number"
            name="incentive"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.incentive}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Civil ID</label>
          <input
            type="text"
            name="civilId"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.civilId}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Civil ID Expiry Date</label>
          <input
            type="date"
            name="civilIdExpiryDate"
            className="w-full border border-gray-300 rounded p-2"
            value={formData.civilIdExpiryDate}
            onChange={handleChange}
          />
        </div>
        {/* Add more fields as necessary */}
      </div>
      <hr className="my-6" />
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EmployeeForm;
