import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";

const PurchaseOrderForm = () => {
    const [items, setItems] = useState([]);
    const [terms, setTerms] = useState([]);
    const [formDetails, setFormDetails] = useState({
        orderedTo: "",
        supplierName: "",
        vendorNo: "",
        orderDate: "",
        referenceNo: "",
        expenseCode: "",
        contactPerson: "",
        contactPhone: "",
        billingAddress: "",
        deliveryAddress: "",
        deliveryDateTime: "",
        estimatedAmount: 0,
        advancePaid: 0,
        remarks: "",
        preparedBy: "",
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormDetails({ ...formDetails, [name]: value });
    };

    const addItemToCart = () => {
        setItems([
            ...items,
            {
                id: items.length + 1,
                material: "Sample Material",
                unit: "Nos",
                quantity: 1,
                unitPrice: 100,
                totalAmount: 100,
            },
        ]);
    };

    const addTermCondition = () => {
        setTerms([
            ...terms,
            {
                id: terms.length + 1,
                title: "Sample Term",
                description: "Sample Description",
            },
        ]);
    };

    const columnsItems = [
        { Header: "Sl No", accessor: "id" },
        { Header: "Material", accessor: "material" },
        { Header: "Unit", accessor: "unit" },
        { Header: "Qty", accessor: "quantity" },
        { Header: "Unit Price", accessor: "unitPrice" },
        { Header: "Total Amt", accessor: "totalAmount" },
    ];

    const columnsTerms = [
        { Header: "Sl No", accessor: "id" },
        { Header: "Title", accessor: "title" },
        { Header: "Description", accessor: "description" },
    ];

    const handleSaveSubmit = () => {
        console.log("Form Details:", formDetails);
        console.log("Items:", items);
        console.log("Terms:", terms);
    };

    return (
        <div className="bg-white rounded-lg max-w-7xl mx-auto">
            <h2 className="text-2xl font-bold mb-8 text-gray-800">Purchase Order</h2>

            {/* Section: Order Details */}
            <div className="grid grid-cols-2 gap-6 mb-8">
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Ordered To
                    </label>
                    <select
                        name="orderedTo"
                        value={formDetails.orderedTo}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="">Select Ordered To</option>
                        <option value="Site 1">#508 - Bharath [Work Site]</option>
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Supplier Name
                    </label>
                    <input
                        type="text"
                        name="supplierName"
                        value={formDetails.supplierName}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Order Date
                    </label>
                    <input
                        type="date"
                        name="orderDate"
                        value={formDetails.orderDate}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Reference No
                    </label>
                    <input
                        type="text"
                        name="referenceNo"
                        value={formDetails.referenceNo}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </div>

            {/* Section: Add Items */}
            <h3 className="text-lg font-bold text-gray-700 mb-4">Add Items to Cart</h3>
            <div className="flex items-center gap-2 mb-6">
                <input
                    type="text"
                    placeholder="Enter Material Name"
                    className="w-full border-gray-300 rounded p-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                    onClick={addItemToCart}
                    className="bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600"
                >
                    Add to Cart
                </button>

            </div>
            <ReusableTable columns={columnsItems} data={items} />

            {/* Section: Payment Details */}
            <h3 className="text-lg font-bold text-gray-700 mt-8 mb-4">
                Payment Details
            </h3>
            <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Advance Paid
                    </label>
                    <input
                        type="number"
                        name="advancePaid"
                        value={formDetails.advancePaid}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-600">
                        Remarks
                    </label>
                    <textarea
                        name="remarks"
                        value={formDetails.remarks}
                        onChange={handleFormChange}
                        className="w-full border-gray-300 rounded p-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </div>

            {/* Section: Terms & Conditions */}
            <h3 className="text-lg font-bold text-gray-700 mt-8 mb-4">
                Terms and Conditions
            </h3>
            <button
                onClick={addTermCondition}
                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 mb-4"
            >
                Add Term
            </button>
            <ReusableTable columns={columnsTerms} data={terms} />

            {/* Submit Section */}
            <div className="flex justify-end mt-8">
                <button
                    onClick={handleSaveSubmit}
                    className="bg-green-500 text-white px-6 py-3 rounded shadow hover:bg-green-600"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default PurchaseOrderForm;
