import React from "react";

const ProjectHeader = ({ onEditDetails, onEditStatus, onPrintSummary }) => {
  return (
    <div className="bg-white p-2  mb-6 flex justify-end space-x-4 rounded-lg">
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded"
        onClick={onEditDetails}
      >
        Edit Project Details
      </button>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded"
        onClick={onEditStatus}
      >
        Edit Project Status
      </button>
      <button
        className="bg-gray-500 text-white px-4 py-2 rounded"
        onClick={onPrintSummary}
      >
        Print Project Summary
      </button>
    </div>
  );
};

export default ProjectHeader;
