import React, { useEffect, useRef } from "react";
import Gantt from "frappe-gantt";

const ProjectScheduling = () => {
  const ganttRef = useRef(null); // DOM element for the Gantt chart
  const ganttInstance = useRef(null); // Reference to the Gantt instance

  useEffect(() => {
    // Define tasks
    const tasks = [
      {
        id: "Task 1",
        name: "Project Initiation",
        start: "2024-12-01",
        end: "2024-12-10",
        progress: 20,
        custom_class: "project-initiation",
      },
      {
        id: "Task 2",
        name: "Design Phase",
        start: "2024-12-11",
        end: "2024-12-20",
        progress: 40,
        dependencies: "Task 1",
        custom_class: "design-phase",
      },
      {
        id: "Task 3",
        name: "Development",
        start: "2024-12-21",
        end: "2025-01-10",
        progress: 10,
        dependencies: "Task 2",
        custom_class: "development-phase",
      },
    ];

    if (ganttRef.current) {
      ganttInstance.current = new Gantt(ganttRef.current, tasks, {
        view_mode: "Week",
        date_format: "YYYY-MM-DD",
        custom_popup_html: (task) => `
          <div class="gantt-popup">
            <h5>${task.name}</h5>
            <p><strong>Start:</strong> ${task.start}</p>
            <p><strong>End:</strong> ${task.end}</p>
            <p><strong>Progress:</strong> ${task.progress}%</p>
          </div>
        `,
        on_click: (task) => alert(`Task clicked: ${task.name}`),
        on_date_change: (task, start, end) =>
          alert(`Task ${task.name} changed to ${start} - ${end}`),
        on_progress_change: (task, progress) =>
          alert(`Progress of ${task.name} updated to ${progress}%`),
      });
    }

    // Cleanup function to avoid multiple instances or null reference
    return () => {
      if (ganttRef.current) {
        ganttRef.current.innerHTML = ""; // Clears DOM content
      }
      ganttInstance.current = null; // Reset instance
    };
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Project Scheduling</h1>
      <div>
        <button
          onClick={() => alert("Add Task functionality can be implemented")}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Add Task
        </button>
      </div>
      <div ref={ganttRef}></div>
    </div>
  );
};

export default ProjectScheduling;
