import React, { useMemo, useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../common/Modal";
import LabourWageGroupForm from "../forms/LabourWageGroupForm"; // For Group Wage Slip
import QuickWageSlipForm from "../forms/QuickWageSlipForm"; // For Quick Wage Slip
import IndividualWageSlipForm from "../forms/IndividualWageSlipForm"; // For Individual Wage Slip

const LabourWageSlips = () => {

    const [modalType, setModalType] = useState(""); // Track the modal type
    const [data, setData] = useState([
        {
            id: 1,
            labourName: "John Doe",
            workType: "Construction",
            workDate: "2024-12-01",
            day: "Monday",
            daysCount: 1,
            labourCount: 5,
            basicWage: 500,
            overtimeRate: 100,
            overtimeDuration: 2,
            overtimeWage: 200,
        },
        {
            id: 2,
            labourName: "Jane Smith",
            workType: "Plumbing",
            workDate: "2024-12-01",
            day: "Monday",
            daysCount: 1,
            labourCount: 3,
            basicWage: 600,
            overtimeRate: 120,
            overtimeDuration: 1,
            overtimeWage: 120,
        },
    ]);

    // Calculations for Statistics
    const stats = useMemo(() => {
        const totalLaboursWorked = data.reduce((acc, curr) => acc + curr.labourCount, 0);
        const distinctLabours = [...new Set(data.map((item) => item.labourName))].length;
        const totalDaysWorked = [...new Set(data.map((item) => item.workDate))].length;
        const totalWage = data.reduce(
            (acc, curr) => acc + curr.basicWage * curr.labourCount + curr.overtimeWage,
            0
        );
        return { totalLaboursWorked, distinctLabours, totalDaysWorked, totalWage };
    }, [data]);

    // Table Columns
    const columns = useMemo(
        () => [
            { Header: "Sr No", accessor: "id" },
            { Header: "Labour Name", accessor: "labourName" },
            { Header: "Work Type", accessor: "workType" },
            { Header: "Work Date", accessor: "workDate" },
            { Header: "Day", accessor: "day" },
            { Header: "Days Count", accessor: "daysCount" },
            { Header: "No of Labours Worked", accessor: "labourCount" },
            { Header: "Basic Wage/Day", accessor: "basicWage" },
            { Header: "Overtime Rate/Day", accessor: "overtimeRate" },
            { Header: "Overtime Duration", accessor: "overtimeDuration" },
            { Header: "Overtime Wage", accessor: "overtimeWage" },
        ],
        []
    );

    const handleOpenModal = (type) => {
        setModalType(type);
    };

    const handleCloseModal = () => {
        setModalType("");
    };

    const handleSubmitModal = (data) => {
        console.log(`Submitted Data for ${modalType}: `, data);
        handleCloseModal();
    };
    return (
        <div className="bg-white p-6 rounded-lg shadow">
            <h1 className="text-xl font-bold mb-4">Labour Wage Slips</h1>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 ">
                <div className="bg-blue-100 p-4 rounded shadow">
                    <h2 className="text-sm font-semibold">Total Labours Worked</h2>
                    <p className="text-xl font-bold">{stats.totalLaboursWorked}</p>
                </div>
                <div className="bg-green-100 p-4 rounded shadow">
                    <h2 className="text-sm font-semibold">Distinct Labours</h2>
                    <p className="text-xl font-bold">{stats.distinctLabours}</p>
                </div>
                <div className="bg-yellow-100 p-4 rounded shadow">
                    <h2 className="text-sm font-semibold">Total Days Worked</h2>
                    <p className="text-xl font-bold">{stats.totalDaysWorked}</p>
                </div>
                <div className="bg-red-100 p-4 rounded shadow">
                    <h2 className="text-sm font-semibold">Total Wage</h2>
                    <p className="text-xl font-bold">₹{stats.totalWage}</p>
                </div>
            </div>
            {/* Wage Slip Buttons */}
            <div className="flex justify-end space-x-4 mt-4">
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                    onClick={() => handleOpenModal("QuickWageSlip")}
                >
                    Quick Wage Slip
                </button>
                <button
                    className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
                    onClick={() => handleOpenModal("WageSlipGroup")}
                >
                    Wage Slip (Group)
                </button>
                <button
                    className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600"
                    onClick={() => handleOpenModal("WageSlipIndividual")}
                >
                    Wage Slip (Individual)
                </button>
            </div>


            {/* Wage Slip Table */}
            <ReusableTable columns={columns} data={data} />

            {modalType === "QuickWageSlip" && (
                <Modal
                    isOpen={true}
                    onClose={handleCloseModal}
                    title="Quick Wage Slip"
                    width="w-3/4"
                >
                    <QuickWageSlipForm
                        onSubmit={handleSubmitModal}
                        onCancel={handleCloseModal}
                    />
                </Modal>
            )}

            {modalType === "WageSlipGroup" && (
                <Modal
                    isOpen={true}
                    onClose={handleCloseModal}
                    title="Wage Slip (Group)"
                    width="w-3/4"
                >
                    <LabourWageGroupForm
                        onSubmit={handleSubmitModal}
                        onCancel={handleCloseModal}
                    />
                </Modal>
            )}

            {modalType === "WageSlipIndividual" && (
                <Modal
                    isOpen={true}
                    onClose={handleCloseModal}
                    title="Wage Slip (Individual)"
                    width="w-3/4"
                >
                    <IndividualWageSlipForm
                        onSubmit={handleSubmitModal}
                        onCancel={handleCloseModal}
                    />
                </Modal>
            )}
        </div>
    );
};

export default LabourWageSlips;
