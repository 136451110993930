import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import SectionForm from "../../components/forms/SectionForm"; // Section Form Component

const ProjectSections = () => {
  const [sections, setSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  // Table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Section Name", accessor: "sectionName" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Section"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Section"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add new section
  const handleAddSection = (section) => {
    setSections([
      ...sections,
      { ...section, id: sections.length + 1 },
    ]);
    closeModal();
  };

  // Edit existing section
  const handleEditSection = (updatedSection) => {
    setSections(
      sections.map((section) =>
        section.id === updatedSection.id ? updatedSection : section
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setSections(sections.filter((section) => section.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedSection(null);
    setIsModalOpen(true);
  };

  const handleEdit = (section) => {
    setSelectedSection(section);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSection(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Project Sections</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Section
        </button>
      </div>
      <ReusableTable columns={columns} data={sections} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedSection ? "Edit Section" : "Add New Section"}
        >
          <SectionForm
            onSubmit={selectedSection ? handleEditSection : handleAddSection}
            onCancel={closeModal}
            initialValues={
              selectedSection || {
                sectionName: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectSections;
