import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Assuming you have a reusable table component
import Modal from "../../components/common/Modal"; // Modal component for adding/editing suppliers
import SupplierForm from "../../components/forms/SupplierForm"; // Form component for suppliers

const Suppliers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedSupplier, setSelectedSupplier] = useState(null); // For editing
  const [data, setData] = useState([
    {
      supplierId: "SUP-001",
      supplierName: "ABC Supplies",
      vendorNo: "VND001",
      supplierAddress: "123 Main Street, City",
      category: "Electronics",
      phone: "1234567890",
      email: "abc@supplies.com",
      contactPerson: "John Doe",
      gstNo: "GST12345",
      gstDate: "2024-12-01",
      panNo: "PAN98765",
      aadharNo: "1234-5678-9101",
      bankAccountNo: "123456789012",
      ifscCode: "IFSC001",
      bankName: "ABC Bank",
      branchName: "Main Branch",
      openingBalance: 10000,
      totalBillAmount: 50000,
      totalPaid: 45000,
      currentBalance: 5000,
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Supplier ID", accessor: "supplierId" },
      { Header: "Supplier Name", accessor: "supplierName" },
      { Header: "Vendor No", accessor: "vendorNo" },
      { Header: "Supplier Address", accessor: "supplierAddress" },
      { Header: "Category", accessor: "category" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Email", accessor: "email" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "GST No", accessor: "gstNo" },
      { Header: "GST Date", accessor: "gstDate" },
      { Header: "PAN No", accessor: "panNo" },
      { Header: "Aadhar", accessor: "aadharNo" },
      { Header: "Bank A/C No", accessor: "bankAccountNo" },
      { Header: "IFSC Code", accessor: "ifscCode" },
      { Header: "Bank Name", accessor: "bankName" },
      { Header: "Branch", accessor: "branchName" },
      { Header: "Opening Balance", accessor: "openingBalance" },
      { Header: "Total Bill Amount", accessor: "totalBillAmount" },
      { Header: "Total Paid", accessor: "totalPaid" },
      { Header: "Current Balance", accessor: "currentBalance" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.supplierId)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedSupplier(null);
    setIsModalOpen(true);
  };

  const handleEdit = (supplier) => {
    setModalMode("Edit");
    setSelectedSupplier(supplier);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.supplierId !== id));
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      // Add new supplier
      const newSupplier = {
        ...formData,
        supplierId: `SUP-${data.length + 1}`,
      };
      setData([...data, newSupplier]);
    } else if (modalMode === "Edit") {
      // Update existing supplier
      setData(
        data.map((item) =>
          item.supplierId === selectedSupplier.supplierId
            ? { ...item, ...formData }
            : item
        )
      );
    }
    setIsModalOpen(false);
    setSelectedSupplier(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Suppliers</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Supplier
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Supplier" : "Edit Supplier"}
        width="w-3/4"
      >
        <SupplierForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedSupplier} // Prefill the form for editing
        />
      </Modal>
    </div>
  );
};

export default Suppliers;
