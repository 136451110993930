
import React from "react";
import ExpenseBills from "../../components/ProjectDetails/ExpenseBills";

const Expenses = () => {
  return (
    <div>
     <ExpenseBills/>
    </div>
  );
};

export default Expenses;
