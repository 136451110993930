import React from "react";
import { FaTasks, FaCalendarAlt, FaUser, FaMapMarkerAlt, FaMoneyBillWave, FaTag } from "react-icons/fa";

const ProjectInfo = () => {
  const projectDetails = [
    { icon: <FaTasks />, label: "Status", value: "In Progress" },
    { icon: <FaTag />, label: "Section", value: "Residential" },
    { icon: <FaTag />, label: "Category", value: "All In All" },
    { icon: <FaTag />, label: "Reference No", value: "#508" },
    { icon: <FaUser />, label: "Project Name", value: "Bharat" },
    { icon: <FaMapMarkerAlt />, label: "Location", value: "Nava" },
    { icon: <FaMapMarkerAlt />, label: "Area", value: "Residential" },
    { icon: <FaCalendarAlt />, label: "Start Date", value: "01-Jan-2024" },
    { icon: <FaCalendarAlt />, label: "Completion Date", value: "31-Dec-2024" },
    { icon: <FaTasks />, label: "Priority", value: "High" },
    { icon: <FaUser />, label: "Client", value: "Dileep" },
    { icon: <FaMoneyBillWave />, label: "Estimated Budget", value: "₹1,30,000" },
    { icon: <FaMoneyBillWave />, label: "GST %", value: "0%" },
    { icon: <FaMoneyBillWave />, label: "GST Amount", value: "₹0" },
    { icon: <FaTag />, label: "Initial Security Deposit", value: "₹0" },
    { icon: <FaTag />, label: "Security Deposit Details", value: "None" },
    { icon: <FaTasks />, label: "Project Description", value: "Project description goes here." },
    { icon: <FaTag />, label: "LPO No", value: "LPO-12345" },
    { icon: <FaTag />, label: "Quotation No", value: "QW-67890" },
  ];

  const clientDetails = [
    { icon: <FaUser />, label: "Client Name", value: "Dileep" },
    { icon: <FaUser />, label: "Contact", value: "9999999999" },
    { icon: <FaUser />, label: "WhatsApp No", value: "9999999999" },
    { icon: <FaUser />, label: "Email", value: "dileep@example.com" },
    { icon: <FaTag />, label: "Civil ID", value: "CIVIL-123456" },
    { icon: <FaMapMarkerAlt />, label: "Address", value: "123 Street, City, State, ZIP" },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      {/* Project Details Section */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-800 border-b pb-2 mb-4">Project Details</h2>
        <div className="grid grid-cols-2 gap-4">
          {projectDetails.map((detail, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div className="text-blue-500 text-base">{detail.icon}</div>
              <span className="font-medium text-gray-600">{detail.label}:</span>
              <span className="text-gray-800">{detail.value}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Client Details Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800 border-b pb-2 mb-4">Client Details</h2>
        <div className="grid grid-cols-2 gap-4">
          {clientDetails.map((detail, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div className="text-green-500 text-base">{detail.icon}</div>
              <span className="font-medium text-gray-600">{detail.label}:</span>
              <span className="text-gray-800">{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
