
import React from "react";

const HR = () => {
  return (
    <div>
      <h1>HR</h1>
      <p>This is the HR page.</p>
    </div>
  );
};

export default HR;
