
import React from "react";
import SecurityDeposits from "../../components/ProjectDetails/SecurityDeposits";

const SecurityDeposit = () => {
  return (
    <div>
      <SecurityDeposits/>
    </div>
  );
};

export default SecurityDeposit;
