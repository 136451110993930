import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import BankForm from "../../components/forms/BankForm"; // Bank Form Component

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "A/C Holder Company Name", accessor: "accountHolder" },
    { Header: "Bank Name", accessor: "bankName" },
    { Header: "Account No", accessor: "accountNumber" },
    { Header: "IFSC", accessor: "ifscCode" },
    { Header: "Bank Branch", accessor: "branch" },
    { Header: "Swift Code", accessor: "swiftCode" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Add a new bank detail
  const handleAddBank = (bank) => {
    setBankDetails([
      ...bankDetails,
      { ...bank, id: bankDetails.length + 1 },
    ]);
    closeModal();
  };

  // Edit an existing bank detail
  const handleEditBank = (updatedBank) => {
    setBankDetails(
      bankDetails.map((bank) =>
        bank.id === updatedBank.id ? updatedBank : bank
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this bank detail?")) {
      setBankDetails(bankDetails.filter((bank) => bank.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedBank(null);
    setIsModalOpen(true);
  };

  const handleEdit = (bank) => {
    setSelectedBank(bank);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBank(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Bank Details</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Bank Detail
        </button>
      </div>
      <ReusableTable columns={columns} data={bankDetails} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedBank ? "Edit Bank Detail" : "Add New Bank Detail"}
        >
          <BankForm
            onSubmit={selectedBank ? handleEditBank : handleAddBank}
            onCancel={closeModal}
            initialValues={
              selectedBank || {
                accountHolder: "",
                bankName: "",
                accountNumber: "",
                ifscCode: "",
                branch: "",
                swiftCode: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default BankDetails;
