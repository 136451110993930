import React, { useState } from "react";

const LabourForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(
    initialValues || {
      name: "",
      workType: "",
      contactNo: "",
      alternateContactNo: "",
      aadharNo: "",
      address: "",
      photo: null,
      documentFile: null,
      openingBalance: 0,
      dailyBasicWage: "fixed", // "fixed" or "notFixed"
      wagePerDay: "",
      overtimeRate: "",
      taPerDay: "",
      foodAllowance: "",
    }
  );

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Labour Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Work Type</label>
          <select
            name="workType"
            value={formData.workType}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">Select Work Type</option>
            <option value="Carpentry">Carpentry</option>
            <option value="Masonry">Masonry</option>
            <option value="Plumbing">Plumbing</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium">Contact No</label>
          <input
            type="text"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Alternate Contact No</label>
          <input
            type="text"
            name="alternateContactNo"
            value={formData.alternateContactNo}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Aadhar No</label>
          <input
            type="text"
            name="aadharNo"
            value={formData.aadharNo}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Address</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            rows="3"
          ></textarea>
        </div>

        <div>
          <label className="block text-sm font-medium">Photo</label>
          <input
            type="file"
            name="photo"
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Document File</label>
          <input
            type="file"
            name="documentFile"
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Opening Balance Payable</label>
          <input
            type="number"
            name="openingBalance"
            value={formData.openingBalance}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Daily Basic Wage</label>
          <div className="flex items-center space-x-4">
            <label>
              <input
                type="radio"
                name="dailyBasicWage"
                value="fixed"
                checked={formData.dailyBasicWage === "fixed"}
                onChange={handleChange}
                className="mr-2"
              />
              Fixed
            </label>
            <label>
              <input
                type="radio"
                name="dailyBasicWage"
                value="notFixed"
                checked={formData.dailyBasicWage === "notFixed"}
                onChange={handleChange}
                className="mr-2"
              />
              Not Fixed
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium">Wage Per Day</label>
          <input
            type="number"
            name="wagePerDay"
            value={formData.wagePerDay}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Overtime Rate/Hr</label>
          <input
            type="number"
            name="overtimeRate"
            value={formData.overtimeRate}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">TA/Day</label>
          <input
            type="number"
            name="taPerDay"
            value={formData.taPerDay}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Food Allowance</label>
          <input
            type="number"
            name="foodAllowance"
            value={formData.foodAllowance}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      <hr className="my-4" />

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default LabourForm;
