import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa"; // FontAwesome icon for delete

const InvoiceForm = () => {
  const [lineItems, setLineItems] = useState([
    { id: 1, description: "", itemCode: "", unit: "", quantity: 1, unitPrice: 0 },
  ]);
  const [retentionPercent, setRetentionPercent] = useState(10); // Default Retention %

  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      { id: Date.now(), description: "", itemCode: "", unit: "", quantity: 1, unitPrice: 0 },
    ]);
  };

  const removeLineItem = (id) => {
    setLineItems(lineItems.filter((item) => item.id !== id));
  };

  const calculateTotals = () => {
    let taxableAmt = 0;
    let retentionAmt = 0;
    let totalAmt = 0;

    lineItems.forEach((item) => {
      const itemTotal = item.quantity * item.unitPrice;
      taxableAmt += itemTotal;
      retentionAmt += (itemTotal * retentionPercent) / 100;
      totalAmt += itemTotal;
    });

    return { taxableAmt, retentionAmt, totalAmt };
  };

  const totals = calculateTotals();

  return (
    <div className="">
      {/* Invoice Header */}
      <div className="grid grid-cols-3 gap-6 items-center mb-8">
        <div>
          <label className="block text-sm font-semibold mb-2">Invoice From</label>
          <textarea
            className="w-full border border-gray-300 rounded-lg p-3 resize-none h-24"
            placeholder="Your company details"
          />
        </div>
        <div className="text-center">
          <img
            src="/images/logo.png"
            alt="Company Logo"
            className="h-16 w-auto mx-auto"
          />
        </div>
        <div>
          <label className="block text-sm font-semibold mb-2">Invoice Date</label>
          <input
            type="date"
            className="w-full border border-gray-300 rounded-lg p-3"
          />
        </div>
      </div>

      {/* Invoice To Section */}
      <div className="mb-8">
        <h3 className="text-lg font-bold mb-4">Invoice To</h3>
        <div>
          <label className="block text-sm font-semibold mb-2">Client Name</label>
          <select className="w-full border border-gray-300 rounded-lg p-3">
            <option>Select Client</option>
            <option>Client A</option>
            <option>Client B</option>
            <option>Client C</option>
          </select>
        </div>
      </div>

      {/* Line Items */}
      <div className="mb-8">
        <h3 className="text-lg font-bold mb-4">Line Items</h3>
        <div className="grid grid-cols-8 gap-4 items-center font-medium text-gray-700 mb-4">
          <span>Sr. No.</span>
          <span>Description</span>
          <span>Item Code</span>
          <span>Unit</span>
          <span>Quantity</span>
          <span>Unit Price</span>
          <span>Total Amount</span>
          <span>Action</span>
        </div>
        {lineItems.map((item, index) => (
          <div key={item.id} className="grid grid-cols-8 gap-4 items-center mb-3">
            <span>{index + 1}</span>
            <input
              className="border border-gray-300 rounded-lg p-2"
              placeholder="Description"
              value={item.description}
              onChange={(e) =>
                setLineItems(
                  lineItems.map((i) =>
                    i.id === item.id ? { ...i, description: e.target.value } : i
                  )
                )
              }
            />
            <input
              className="border border-gray-300 rounded-lg p-2"
              placeholder="Item Code"
              value={item.itemCode}
              onChange={(e) =>
                setLineItems(
                  lineItems.map((i) =>
                    i.id === item.id ? { ...i, itemCode: e.target.value } : i
                  )
                )
              }
            />
            <select
              className="border border-gray-300 rounded-lg p-2"
              value={item.unit}
              onChange={(e) =>
                setLineItems(
                  lineItems.map((i) =>
                    i.id === item.id ? { ...i, unit: e.target.value } : i
                  )
                )
              }
            >
              <option>Select Unit</option>
              <option>Sqft</option>
              <option>Nos</option>
            </select>
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2"
              placeholder="Quantity"
              value={item.quantity}
              onChange={(e) =>
                setLineItems(
                  lineItems.map((i) =>
                    i.id === item.id ? { ...i, quantity: +e.target.value } : i
                  )
                )
              }
            />
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2"
              placeholder="Unit Price"
              value={item.unitPrice}
              onChange={(e) =>
                setLineItems(
                  lineItems.map((i) =>
                    i.id === item.id ? { ...i, unitPrice: +e.target.value } : i
                  )
                )
              }
            />
            <span className="text-center">{`₹${(item.quantity * item.unitPrice).toFixed(2)}`}</span>
            <button
              onClick={() => removeLineItem(item.id)}
              className="text-red-500 hover:text-red-700 flex items-center justify-center"
            >
              <FaTrashAlt />
            </button>
          </div>
        ))}
        <button
          onClick={addLineItem}
          className="bg-green-500 text-white px-6 py-2 rounded-lg shadow hover:bg-green-600"
        >
          Add Item
        </button>
      </div>

      {/* Totals Section */}
      <div className="grid grid-cols-4 gap-4 mt-6 items-center">
        <div>
          <label className="block text-sm font-semibold mb-2">Retention %</label>
          <input
            type="number"
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={retentionPercent}
            onChange={(e) => setRetentionPercent(+e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-semibold mb-2">Taxable Amount</label>
          <input
            type="text"
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={`₹${totals.taxableAmt.toFixed(2)}`}
            disabled
          />
        </div>
        <div>
          <label className="block text-sm font-semibold mb-2">Retention Amount</label>
          <input
            type="text"
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={`₹${totals.retentionAmt.toFixed(2)}`}
            disabled
          />
        </div>
        <div>
          <label className="block text-sm font-semibold mb-2">Total Amount</label>
          <input
            type="text"
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={`₹${totals.totalAmt.toFixed(2)}`}
            disabled
          />
        </div>
      </div>

      {/* Notes/Remarks Section */}
      <div className="mt-6">
        <label className="block text-sm font-semibold mb-2">Notes/Remarks</label>
        <textarea
          className="w-full border border-gray-300 rounded-lg p-3 h-24 resize-none"
          placeholder="Add any notes or remarks here..."
        />
      </div>

      {/* Save and Submit Button */}
      <div className="mt-8 text-right">
        <button className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow hover:bg-blue-700">
          Save & Submit
        </button>
      </div>
    </div>
  );
};

export default InvoiceForm;
