import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Assuming a reusable table component exists
import Modal from "../../components/common/Modal"; // Assuming a reusable modal exists
import ExpenseBillForm from "../../components/forms/ExpenseBillForm"; // Separate form component

const ExpenseBills = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([
    {
      slNo: 1,
      billId: "BILL-001",
      billDate: "2024-12-01",
      expenseHead: "Office Supplies",
      billNo: "INV-001",
      consignee: "Client A",
      gstNo: "27AA1234AB1ZC",
      totalGstPercent: 18,
      totalGstAmt: 360,
      cgstPercent: 9,
      cgstAmt: 180,
      sgstPercent: 9,
      sgstAmt: 180,
      igstPercent: 0,
      igstAmt: 0,
      payableAmount: 2000,
      totalPaid: 1500,
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Bill ID", accessor: "billId" },
      { Header: "Bill Date", accessor: "billDate" },
      { Header: "Expense Head", accessor: "expenseHead" },
      { Header: "Bill No", accessor: "billNo" },
      { Header: "Consignee", accessor: "consignee" },
      { Header: "GST No", accessor: "gstNo" },
      { Header: "Total GST %", accessor: "totalGstPercent" },
      { Header: "Total GST Amt", accessor: "totalGstAmt" },
      { Header: "CGST %", accessor: "cgstPercent" },
      { Header: "CGST Amt", accessor: "cgstAmt" },
      { Header: "SGST %", accessor: "sgstPercent" },
      { Header: "SGST Amt", accessor: "sgstAmt" },
      { Header: "IGST %", accessor: "igstPercent" },
      { Header: "IGST Amt", accessor: "igstAmt" },
      { Header: "Payable Amount", accessor: "payableAmount" },
      { Header: "Total Paid", accessor: "totalPaid" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.billId)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleEdit = (bill) => {
    console.log("Edit Bill:", bill);
    setIsModalOpen(true); // Open modal with form
  };

  const handleDelete = (billId) => {
    setData(data.filter((item) => item.billId !== billId));
  };

  const handleFormSubmit = (newBill) => {
    const newData = {
      ...newBill,
      slNo: data.length + 1,
    };
    setData([...data, newData]);
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Expense Bills</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Bill
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add/Edit Expense Bill"
        width="w-3/4"
      >
        <ExpenseBillForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default ExpenseBills;
