import React, { useState } from "react";

const ReminderForm = ({ onSubmit, onCancel, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium">Reminder Type</label>
        <select
          name="type"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.type}
          onChange={handleChange}
        >
          <option value="">Select Type</option>
          <option value="Meeting">Meeting</option>
          <option value="Task">Task</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium">Reminder Title</label>
        <input
          type="text"
          name="title"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.title}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Reminder Date</label>
        <input
          type="date"
          name="reminderDate"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.reminderDate}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Description</label>
        <textarea
          name="description"
          className="w-full border border-gray-300 rounded p-2"
          rows="4"
          value={formData.description}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Google Drive Link (If Any File)</label>
        <input
          type="url"
          name="fileLink"
          className="w-full border border-gray-300 rounded p-2"
          value={formData.fileLink}
          onChange={handleChange}
        />
      </div>

      <hr className="my-4" />
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ReminderForm;
