import React, { useState } from "react";

const BOQForm = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    boqName: "",
    workOrderNo: "",
    attachmentLink: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.boqName || !formData.workOrderNo || !formData.attachmentLink) {
      alert("Please fill in the required fields!");
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium">BOQ Name</label>
        <input
          type="text"
          name="boqName"
          className="w-full border border-gray-300 rounded p-2"
          placeholder="Enter BOQ Name"
          value={formData.boqName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Work Order No</label>
        <input
          type="text"
          name="workOrderNo"
          className="w-full border border-gray-300 rounded p-2"
          placeholder="Enter Work Order No"
          value={formData.workOrderNo}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">File Attachment Link</label>
        <input
          type="url"
          name="attachmentLink"
          className="w-full border border-gray-300 rounded p-2"
          placeholder="Enter File Attachment Link"
          value={formData.attachmentLink}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-300 px-4 py-2 rounded shadow hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default BOQForm;
