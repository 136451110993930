export const roles = {
    Admin: [
      "view_projects",
      "view_project_details",
      "manage_users",
      "view_reports",
      "configure_settings",
      "view_enquiries",
      "create_quotations",
      "manage_subcontract_works",
      "manage_security_deposits",
      "manage_EMD",
      "manage_assets",
      "manage_accounts",
      "approve_client_payments",
      "manage_other_income",
      "manage_loans",
      "approve_user_payments",
      "view_purchase_requests",
      "manage_purchase_orders",
      "manage_purchase_bills",
      "handle_purchase_returns",
      "manage_stock",
      "log_expenses",
      "manage_labour",
      "manage_vehicle",
      "manage_hr",
      "manage_employees",
      "manage_users",
      "manage_tools",
      "approve_travel_allowance",
      "set_reminders",
      "manage_project_documents",
      "track_daily_tasks",
      "write_daily_notes",
      "view_attendance_reports",
      "view_activity_logs",
      "manage_clients",
      "manage_sales_customers",
      "define_services",
      "manage_suppliers",
      "manage_materials",
      "manage_bank_accounts",
      "define_work_types",
      "define_work_categories",
      "define_project_sections",
      "define_holidays",
      "define_work_locations",
      "define_tax_types",
    ],
    ProjectManager: [
      "view_projects",
      "manage_subcontract_works",
      "manage_assets",
      "track_daily_tasks",
      "write_daily_notes",
      "manage_project_documents",
      "set_reminders",
      "log_expenses",
      "manage_labour",
      "approve_travel_allowance",
      "view_reports",
    ],
    Accountant: [
      "view_accounts",
      "approve_client_payments",
      "manage_other_income",
      "manage_loans",
      "approve_user_payments",
      "view_purchase_requests",
      "manage_purchase_orders",
      "manage_purchase_bills",
      "handle_purchase_returns",
      "log_expenses",
      "view_reports",
    ],
    HR: [
      "manage_labour",
      "manage_hr",
      "view_attendance_reports",
      "approve_travel_allowance",
      "define_holidays",
    ],
    Storekeeper: [
      "manage_stock",
      "manage_tools",
      "manage_materials",
      "view_purchase_requests",
      "manage_suppliers",
    ],
    Client: [
      "view_projects",
      "view_enquiries",
      "approve_client_payments",
      "view_reports",
    ],
  };
  