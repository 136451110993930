import React, { useState } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import AreaForm from "../../components/forms/AreaForm"; // Area Form Component

const WorkLocations = () => {
  const [areas, setAreas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  // Table columns definition
  const columns = [
    { Header: "ID", accessor: (_row, i) => i + 1 },
    { Header: "Area Name", accessor: "areaName" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddArea = (area) => {
    setAreas([
      ...areas,
      { ...area, id: areas.length + 1 },
    ]);
    closeModal();
  };

  const handleEditArea = (updatedArea) => {
    setAreas(
      areas.map((area) =>
        area.id === updatedArea.id ? updatedArea : area
      )
    );
    closeModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this area?")) {
      setAreas(areas.filter((area) => area.id !== id));
    }
  };

  const handleAddClick = () => {
    setSelectedArea(null);
    setIsModalOpen(true);
  };

  const handleEdit = (area) => {
    setSelectedArea(area);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedArea(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Work Locations</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Area
        </button>
      </div>

      <ReusableTable columns={columns} data={areas} />

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedArea ? "Edit Area" : "Add New Area"}
        >
          <AreaForm
            onSubmit={selectedArea ? handleEditArea : handleAddArea}
            onCancel={closeModal}
            initialValues={selectedArea || { areaName: "" }}
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkLocations;
