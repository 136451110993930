import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import VehicleWorkSlipForm from "../../components/forms/VehicleWorkSlipForm"; // Form Component

const VehicleWorkSlip = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSlip, setEditingSlip] = useState(null); // To handle edit functionality
  const [data, setData] = useState([
    {
      slNo: 1,
      slipId: "SLIP-001",
      vehicle: "Truck A",
      consignee: "Client X",
      workType: "Material Transport",
      project: "Project A",
      workDate: "2024-12-01",
      duration: "5 hours",
      basicCharge: "₹5,000",
      overtimeDur: "2 hours",
      overtimeRate: "₹300/hour",
      overtimeAmt: "₹600",
      expenses: "Fuel",
      expenseAmt: "₹1,200",
      driverBata: "₹500",
      loadingAmt: "₹800",
      commission: "₹1,000",
      labourCharge: "₹1,200",
      otherExpenses: "₹500",
      totalPayable: "₹10,800",
      totalPaid: "₹9,000",
      balance: "₹1,800",
      remarks: "Pending payment for overtime",
      creation: "2024-12-01",
      paymentStatus: "Partial",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Slip ID", accessor: "slipId" },
      { Header: "Vehicle", accessor: "vehicle" },
      { Header: "Consignee", accessor: "consignee" },
      { Header: "Work Type", accessor: "workType" },
      { Header: "Project", accessor: "project" },
      { Header: "Work Date", accessor: "workDate" },
      { Header: "Duration", accessor: "duration" },
      { Header: "Basic Charge", accessor: "basicCharge" },
      { Header: "Overtime Dur", accessor: "overtimeDur" },
      { Header: "Overtime Rate", accessor: "overtimeRate" },
      { Header: "Overtime Amt", accessor: "overtimeAmt" },
      { Header: "Expenses", accessor: "expenses" },
      { Header: "Expense Amt", accessor: "expenseAmt" },
      { Header: "Driver Bata", accessor: "driverBata" },
      { Header: "Loading Amt", accessor: "loadingAmt" },
      { Header: "Commission", accessor: "commission" },
      { Header: "Labour Charge", accessor: "labourCharge" },
      { Header: "Other Expenses", accessor: "otherExpenses" },
      { Header: "Total Payable", accessor: "totalPayable" },
      { Header: "Total Paid", accessor: "totalPaid" },
      { Header: "Balance", accessor: "balance" },
      { Header: "Remarks", accessor: "remarks" },
      { Header: "Creation", accessor: "creation" },
      { Header: "Payment Status", accessor: "paymentStatus" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.slipId)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setEditingSlip(null); // Reset editing state
    setIsModalOpen(true);
  };

  const handleEdit = (workSlip) => {
    setEditingSlip(workSlip); // Set data for editing
    setIsModalOpen(true);
  };

  const handleDelete = (slipId) => {
    setData(data.filter((item) => item.slipId !== slipId));
  };

  const handleFormSubmit = (newWorkSlip) => {
    if (editingSlip) {
      // Update existing entry
      setData(
        data.map((item) =>
          item.slipId === editingSlip.slipId ? { ...item, ...newWorkSlip } : item
        )
      );
    } else {
      // Add new entry
      const newData = {
        ...newWorkSlip,
        slNo: data.length + 1,
        creation: new Date().toISOString().split("T")[0],
        slipId: `SLIP-${data.length + 1}`,
      };
      setData([...data, newData]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Vehicle Work Slips</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Work Slip
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={editingSlip ? "Edit Work Slip" : "Add New Work Slip"}
        width="w-3/4"
      >
        <VehicleWorkSlipForm
          onSubmit={handleFormSubmit}
          initialData={editingSlip}
        />
      </Modal>
    </div>
  );
};

export default VehicleWorkSlip;
