import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import EmployeeForm from "../../components/forms/EmployeeForm"; // Form for Employee Management

const Employees = () => {
  const [employees, setEmployees] = useState([
    {
      id: 1,
      name: "John Doe",
      employeeCode: "EMP001",
      department: "IT",
      designation: "Software Engineer",
      contact: "9876543210",
      address: "123 Street, City",
      gender: "Male",
      dob: "1990-01-01",
      nationality: "Indian",
      sponsor: "Company A",
      workLocation: "Main Office",
      arrivalDate: "2023-01-01",
      joinDate: "2023-01-15",
      basicWage: 50000,
      wageType: "Monthly",
      basicWageFixed: true,
      openingBalance: 2000,
      foodAllowance: 500,
      incentive: 1000,
      civilId: "CIV123456",
      civilIdExpiryDate: "2025-01-01",
      passportNo: "PASS123456",
      passportExpiryDate: "2026-01-01",
      healthCardNo: "HC123456",
      healthCardExpiryDate: "2024-01-01",
      bankName: "XYZ Bank",
      bankAccountNo: "1234567890",
      cardExpiryDate: "2025-12-31",
      tShirtSize: "L",
      shoeSize: "9",
      pantSize: "32",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedEmployee, setSelectedEmployee] = useState(null); // For editing

  const openModal = (mode, employee = null) => {
    setModalMode(mode);
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setEmployees([...employees, { id: employees.length + 1, ...formData }]);
    } else {
      setEmployees(
        employees.map((employee) =>
          employee.id === selectedEmployee.id ? { ...formData } : employee
        )
      );
    }
    closeModal();
  };

  const handleDelete = (id) => {
    setEmployees(employees.filter((employee) => employee.id !== id));
  };

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Name", accessor: "name" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Department", accessor: "department" },
    { Header: "Designation", accessor: "designation" },
    { Header: "Contact", accessor: "contact" },
    { Header: "Basic Wage", accessor: "basicWage" },
    { Header: "Wage Type", accessor: "wageType" },
    { Header: "Fixed Wage", accessor: "basicWageFixed" },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="mb-10">
        <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Employees Management</h1>
        <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => openModal("Add")}
          >
            Add New Employee
          </button>
        </div>
        <ReusableTable columns={columns} data={employees} />
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Employee" : "Edit Employee"}
        >
          <EmployeeForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={selectedEmployee || {
              name: "",
              employeeCode: "",
              department: "",
              designation: "",
              contact: "",
              address: "",
              gender: "",
              dob: "",
              nationality: "",
              sponsor: "",
              workLocation: "",
              arrivalDate: "",
              joinDate: "",
              basicWage: 0,
              wageType: "Monthly",
              basicWageFixed: false,
              openingBalance: 0,
              foodAllowance: 0,
              incentive: 0,
              civilId: "",
              civilIdExpiryDate: "",
              passportNo: "",
              passportExpiryDate: "",
              healthCardNo: "",
              healthCardExpiryDate: "",
              bankName: "",
              bankAccountNo: "",
              cardExpiryDate: "",
              tShirtSize: "",
              shoeSize: "",
              pantSize: "",
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Employees;
