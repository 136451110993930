import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable"; // Reusable table component
import Modal from "../../components/common/Modal"; // Reusable modal
import AssetForm from "../../components/forms/AssetForm"; // Form component for managing assets

const Assets = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null); // Selected asset for editing
  const [data, setData] = useState([
    {
      slNo: 1,
      assetName: "Laptop",
      description: "High-performance laptop",
      quantity: 5,
      unitCost: "₹50,000",
      totalCost: "₹250,000",
      purchasedOn: "2024-12-01",
      creation: "2024-12-01",
    },
  ]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "Asset Name", accessor: "assetName" },
      { Header: "Description", accessor: "description" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Unit Cost", accessor: "unitCost" },
      { Header: "Total Cost", accessor: "totalCost" },
      { Header: "Purchased On", accessor: "purchasedOn" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.slNo)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setSelectedAsset(null); // Reset selected asset for adding new
    setIsModalOpen(true);
  };

  const handleEdit = (asset) => {
    setSelectedAsset(asset); // Set the selected asset for editing
    setIsModalOpen(true);
  };

  const handleDelete = (slNo) => {
    setData(data.filter((item) => item.slNo !== slNo));
  };

  const handleFormSubmit = (assetDetails) => {
    if (selectedAsset) {
      // Edit existing asset
      setData(
        data.map((item) =>
          item.slNo === selectedAsset.slNo ? { ...item, ...assetDetails } : item
        )
      );
    } else {
      // Add new asset
      setData([
        ...data,
        {
          ...assetDetails,
          slNo: data.length + 1,
          totalCost: `₹${assetDetails.quantity * assetDetails.unitCost}`,
          creation: new Date().toISOString().split("T")[0],
        },
      ]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Assets</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add Asset
        </button>
      </div>

      <ReusableTable columns={columns} data={data} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedAsset ? "Edit Asset" : "Add Asset"}
        width="w-1/2"
      >
        <AssetForm
          initialValues={selectedAsset}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Assets;
